<template>
	<div class="phone_files_mainLayout">
		<div class="boxBorder1 ">
		    <div class="boxBorder2">
				<el-row>
					<el-col :span="1"></el-col>
				    <el-col :span="6"><div class="phone_main_title_style_b" /></el-col>						
				    <el-col :span="10">
						<div class="phone_main_title_style_a" >
							项目管理
						</div>
					</el-col>
				    <el-col :span="6"><div class="phone_main_title_style_c" /></el-col>
					<el-col :span="1"></el-col>
				</el-row>
				<div class="phone_device_data_5_layout_title_2">
					<el-row style="margin-top: -4px;">
						<el-col :span="4"
							style="
								
								color: #55ffff;
								line-height: 70px;
								font-size: 16px;
							"
							>
							设备:
						</el-col>
						<el-col :span="19" class="phone_select_device_files_layout" 
								style="margin-top: 5px;
									line-height: 55px;
									text-align: left;
									"
						>
							<el-select 
								v-model="choose_display_devices" 
								class="phone_device_files_select" 
								placeholder="     选择设备"
								multiple 
								filterable
								:disabled="unedit_admin_value"
								placement ="bottom-end"
								@change="display_devices_change"
								collapse-tags
								collapse-tags-tooltip							
								size="small"
								>
								<!-- :disabled="see_admin.display_device=='全部设备'" -->
								
							    <el-option
							      v-for="item in device_data_info"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value"
							    />
							</el-select>			
						</el-col>
						<!-- <el-col :span="2"></el-col> -->
						<!-- <el-col :span="6" class="history_device_position_col"> -->
							<!-- <el-select v-model="select_device_id" 
									class="history_now_region_select" 
									placeholder="Select" 
									@change="select_value_onChange"
									filterable 
									size="default">
							    <el-option
							      v-for="item in select_options"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value"
								  class="history_select_options_item"
							    />
							</el-select> -->
						<!-- </el-col> -->
						<el-col :span="6">
							<div class="phone_device_data2_select_col_2">
								文件名:      	 
							</div>
						</el-col>
						<el-col :span="14" >
							<div class="phone_device_data2_select_col_2" style="margin-left: 10px;margin-top: -12px;">
								<el-input v-model="select_file_name"
									placeholder="  " 
									clearable
									size="small"
									@change="select_file_name_On_change"
									/>
							</div>
						</el-col>
						<!-- <el-col :span="2"></el-col> -->
						<!-- <el-col :span="7"></el-col> -->
						<!-- <el-col :span="4"> -->
							<!-- <el-switch
							    v-model="sort_sign"
							    
							    active-text="升序"
							    inactive-text="降序"
								active-value="up"
								inactive-value="down"
								@change="sort_sign_change"
								class="sort_sign_switch"
								size="large"
							  /> -->
							
							<!-- <el-date-picker
									class="date_picker_layout"
							        v-model="select_type_1_time"
							        type="datetimerange"
							        start-placeholder="开始时间"
							        end-placeholder="结束时间"
							        format="YYYY-MM-DD HH:mm:ss"
							        date-format="YYYY/MM/DD "
							        time-format="hh:mm:ss"
									:shortcuts="shortcuts"
									size="large"
									@change="select_value_onChange"
									:clearable=false
							      /> -->
								  <!-- value-format="YYYY-MM-DD HH:mm:ss" -->
								  
						<!-- </el-col> -->
						<el-col :span="4">
							<div class="phone_show_upload_form"  @click="show_upload_form" id="show_btn"></div>
						</el-col>
					</el-row>
				</div>
				<div class="phone_upload_file_list_layout" 
					v-show="upload_form_isShow"
				>
					<!-- <el-upload
						ref="uploadRef"
					    v-model:file-list="fileList"
					    class="upload_layout"
					    action="http://120.26.82.44:7770/device/upload_files"
					    multiple
					    :on-preview="handlePreview"
					    :on-remove="handleRemove"
						:on-success="upload_success"
					    :before-remove="beforeRemove"
					    :on-exceed="handleExceed"
					>
					
						<template #trigger>
						    <el-button type="primary">select file</el-button>
						</template>
						<el-button  @click="submitUpload" class="ml-3" type="success" >
						    upload to server
						</el-button>
						
					    <template #tip>
							<div class="el-upload__tip">
								jpg/png files with a size less than 500KB.
							</div>
					    </template>
					</el-upload> -->
					
					<!-- action="http://120.26.82.44:7770/device/upload_files" -->
					<el-upload
					    ref="uploadRef"
					    class="upload_layout"
					    :action="SERVER_URL_upload"
						:on-success="upload_success"
						:on-error="upload_error"
					    :auto-upload="false"
						multiple
						:data="upload_info_data"
						v-model:file-list="fileList"
					  >
					    <template #trigger>
					      <el-button class="phone_select_file_btn">选择文件</el-button>
					    </template>
					
					    <el-button class="phone_supload_file_btn" @click="submitUpload">
					      上传到服务器
					    </el-button>
					
					    <template #tip>
							<div class="phone_el-upload__tip">
								<br>1.先点击左侧按钮选择需要上传的文件
								<br>2.再点击右侧按钮将选择的文件上传到服务器
								
							</div>
					    </template>
					  </el-upload>
				</div>
				<div class="phone_pdf_data_show_layout" v-show="pdf_data_isShow">
					<div class="phone_history_data_2_show_header" @click="close_pdf_data_show">
						×
					</div>
					<div class="phone_wait_submit_isShow" v-show="phone_wait_submit_isShow">
						<div class="box">
							<div class="box-circle"></div>
							<div class="box-circle1"></div>
							<div class="box-circle2"></div>
						</div>
					</div>
					<div class="phone_pdf_data_list">
						<el-table
							:row-style="rowState"
							:cell-style="cellState"
							:header-row-style="headerRowState"
							:header-cell-style="headerCellState"
							:data="pdf_data_list" 
							:border="parentBorder" 
							style="width: 100%;border-radius: 0px;"
							height="100%"
							>
							<el-table-column type="index" width="50" />
							<el-table-column label="内容" prop="text" />
							<el-table-column label="地址" prop="position" />
							<el-table-column label="操作" width="60" >
								<template #default="scope">
									<div class="phone_delete_pdf_item" @click="delete_pdf_item(scope)">
										删除
									</div>
								</template>
								<!-- <el-popconfirm
									title="确定删除吗？" 
									@confirm="delete_folder_btn(i)"
								>
								  <template #reference>
								    <div class="delete_btn" ></div>
								  </template>
								</el-popconfirm> -->
							</el-table-column>
						</el-table>
					</div>
					<div class="bottom_layout">
						<!-- <div class="update_data_device_id" >
							<el-input v-model="update_data_device_id"
								placeholder="请输入需要修改的设备id" 
								clearable
								/>
						</div> -->
						<div class="phone_change_device_data_from_pdf" @click="change_device_data_from_pdf">
							应用到当前所选择的{{choose_display_devices.length}}台设备
						</div>
					</div>
					
				</div>
				<div class="phone_file_path_layout" >
					
					<el-row>
						<el-col :span="3" >
							<!-- <div class="phone_folder_title">当前路径:</div> -->
							<div class="phone_folder_icon" @click="home_folder_btn"></div>
							
						</el-col>
						<el-col :span="15">
							<el-input 
								v-model="file_path" 
								placeholder=""
								@change="select_file_path_On_change"
								@focus="select_file_path_On_focus"
								class="phone_file_path_input"
							>
							    <template #prepend>/</template>
							</el-input>
							<!-- upload_files -->
						</el-col>
						<!-- <el-col :span="2">
							
						</el-col> -->
						<el-col :span="6">
							<!-- <div class="phone_home_folder_icon" @click="home_folder_btn"></div> -->
							<div class="phone_add_folder_icon" @click="new_folder_dialogVisible = true" ></div>
							<div class="phone_retreat_icon" @click="retreat_btn"></div>
							
						</el-col>
<!-- 						<el-col :span="1">
							<div class="forward_icon" @click="forward_btn"></div>
						</el-col> -->
<!-- 						<el-col :span="3">
							<div class="phone_home_folder_icon" @click="home_folder_btn"></div>
						</el-col> -->
<!-- 						<el-col :span="3">
							<div class="phone_add_folder_icon" @click="new_folder_dialogVisible = true" ></div>
						</el-col> -->
					</el-row>
				</div>
				<div class="phone_file_list_header" v-show="!upload_form_isShow">
					
					<el-row>
						<el-col :span="8">
							<div class="phone_sort_text">文件(夹)名</div>
							<div class="phone_sort_up_btn" @click="sort_sign_change('name')" id="sort_name_btn"></div>
						</el-col>
						<!-- <el-col :span="4"></el-col> -->
						<el-col :span="8">
							<div class="phone_sort_text">时间</div>
							<div class="phone_sort_default_btn" @click="sort_sign_change('update_time')" id="sort_update_time_btn"></div>
						</el-col>
						<!-- <el-col :span="1"></el-col> -->
						<el-col :span="8">
							<div class="phone_sort_text">提交者</div>
							<div class="phone_sort_default_btn" @click="sort_sign_change('operator')" id="sort_operator_btn"></div>
						</el-col>
						<!-- <el-col :span="4">操作</el-col> -->
					</el-row>
				</div>
				<div class="phone_all_file_list_layout" v-show="!upload_form_isShow">
					<div v-for="i in all_file_list" 
						class="phone_file_obj" :id="i.name" 
						
						v-show="i['file_type']=='folder'"
						@dblclick="open_folder_btn(i)"
					>
						<el-row>
							<el-col :span="3">
								<div class="phone_folder_type_icon"  ></div>
							</el-col>
							<el-col :span="11" >
								<div class="phone_file_obj_name">
									{{i['name']}}
								</div>
							</el-col>
							<el-col :span="4">
							</el-col>
							<el-col :span="3">
								<div class="phone_open_folder_btn" @click="open_folder_btn(i)" ></div>
								
							</el-col>
							<el-col :span="3">
								<el-popconfirm
									title="确定删除吗？" 
									@confirm="delete_folder_btn(i)"
								>
								  <template #reference>
								    <div class="phone_delete_btn" ></div>
								  </template>
								</el-popconfirm>
							</el-col>
							<el-col :span="14">
								<div class="phone_file_obj_time">
									{{i['update_time']}}
								</div>
							</el-col>
							<el-col :span="10" >
								<div class="phone_file_obj_operator">
									{{i['operator']}}
								</div>
							</el-col>
							<!-- <el-col :span="1">
								<div class="open_folder_btn" @click="open_folder_btn(i)" ></div>
							</el-col>
							<el-col :span="1">
								<el-popconfirm
									title="确定删除吗？" 
									@confirm="delete_folder_btn(i)"
								>
								  <template #reference>
								    <div class="delete_btn" ></div>
								  </template>
								</el-popconfirm>
							</el-col> -->
						</el-row>
						<!-- @click="click_file_list_item" -->
					</div>
					<div v-for="i in all_file_list" 
						class="phone_file_obj" :id="i.name" 
						
						v-show="i['file_type']=='file'"
						@dblclick="download_file_btn(i)"
						
					>
						<!-- @click="click_file_list_item" -->
						<el-row>
							<el-col :span="3">
								<div class="phone_file_type_icon" ></div>
							</el-col>
							<el-col :span="11" >
								<div class="phone_file_obj_name">
									{{i['name']}}
								</div>
							</el-col>
							<el-col :span="4">
								<div class="pdf_btn" @click="pdf_btn(i)" v-if="i['handle_pdf']"></div>
							</el-col>
							<el-col :span="3">
								<div class="download_btn" @click="download_file_btn(i)" ></div>
							</el-col>
							<el-col :span="3">
								<el-popconfirm
									title="确定删除吗？" 
									@confirm="delete_file_btn(i)"
								>
								  <template #reference>
								    <div class="delete_btn" ></div>
								  </template>
								</el-popconfirm>
							</el-col>
							<el-col :span="14">
								<div class="phone_file_obj_time">
									{{i['update_time']}}
								</div>
							</el-col>
							<el-col :span="10" >
								<div class="phone_file_obj_operator">
									{{i['operator']}}
								</div>
							</el-col>
							<!-- <el-col :span="1">
								<div class="pdf_btn" @click="pdf_btn(i)" v-if="i['handle_pdf']"></div>
							</el-col>
							<el-col :span="1">
								<div class="download_btn" @click="download_file_btn(i)" ></div>
							</el-col>
							<el-col :span="1">
								<el-popconfirm
									title="确定删除吗？" 
									@confirm="delete_file_btn(i)"
								>
								  <template #reference>
								    <div class="delete_btn" ></div>
								  </template>
								</el-popconfirm>
							</el-col> -->
						</el-row>
						




						
					</div>
					<div class="default_value" v-show="all_file_list.length==0">
						暂无文件
					</div>
				</div>
				<el-dialog v-model="dialogVisible">
				    <img w-full :src="dialogImageUrl" alt="Preview Image" />
				</el-dialog>
				
			</div>
		</div>
	</div>
	
	<el-dialog v-model="new_folder_dialogVisible" title="Tips" class="phone_dialog_main_layout" draggable >
	    <div style="color: #55ffff;
					font-size: 18px;
					width: calc(100% - 40px);
					margin-left: 20px;
					margin-top: 40px;
			">
			请输入文件夹名字：
		</div>
		<div style="
					width: calc(100% - 40px);
					margin-left: 20px;
			
		">
			<el-input v-model="new_folder_name"
				placeholder="不能包含特殊字符" 
				clearable
				/>
		</div>
		
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button style="background-color: #e3e3e3;color: #000000;" @click="close_new_folder">取消</el-button>
	        <el-button type="primary" @click="create_new_folder">
	          创建
	        </el-button>
	      </span>
	    </template>
	  </el-dialog>
	  
</template>

<script>
	import request from "@/utils/request";
	import { ElMessage } from 'element-plus'
import { ref } from "vue";
	export default{
		data() {
			return{
				SERVER_URL_upload:SERVER_URL+'/device/upload_files',
				phone_wait_submit_isShow:false,
				fileList :[], //上传文件
				all_file_list :[], //服务器文件
				dialogVisible :false,
				dialogImageUrl:"",
				upload_form_isShow:false,
				select_file_name:"",
				choose_display_devices:[],
				choose_display_devices_99:true,
				device_data_info:[],
				upload_info_data:[],
				sort_sign:"up",
				sort_type:"name",
				device_data_info_type:"not",
				file_path:"",
				new_folder_name:"",
				new_folder_dialogVisible:false,
				forward_path:"",
				retreat_path:"",
				pdf_data_isShow:false,
				pdf_data_list:[],
				update_data_device_id:"",
				cellState:function(row){
					
					let style ={
						textAlign: "center",
						borderBottom:"1px solid #55ffff",
						backgroundColor: 'rgba(85, 170, 255, 0.2)',
						fontSize: "15px",
						color:'#55aaff',
					}
					if(row['columnIndex']==10){
						style.backgroundColor='rgba(0, 75, 113, 0.0)'
						// style.borderRadius='15px'
					}
					return style
					
				},
			}
		},
		methods:{
			// click_file_list_item
			get_all_device_info(){
				request.post(this.$GLOBAL.flask_web_local_url+'/get_private_device_info_2',{"username":sessionStorage.getItem('user')}).then(res => {
					this.device_data_info=[]
					let default_data = {
						'value':99,
						'label':"全部设备",
					}
					this.device_data_info.push(default_data)
					let not_default_data = {
						'value':-99,
						'label':"取消选择",
					}
					this.device_data_info.push(not_default_data)
					for(let i in res){
						if(i=="type"){
							this.device_data_info_type = res[i]
						}else{
							let info_data = {
								'value':res[i]['device_id'],
								'label':res[i]['device_id']+" - "+res[i]['address'],
								'region':res[i]['region'],
								'address':res[i]['address'],
							}
							this.device_data_info.push(info_data) 
						}
					}
					// console.log(this.device_data_info)
					this.get_now_admin()
				})
			},
			get_now_admin(){
				request.post(this.$GLOBAL.flask_web_local_url+'/get_one_admin', {"id":sessionStorage.getItem('id')}).then(res => {
						if(res['display_device']=="99"){
							this.choose_display_devices=[99]
						}else{
							let data_arr = res['display_device'].split(",")
							for(let i in data_arr){
								this.choose_display_devices.push(data_arr[i])
							}
						}
						// console.log(this.choose_display_devices)
						this.display_devices_change()
						// console.log(this.choose_display_devices)
						// this.get_all_upload_files()
					}).catch(function(){
					ElMessage.error('数据加载失败 ！！！')
				})	
			},
			display_devices_change(){
				
				for(let i in this.choose_display_devices){
					if(this.choose_display_devices[i]==99){
						// if(this.device_data_info_type == "not"){
							let new_choose = []
							for(let i in this.device_data_info){
								if(this.device_data_info[i]['value']!=99 && this.device_data_info[i]['value']!=-99){
								// if(this.device_data_info[i]['value']!=-99){
									new_choose.push(this.device_data_info[i]['value'])
								}
							}
							this.choose_display_devices = new_choose
						// }
						// else{
						// 	this.choose_display_devices=[99]
						// }
						break
					}
					if(this.choose_display_devices[i]==-99){
						this.choose_display_devices=[]
						break
					}
				}
				this.get_all_upload_files()
			},
			open_folder_btn(folder){
				if(this.file_path==""){
					this.file_path = folder['name']
				}else{
					// for(let i in this.file_path){
						
					// }
					this.file_path = this.file_path + "/" + folder['name']
				}
				
				this.get_all_upload_files()
			},
			delete_pdf_item(item){
				let index = item.$index
				console.log(index)
				this.pdf_data_list.splice(index, 1)
				
			},
			change_device_data_from_pdf(){
				this.phone_wait_submit_isShow = true
				if(this.pdf_data_list.length!=0){
					let msg = {
						"device_data":this.pdf_data_list,
						// "device_data":[this.update_data_device_id],
						"device_id_list":this.choose_display_devices
					}
					request.post(this.$GLOBAL.flask_web_local_url+'/update_device_data_from_pdf', msg).then(res => {
						console.log(res)	
						this.phone_wait_submit_isShow = false
						if(res != "500"){
							console.log(res)
							this.pdf_data_isShow = false
							ElMessage.success('应用成功！')
						}else{
							ElMessage.error('提交失败，请重试！！！')
						}
					})
				
				}else{
					ElMessage.error('数据不能为空！！！')
					this.phone_wait_submit_isShow = false
				}
			},
			close_pdf_data_show(){
				this.pdf_data_isShow = false
			},
			pdf_btn(file){
				this.pdf_data_isShow = true
				console.log(file)
				let index = file['url'].indexOf("=")
				let pdf_path = file['url'].substring(index+1)
				console.log(index)
				console.log(pdf_path)
				request.post(this.$GLOBAL.flask_web_local_url+'/get_pdf_data', {"pdf_path":pdf_path}).then(res => {
					console.log(res)
					this.pdf_data_list = res
				}).catch(function(){
					ElMessage.error('数据加载失败 ！！！')
				})	
			},
			download_file_btn(file){	
				const href = file['url']
				const alink = document.createElement("a");
				alink.style.display = "none";
				alink.download = file['name']; // 下载后文件名
				alink.href = href;
				// alink.target = 'blank'			
				document.body.appendChild(alink);
				alink.click();
				document.body.removeChild(alink); // 下载完成移除元素
				
			},
			show_upload_form(){
				if(this.upload_form_isShow){
					this.upload_form_isShow = false
					document.getElementById("show_btn").className="phone_show_upload_form"
					this.get_all_upload_files()
				}else{
					this.upload_form_isShow = true
					document.getElementById("show_btn").className="phone_show_download_form"
				}
			},
			handlePreview(file){
				// console.log(this.fileList)
				// console.log(file)
				this.dialogImageUrl = file.response.url
				this.dialogVisible = true
			},
			upload_success(e){
				ElMessage.success('文件上传成功')
				// console.log(this.fileList)
				// console.log(e)
				this.fileList=[]
			},
			upload_error(){
				ElMessage.error('文件上传失败，请稍后再试 ！！！')
			},
			sort_sign_change(type){
				// 判断升序、降序
				if(this.sort_sign=="up"){
					this.sort_sign = "down"
				}else{
					this.sort_sign = "up"
				}
				// 判断排序类型
				if(type=="name"){
					if(this.sort_sign=="up"){
						document.getElementById("sort_name_btn").className="phone_sort_up_btn"
					}else{
						document.getElementById("sort_name_btn").className="phone_sort_down_btn"
					}					
					// 还原其余类型图标
					document.getElementById("sort_update_time_btn").className="phone_sort_default_btn"
					document.getElementById("sort_operator_btn").className="phone_sort_default_btn"
				}else if(type=="update_time"){
					if(this.sort_sign=="up"){
						document.getElementById("sort_update_time_btn").className="phone_sort_up_btn"
					}else{
						document.getElementById("sort_update_time_btn").className="phone_sort_down_btn"
					}					
					document.getElementById("sort_name_btn").className="phone_sort_default_btn"
					document.getElementById("sort_operator_btn").className="phone_sort_default_btn"
				}else if(type=="operator"){
					if(this.sort_sign=="up"){
						document.getElementById("sort_operator_btn").className="phone_sort_up_btn"
					}else{
						document.getElementById("sort_operator_btn").className="phone_sort_down_btn"
					}					
					document.getElementById("sort_name_btn").className="phone_sort_default_btn"
					document.getElementById("sort_update_time_btn").className="phone_sort_default_btn"
				}
				this.sort_type=type
				this.get_all_upload_files()
			},
			home_folder_btn(){
				this.file_path=""
				this.get_all_upload_files()
			},
			retreat_btn(){
				// console.log(this.file_path)
				this.forward_path = this.file_path
				if(this.file_path != ""){
					let path_arr = this.file_path.split("/")
					let temp_path = ""
					if(path_arr.length==1){
						temp_path = ""
					}else{
						for(let i in path_arr){
							if(i==0){
								temp_path = path_arr[i]
							}else if(i==path_arr.length-1){
								break
							}else{
								temp_path = temp_path + "/" + path_arr[i]
							}
						}
					}
					this.file_path = temp_path
					this.get_all_upload_files()
					
				}
			},
			// forward_btn(){
			// 	this.file_path=this.forward_path
			// 	this.get_all_upload_files()
			// },
			select_file_path_On_focus(){
				
				
			},
			select_file_path_On_change(){
				this.get_all_upload_files()
			},
			select_file_name_On_change(){
				this.get_all_upload_files()
			},
			close_new_folder(){
				this.new_folder_dialogVisible = false
				this.new_folder_name = ""
			},
			delete_file_btn(file){
				let msg = {
					"file_name":file['name'],
					"file_path":file['file_path']				
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/delete_upload_files', msg).then(res => {
					console.log(res)
					this.get_all_upload_files()
				})
			},
			delete_folder_btn(file){
				let msg = {
					"file_name":file['name'],
					"file_path":file['file_path']				
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/delete_upload_folder', msg).then(res => {
					if(res=="200"){
						this.get_all_upload_files()
					}else if(res=="501"){
						ElMessage.error('非空文件夹不能删除')
					}
				})
			},
			create_new_folder(){
				// this.new_folder_dialogVisible = false
				let display_device = ""
				if(this.device_data_info_type == "yes" ){
					if(this.choose_display_devices.length == this.device_data_info.length - 2){
						display_device = 99
					}
				}
				if(display_device!=99){
					for(let i in this.choose_display_devices){
						if(display_device==""){
							display_device = this.choose_display_devices[i]
						}else{
							display_device = display_device +","+this.choose_display_devices[i]
						}
					}
				}
				let info_data = {
					"device_info":display_device,
					"operator":sessionStorage.getItem('user'),
					"file_path":this.file_path=="" ? "/" : this.file_path,
					"name":this.new_folder_name,
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/create_new_folder',info_data).then(res => 	{
					if(res=="200"){
						ElMessage.success('文件夹新建成功')
						this.new_folder_dialogVisible = false
						this.get_all_upload_files()
					}else if(res=="501"){
						ElMessage.error('已存在同名文件夹')
					}
					this.new_folder_name = ""
				})
			},
			submitUpload(){
				let display_device = ""
				if(this.device_data_info_type == "yes" ){
					if(this.choose_display_devices.length == this.device_data_info.length - 2){
						display_device = 99
					}
				}
				if(display_device!=99){
					for(let i in this.choose_display_devices){
						if(display_device==""){
							display_device = this.choose_display_devices[i]
						}else{
							display_device = display_device +","+this.choose_display_devices[i]
						}
					}
				}
				this.upload_info_data= {
					"device_info":display_device,
					"operator":sessionStorage.getItem('user'),
					"file_path":this.file_path=="" ? "/" : this.file_path,
				}
				this.$refs.uploadRef.submit()
			},			
			get_all_upload_files(){
				let info_data = {
					"info_data":this.choose_display_devices,
					"file_path":this.file_path=="" ? "/" : this.file_path,
				}
				if(this.device_data_info_type == "yes" ){
					if(this.choose_display_devices.length == this.device_data_info.length - 2){
						info_data['info_data'] = [99]
					}
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/get_all_upload_folder_files',info_data).then(res => 	{
					// console.log(this.select_file_name)
					if(res=="501"){
						ElMessage.error('该路径不存在')
						this.retreat_btn()
					}else{						
						this.all_file_list = []
						for(let i in res){
							// console.log(res[i])
							if(res[i]['file_name'].indexOf(this.select_file_name)!=-1||this.select_file_name==""){
								let msg = {
									"name":res[i]['file_name'],
									"url":res[i]['url'],
									"update_time":res[i]['update_time'],
									"operator":res[i]['operator'],
									"file_type":res[i]['file_type'],
									"file_path":res[i]['file_path']
								}
								if(res[i]['file_name'].indexOf("pdf")!=-1){
									msg['handle_pdf']=true
								}else{
									msg['handle_pdf']=false
								}
								this.all_file_list.push(msg)
							}
						}
						let that = this
						this.all_file_list = this.all_file_list.sort(function(a,b){
							let a_value = ""
							let b_value= ""
							if(that.sort_type=="name"){
								a_value = a.name.toUpperCase()
								b_value= b.name.toUpperCase()
							}else if(that.sort_type=="update_time"){
								a_value = a.update_time.toUpperCase()
								b_value= b.update_time.toUpperCase()
							}else if(that.sort_type=="operator"){
								a_value = a.operator.toUpperCase()
								b_value= b.operator.toUpperCase()
							}
							if(that.sort_sign=="up"){
								if(a_value > b_value){
									return 1;
								}
								if(a_value < b_value) {
									return -1;
								}
							}else if(that.sort_sign=="down"){
								if(a_value > b_value){
									return -1;
								}
								if(a_value < b_value) {
									return 1;
								}
							}
						})
					}
				})
			}
		},
		mounted(){
			// this.get_now_admin()
			this.get_all_device_info()
			// this.get_all_upload_files()
			
		}
		
	}
</script>

<style>
	.phone_files_mainLayout{
		/* margin-top: -20px; */
		height: 800px;
		height: calc(100vh - 100px);
		width: 100%;
		/* padding-right: 30px; */
		overflow: hidden;
	}
	.phone_upload_file_list_layout{
		position: absolute;
		margin-left: 3%;
		margin-top: 20px;
		width: 94%;
		height: calc(100% - 90px);
		/* text-align: left; */
		/* background-color: rgba(255, 255, 255, 0.5); */
		/* overflow: auto; */
	}
	.phone_all_file_list_layout{
		position: relative;
		margin-left: 5px;
		/* margin-top: 120px; */
		width: calc(100% - 10px);
		/* height: ; */
		/* margin-top: 10px; */
		height: calc(100% - 225px);
		/* max-height: 720px; */
		text-align: left;
		overflow: auto;
	}
	
	.upload_layout{
		/* text-align: left; */
		margin-top: 30px;
		position: absolute;
		/* height: 200px; */
		height: calc(90% - 60px);
		width: 100%;
		overflow: auto;
		overflow-y: auto;
		overflow-x: hidden;
		border: 1px solid #55ffff;
		box-shadow: 0px 0px 5px 2px #55ffff, inset 0px 0px 10px 1px #55ffff !important;
		border-radius: 30px !important;
	}
	.el-upload-list__item{
		background-color: rgba(226, 226, 226, 0.7);
		font-size: 18px;
		color: #000000;
		border-radius: 20px !important;
	}
	.el-upload-list__item-file-name{
		font-size: 18px;
		color: #000000;
	}
	.el-icon--document{
		color: #000000 !important;
	}
	.el-upload-list__item-name .el-icon{
		color: #000000;
		font-size: 18px;
	}
	.el-icon--close{
		color: #000000 !important;
		font-size: 30px !important;
		margin-right: 30px;
	}
	.phone_select_file_btn{
		margin-top: 10px;
		/* margin-left: -300px; */
		margin-right: 20px;
		width: 120px;
		height: 60px;
		/* position: fixed; */
		background-color: rgba(108, 204, 255, 0.1) !important;
		border: 1px solid #55ffff !important;
		border: none !important;
		color: #6cccff !important;
		font-size: 18px !important;
		font-weight: 600 !important;
		box-shadow: 0px 0px 5px 2px #6cccff, inset 0px 0px 10px 1px #6cccff !important;
		border-radius: 10px !important;
		
	}
	.phone_select_file_btn:hover{
		background-color: rgba(0, 170, 255, 0.7) !important;
		color: #ffffff !important;
	}
	.phone_supload_file_btn{
		/* margin-top: -45px; */
		/* margin-left: 300px; */
		width: 120px;
		height: 60px;
		/* position: fixed; */
		background-color: rgba(108, 204, 255, 0.1) !important;
		border: 1px solid #55ffff !important;
		border: none !important;
		color: #6cccff !important;
		font-size: 18px !important;
		font-weight: 600 !important;
		box-shadow: 0px 0px 5px 2px #6cccff, inset 0px 0px 10px 1px #6cccff !important;
		border-radius: 10px !important;
	}
	.supload_file_btn:hover{
		background-color: rgba(0, 170, 255, 0.7) !important;
		color: #ffffff !important;
	}
	.phone_file_obj{
		margin-top: 5px;
		margin-bottom: 5px;
		position: relative;
		height: 75px;
		width: calc(100%  - 10px);
		/* background-color: #00a1ff; */
		margin-left: 5px;
		/* margin-bottom: 30px; */
		display: inline-block;
		border-radius: 10px;
		box-shadow:  0px 0px 10px 4px rgba(85, 255, 255, 0.6),inset 0px 0px 15px 5px rgba(85, 255, 255, 0.6);
		background-color: rgba(0, 170, 255, 0.4);
		/* padding-top: 10px; */
	}
	.file_obj:hover{
		box-shadow:  0px 0px 10px 4px rgba(85, 170, 255, 0.8),inset 0px 0px 15px 5px rgba(85, 170, 255, 0.9);
		background-color: rgba(0, 0, 0, 0.4);
	}
	.phone_file_obj_name{
		margin-left: 8px;
		margin-top: 2px;
		/* font-weight: 600; */
		
		color: #ffffff;
		font-size: 17px;
		/* width: 90px; */
		width: 100%;
		
		display: inline-block;
		height: 40px;
		line-height: 40px;
		/* background-color: #00a1ff; */
		overflow-y: auto;
		overflow-x: hidden;
	}
	.phone_file_obj_time{
		/* margin-left: 20px; */
		/* font-weight: 600; */
		color: #ffffff;
		font-size: 17px;
		width: 100%;
		overflow-y: auto;
		overflow-x: auto;
		/* height: 30px; */
		line-height: 35px;
		display: inline-block;
		/* background-color: #00a1ff; */
		margin-top: -5px;
		padding-left: 15px;
		text-align: center;
		/* overflow: hidden; */
	}
	.phone_file_obj_operator{
		/* margin-left: 20px; */
		/* font-weight: 600; */
		color: #ffffff;
		width: 100%;
		height: 35px;
		line-height: 35px;
		margin-top: -8px;
		display: inline-block;
		font-size: 20px;
		text-align: center;
		overflow-y: auto;
		padding-left: 10px;
		/* background-color: #00a1ff; */
		/* overflow: hidden; */
	}
	.phone_show_upload_form{
		margin-top: -5px;
		margin-left: 10px;
		background: url(../assets/upload_file.png) no-repeat;
		background-size: 30px 30px ;
		width: 30px;
		height: 30px;
	}
	.phone_show_download_form{
		margin-top: -5px;
		margin-left: 10px;
		background: url(../assets/download_btn.png) no-repeat;
		background-size: 30px 30px ;
		width: 30px;
		height: 30px;
	}
	.phone_file_type_icon{
		margin-top: 8px;
		margin-left: 3px;
		float:right;
		background: url(../assets/file_icon.png) no-repeat;
		background-size: 28px 28px ;
		width: 28px;
		height: 28px;
	}
	.phone_folder_type_icon{
		margin-top: 8px;
		/* margin-left: 3px; */
		float:right;
		background: url(../assets/folder.png) no-repeat;
		background-size: 28px 28px ;
		width: 28px;
		height: 28px;
	}
	.download_btn{
		/* position: absolute; */
		margin-top: 8px;
		margin-right: 15px;
		float: right;
		background: url(../assets/download_file.png) no-repeat;
		background-size: 30px 30px ;
		width: 30px;
		height: 30px;
	}
	.pdf_btn{
		/* position: absolute; */
		margin-top: 8px;
		margin-right: 15px;
		float: right;
		background: url(../assets/pdf_btn.png) no-repeat;
		background-size: 30px 30px ;
		width: 30px;
		height: 30px;
	}
	.phone_delete_btn{
		margin-top: 8px;
		margin-right: 10px;
		float: right;
		background: url(../assets/delete_btn.png) no-repeat;
		background-size: 28px 28px ;
		width: 28px;
		height: 28px;
	}
	.phone_open_folder_btn{
		margin-top: 8px;
		margin-right: 10px;
		float: right;
		background: url(../assets/open_folder.png) no-repeat;
		background-size: 28px 28px ;
		width: 28px;
		height: 28px;
	}
	.phone_el-upload__tip{
		color: #55ffff !important;
		font-size: 19px !important;
		width: calc(100% - 30px);
		margin-left: 15px;
		/* padding-left: 20px; */
	}
	.phone_device_files_select{
		width: calc(100% - 5px) !important;
	}
	.phone_device_files_select .el-input__inner{
		color: #6dfaff;
		font-size: 15px;
		height: 50px !important; 
	}
	.phone_device_files_select .el-select__tags{
		margin-top: 50px;
		height: 100% !important;
		top: -92px !important;
		display: inline-block;
	}
	.phone_select_device_files_layout .el-select{
		/* display: inline !important; */
	}
	.phone_select_device_files_layout .el-select__input{
		/* position: absolute !important; */
		/* margin-top: -1px; */
		/* border: 1px solid #000000 !important; */
		border: 2px solid #00aaff !important;
		/* padding-left: 3px; */
		border-radius: 5px;
		width: 100% !important;
		width: 100vw;
		min-width: 120px;
		height :24px !important;
		font-size: 15px !important;
		float: right;
		margin-top: -9px !important;
		padding-left: 10px;
	}
	.phone_select_device_files_layout .el-select-tags-wrapper{
		height: 30px;
		/* position: absolute !important; */
		margin-top: 70px !important;
		margin-left: 12px !important;
		/* width: 130px !important; */
		/* margin-left: 100px !important; */
		/* overflow-y: auto; */
		/* overflow-x: hidden; */
		/* margin-top: 40px !important; */
		/* width: 100%; */
	}
	.default_value{
		position: relative;
		margin-left: 3%;
		/* margin-top: 120px; */
		width: 94%;
		/* height: ; */
		height: 500px;
		line-height: 500px;
		/* max-height: 720px; */
		text-align: center;
		overflow: auto;
		font-size: 50px;
		color: #55ffff;
		/* background-color: rgba(85, 255, 255, 0.8) */
	}
	.sort_sign_switch{
		margin-top: 10px;
		font-size: 18px !important;
	}
	.sort_sign_switch .el-switch__core{
		border-radius: 3px !important;
		background-color: #6cccff ;
	}
	.sort_sign_switch .el-switch--large .el-switch__label {
		font-size: 18px !important;
		color: #55ffff !important;
	}
	.sort_sign_switch .el-switch--large .el-switch__label *{
		font-size: 18px !important;
		color: #55ffff !important;
	}
	.sort_sign_switch .el-switch__label *{
		font-size: 18px !important;
		color: #55ffff !important;
	}
	.phone_file_list_header{
		margin-left: 5px;
		margin-top: 10px;
		width: calc(100% - 10px);
		height: 30px;
		font-size: 20px;
		border-top: 1px solid #55ffff;
		border-left: 1px solid #55ffff;
		border-right: 1px solid #55ffff;
		border-radius: 20px;
		color: #55ffff;
		/* background-color: #55ffff; */
		box-shadow:  inset 0px 0px 8px 5px rgba(255, 255, 255, 0.4);
	}
	.phone_sort_up_btn{
		margin-top: 8px;
		/* margin-right: 15px; */
		float: right;
		background: url(../assets/sort_up.png) no-repeat;
		background-size: 25px 25px	 ;
		width: 25px;
		height: 25px;
	}
	.phone_sort_text{
		width: calc(100% - 30px);
		height: 30px;
		/* margin-left: calc(50% - 44px); */
		float: left;
		line-height: 30px;
		font-size: 16px;
		/* background-color: #ffffff; */
		/* display: inline-block; */
	}
	.phone_sort_down_btn{
		float: left;
		background: url(../assets/sort_down.png) no-repeat;
		background-size: 25px 25px ;
		width: 25px;
		height: 25px;
	}
	.phone_sort_up_btn{
		float: left;
		margin-top: -2px;
		background: url(../assets/sort_up.png) no-repeat;
		background-size: 25px 25px ;
		width: 25px;
		height: 25px;
	}
	.phone_sort_default_btn{
		float: left;
		/* margin-top: -2px; */
		background: url(../assets/sort_default.png) no-repeat;
		background-size: 25px 25px ;
		width: 25px;
		height: 25px;
	}
	.phone_file_path_layout{
		margin-left: 5px;
		width: calc(100% - 10px);
		height: 40px;
		font-size: 20px;
		border-top: 1px solid #55ffff;
		border-left: 1px solid #55ffff;
		border-right: 1px solid #55ffff;
		border-radius: 5px;
		color: #55ffff;
		/* background-color: #55ffff; */
		box-shadow:  inset 0px 0px 8px 5px rgba(255, 255, 255, 0.4);
	}
	.phone_folder_icon{
		/* margin-top: -32px; */
		/* margin-right: 15px; */
		margin-top: 5px;
		margin-right: 5px;
		float: right;
		background: url(../assets/folder.png) no-repeat;
		background-size: 25px 25px ;
		width: 25px;
		height: 25px;
	}
	.phone_add_folder_icon{
		margin-top: 3px;
		margin-right: 8px;
		float: right;
		background: url(../assets/add_folder.png) no-repeat;
		background-size: 27px 27px ;
		width: 27px;
		height: 27px;
		display: inline-block;
	}
	.phone_home_folder_icon{
		margin-top: 3px;
		margin-left: 8px;
		float: right;
		background: url(../assets/home.png) no-repeat;
		background-size: 27px 27px ;
		width: 27px;
		height: 27px;
		display: inline-block;
	}
	.phone_retreat_icon{
		margin-top: 3px;
		margin-right: 8px;
		float: right;
		background: url(../assets/retreat.png) no-repeat;
		background-size: 27px 27px ;
		width: 27px;
		height: 27px;
		display: inline-block;
	}
	.phone_forward_icon{
		margin-top: 3px;
		/* margin-right: 15px; */
		/* float: right; */
		background: url(../assets/forward.png) no-repeat;
		background-size: 27px 27px ;
		width: 27px;
		height: 27px;
	}
	.phone_folder_title{
		margin-top: 6px;
		/* margin-left: -10px; */
		text-align: left;
		font-size: 16px;
		float: left;
		width: 100%;
		height: 30px;
	}
	.phone_file_path_input{
		width: 100%;
		height: 32px;
		/* margin-left: -20px; */
		margin-top: 3px;
		
	}
	.phone_file_path_input .el-input-group__prepend{
		background-color: rgba(1, 49, 61, 0.3);
		color: #55ffff !important;
		font-size: 25px;
		padding: 0px;
		padding-left: 10px;
		/* padding-right: 5px; */
		border-top: 1px solid #ffffff !important;
		border-left: 1px solid #ffffff !important;
		border-bottom: 1px solid #ffffff !important;
	}
	.phone_file_path_input .el-input__wrapper{
		border: none;
		border-top: 1px solid #ffffff !important;
		border-right: 1px solid #ffffff !important;
		border-bottom: 1px solid #ffffff !important;
		box-shadow: none;
	}
	.phone_file_path_input .el-input-group{
		/* border: 1px solid #6dfaff !important; */
		/* box-shadow: inset 0px 0px 0px 1px #6dfaff !important; */
	}
	.phone_pdf_data_show_layout{
		position: absolute;
		z-index: 99;
		margin-left: 15px;
		margin-top: -65px;
		width: calc(100% - 30px);
		height: calc(100% - 90px);
		border-radius: 30px;
		background-color: rgba(0, 85, 127, 0.98);
		box-shadow: 0px 0px 5px 5px rgba(85, 255, 255, 0.9) ,inset 0px 0px 10px 5px rgba(85, 255, 255, 0.9);
	}
	.phone_pdf_data_list{
		height: calc(100% - 70px);
		width: 100%;
		/* background-color: rgba(255, 255, 255, 1.0); */
		/* border-radius: 50px; */
		/* border-bottom-left-radius: 50px; */
		/* border-bottom-right-radius: 50px; */
	}
	.phone_delete_pdf_item{
		margin-left: -5px;
		width: 50px;
		
		height: 30px;
		background-color: rgba(255, 0, 0, 0.7);
		color: #ffffff;
		border-radius: 20px;
		line-height: 30px;
		font-size: 15px;
	}
	.bottom_layout{
		width: 100%;
		height: 50px;
		/* background-color: rgba(255, 0, 0, 0.4); */
		border-bottom-left-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.phone_change_device_data_from_pdf{
		margin-top: 5px;
		margin-left: 10px;
		width: calc(100% - 20px);
		height: 30px;
		line-height: 30px;
		background-color: rgba(85, 255, 255, 0.7);
		color: #ffffff;
		border-radius: 15px;
		font-size: 17px;
		
	}
	.phone_device_data_5_layout_title_2{
		margin-top:10px;
		margin-left: 2%;
		margin-bottom: 8px;
		width: 96%;
		height: calc(100px - 6px);
		background-color: #00557f;	
		background-color: rgba(19, 77, 88, 0.4);
		border-radius: 10px;
		border: 1px solid #77f7f8;
		box-shadow: 0px 0px 3px 2px #6dfaff;
	}
	.phone_device_data2_select_col_2{
		margin-top: -10px;
		font-size: 16px;
		height: 40px;
		color: #6dfaff;
		font-size: 16px;
		line-height:40px;
	}
	.phone_history_data_2_show_header{
		width: 100%;
		height: 30px;
		background-color: rgba(255, 255, 255, 0.5);
		border-top-left-radius: 50px;
		border-top-right-radius: 50px;
		font-size: 40px;
		line-height: 30px;
		color: #ffaa00;
	}
	.phone_wait_submit_isShow{
		position: absolute;
		z-index: 99;		
		width: 100%;
		/* margin-left: 20px;
		margin-top: 15px; */
		height: calc(100% - 30px );
		background-color: rgba(0, 0, 0, 0.5);
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
		padding-top: 3%;
	}
</style>
