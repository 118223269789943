<template>
	<div class="map_device_data_list" v-show="all_data_list_isShow">
		<div style="width: 100%;height: 42px;">
			<div class="map_device_data_header_1" id="map_device_data_header" >
				<!-- 数据名称： -->
				<div class="device_item_select_col_1">
					<el-input v-model="data_list_select"
						placeholder="  " 
						clearable
						@change="data_list_select_change"
					/>
				</div>
			</div>
		</div>
		<div class="map_device_data_list_layout">
			<div class="map_device_data_item_layout" :id="'device_item_'+item['device_id']" v-for="item in all_data_list" v-show="item['isShow']">
						<div class="map_device_data_item_layout_btn" @click="select_all_data_list_item(item)" >
							<div class="map_device_data_item" >
								地址: {{item['device_addres']}}
							</div>
							<div class="map_device_data_item" style="color: #55ffff;">
								设备id: {{item['device_id']}}
							</div>
							<div class="map_device_data_item" >
								海侧行程模拟量-AIW36: {{item['AIW36']}}
							</div>
			<!-- 				<div class="map_device_data_item" >
								海侧行程模拟量-AIW52: {{item['AIW52']}}
							</div> -->
							<div class="map_device_data_item" >
								海侧行程读值-VW108: {{item['VW108']}}
							</div>
							<div class="map_device_data_item" >
								陆侧行程模拟量-AIW38: {{item['AIW38']}}
							</div>
			<!-- 				<div class="map_device_data_item" >
								陆侧行程模拟量-AIW54: {{item['AIW54']}}
							</div> -->
							<div class="map_device_data_item" style="margin-bottom: 5px;" >
								陆侧行程读值-VW124: {{item['VW124']}}
							</div>
							<!-- <div class="map_device_data_item" v-if="item['error']>0" style="color: #ffc800;font-size: 20;font-weight: 600;">
								故障统计: {{item['error']}}
							</div> -->
							<!-- <div class="map_device_data_item" v-else>
								故障统计: {{item['error']}}
							</div> -->
						</div>
						
						<!-- <div  class="map_device_data_item_btn" @click="see_more_device_data">
							查看状态数据
						</div> -->
					</div>
		</div>
		
	</div>
	<div class="expand_btn_layout expand_btn_open" @click="expand_btn" id="expand_btn" v-show="is_not_phone&&all_data_list_isShow">
<!--		> -->
	</div>
	<div class="map_device_status_list" v-show="all_data_status_isShow">
		<div class="status_list_header"> 
			<div class="status_list_header_item">
				设备总数:{{all_data_list_length}}
			</div>
<!-- 			<div class="status_list_header_item">
				故障数量：{{error_device_list.length}}
			</div> -->
		</div>
		<!-- v-if="init_show_all_status" -->
		<div class="status_list_content_title" @click="status_list_content_isShow_btn"  v-if="false">
			故障设备：{{error_device_list.length}}
		</div>
		<div class="status_list_content" v-show="status_list_content_isShow" v-if="false">
			<div class="status_list_content_item_layout" v-for="error_item in error_device_list" @click="select_error_device(error_item)" >
				<div class="status_list_content_item">
					设备id：{{error_item['device_id']}}
				</div>
<!-- 				<div class="status_list_content_item">
					地址：{{error_item['device_addres']}}
				</div> -->
				<!-- <div class="status_list_content_item"> -->
					<!-- 故障统计：{{error_item['error']}} -->
				<!-- </div> -->
			</div>
			
		</div>
	</div>
	<div class="t_map_bg">
		
	</div>
	<div :id="tdtMapDivID" class="divTdtMap" >
		
	</div>

</template>

<script>
	import request from "@/utils/request";
	import { ElMessage } from 'element-plus'

	export default {
	    name: 'TdtMap',
	    data() {
	      return {
			// marker_error_icon_url:"http://192.168.1.77:9999/web/device_error_img",
			// marker_error_icon_url:"http://120.26.82.44:7770/device/device_error_img",
			marker_error_icon_url:SERVER_URL+"/device/device_error_img",

			// marker_normal_icon_url:"http://192.168.1.77:9999/web/device_normal_img",
			// marker_normal_icon_url:"http://120.26.82.44:7770/device/device_normal_img",
			marker_normal_icon_url:SERVER_URL+"/device/device_normal_img",
			init_show_all_label:false,
			init_show_all_list:true,
			init_show_all_status:true,
			show_all_sign:false,
			status_list_content_isShow:true,
			all_data_list_isShow:false,
			is_not_phone:true,
			all_data_status_isShow:false,
			all_data_list:[],
			all_data_list_length:"",
			error_device_list:[],
	        tdtMapDivID: "tdtMapDivID_",
			marker:"",
			content:"",
			ContextMenu:"",
			now_region_id:"device1",
			transform_marker_region:[
				// '0':"device1",
				// '1':"device2",
				// '2':"device3",
				// '3':"device4",
				// '4':"device5",
				// '5':"device6",
				// '6':"device7",
				// '7':"device8",
				// '8':"device9",
				// '9':"device10",
				// '10':"device11",
				// '11':"device12",
				// '12':"device13",
				// '13':"device14",
				// '14':"device15",
			],
			marker_data_timer:[
				
			],
			map_label_timer:"",
			markers_list:"",
			data_list_is_expand:false,
			data_list_select:"",
			click_marker:"",
	      }
	    },
	    created() {
	    },
	    mounted(){
			let is_Phone = sessionStorage.getItem("is_Phone")
			if(is_Phone=="true"){
				this.is_not_phone = false
			}else{
				this.is_not_phone = true
			}
			// 获取全部设备地图数据信息
			// request.get(this.$GLOBAL.flask_web_local_url+'/get_map_data_info').then(res => 	{
			// 	this.$GLOBAL.map_data_info=[]
			// 	this.transform_marker_region={}
			// 	for(let i in res){
			// 		/* ！！！  " "作为字符串分割的标注,要严格按照下列规范
			// 			"<div style='color:#55aaff;'>地址 :武汉港 <br/>设备: device1 </div>"
			// 		*/
			// 		let arr =[
			// 			res[i]['lon'],
			// 			res[i]['lat'],
			// 			`<div style='color:#55aaff;'>地址: ${res[i]['address']} <br/>设备: ${res[i]['device_id']} </div>`
			// 		]
			// 		this.$GLOBAL.map_data_info.push(arr)
			// 		this.transform_marker_region[i]=res[i]['device_id']
			// 	}
			// 	// console.log(this.$GLOBAL.map_data_info)
			// 	// console.log(this.transform_marker_region)
			// 	// 初始化天地图
			// 	this.initTdtMap()
			// })
			
			// 获取私有设备地图数据信息
			request.post(this.$GLOBAL.flask_web_local_url+'/get_private_map_data_info',{"username":sessionStorage.getItem('user')}).then(res => 	{
				this.$GLOBAL.map_data_info=[]
				this.transform_marker_region=[]
				for(let i in res){
					/* ！！！  " "作为字符串分割的标注,要严格按照下列规范
						"<div style='color:#55aaff;'>地址 :武汉港 <br/>设备: device1 </div>"
					*/
					let arr =[
						res[i]['lon'],
						res[i]['lat'],
						`<div style='color:#55aaff;'>地址: ${res[i]['address']} <br/>设备: ${res[i]['device_id']} </div>`
					]
					this.$GLOBAL.map_data_info.push(arr)
					this.transform_marker_region.push(res[i]['device_id'])
				}
				// console.log(this.$GLOBAL.map_data_info)
				// console.log(this.transform_marker_region)
				// 初始化天地图
				// this.initTdtMap()
				this.get_all_device_info()
			})
			
	    },
	    watch: {
	    },
	    methods: {
			data_list_select_change(){
				// console.log(this.all_data_list)
				// if(this.data_list_select==""){
				// 	for(let i in this.all_data_list){
				// 		this.all_data_list[i]['isShow']=true
				// 	}
				// }else{
				// 	for(let i in this.all_data_list){
				// 		this.all_data_list[i]['isShow']=false
				// 		// if()
				// 	}
				// }
				
			},
			expand_btn(){
				if(!this.data_list_is_expand){
					this.data_list_is_expand=true
					document.getElementById("expand_btn").className="expand_btn_layout expand_btn_close"
										// document.getElementById("expand_btn").innerHTML="<"
					document.getElementById("expand_btn").style.marginLeft="900px"
					document.getElementsByClassName("map_device_data_list")[0].style.width="900px"
					// document.getElementById("map_device_data_header").style.marginLeft="900px"
				}else{
					this.data_list_is_expand=false
					document.getElementById("expand_btn").className="expand_btn_layout expand_btn_open"
					// document.getElementById("expand_btn").innerHTML=">"
					document.getElementById("expand_btn").style.marginLeft="280px"
					document.getElementsByClassName("map_device_data_list")[0].style.width="280px"
					// document.getElementById("map_device_data_header").style.marginLeft="280px"
				}
				
				
			},
			// 查询全部设备数据
			get_all_device_data(){
				if(this.all_data_status_isShow){
					request.post(this.$GLOBAL.flask_web_local_url+'/check_device_status_2',{"username":sessionStorage.getItem('user')}).then(res => {
						// console.log(res)
						this.all_data_list_length = 0
						this.error_device_list = []
						for(let i in res){							
							this.all_data_list_length ++
							let time_key = res[i]['last_time']
							let time_obj = new Date(time_key)
							let now_time = new Date()
							let timeDiff  =  now_time.getTime() - time_obj.getTime()
							let timeDiff_s = timeDiff/1000
							// console.log(timeDiff_s)	
							// let last_time = time_obj.getFullYear()+"-"
							// 			+(time_obj.getMonth()+1)+"-"
							// 			+time_obj.getDate()+" "
							// 			+time_obj.toTimeString().substring(0,8);		
							// 还原标注图标
							// console.log(this.$GLOBAL.map_marker[i])
							// console.log([i])
							this.$GLOBAL.map_marker[i].setIcon(new T.Icon({
								iconUrl: this.marker_normal_icon_url,
								iconSize: new T.Point(25, 28),
								iconAnchor: new T.Point(12, 28)
							}))
							
							if(timeDiff_s>10){
								this.error_device_list.push({
									"device_id":res[i]['device_id'],
								})
								this.$GLOBAL.map_marker[i].setIcon(new T.Icon({
									iconUrl: this.marker_error_icon_url,
									iconSize: new T.Point(25, 28),
									iconAnchor: new T.Point(12, 28)
								}))
							}							
						}
					})
				}
				if(this.all_data_list_isShow){
					// request.get(this.$GLOBAL.flask_web_local_url+'/get_device_data_type_1_from_map?region_id=999').then(res => 	{
					request.post(this.$GLOBAL.flask_web_local_url+'/get_private_device_data_type_1_from_map_2',{"username":sessionStorage.getItem('user')}).then(res => 	{
						// let device_data = res['device_data_type_1']
						for(let item in res){
							let index = 0
							for( let j in this.$GLOBAL.map_data_info){
								if(this.$GLOBAL.map_data_info[j][2].split(" ")[4]==item){
									index = j
								}
							}
							this.all_data_list[index] = {
								"device_addres":this.$GLOBAL.map_data_info[index][2].split(" ")[2],
								"device_id":this.$GLOBAL.map_data_info[index][2].split(" ")[4],
								"AIW36":res[item]["1_1"],
								"AIW38":res[item]["1_2"],
								// "AIW52":res[item]["1_3"],
								// "AIW54":res[item]["1_4"],
								"VW108":res[item]["1_5"],
								"VW124":res[item]["1_6"],
								// "error":parseInt(item[device_i][6]['value']),							
							}
							// conso
							// 筛选功能
							if(this.data_list_select==""){
								this.all_data_list[index]['isShow']=true
							}else{
								this.all_data_list[index]['isShow']=false
								if(this.all_data_list[index]['device_addres'].indexOf(this.data_list_select)!=-1){
									this.all_data_list[index]['isShow']=true
								}
								if(this.all_data_list[index]['device_id'].indexOf(this.data_list_select)!=-1){
									this.all_data_list[index]['isShow']=true
								}
							}
						}			
						// console.log(this.all_data_list[0])
						// console.log(res)
						// }
					}).catch(function(e){
						console.error(e)
						ElMessage.error('数据加载失败 ！！！')
					})	
				}
				
			},
			// 从服务器获取设备数据
			get_device_data(marker_i){
				console.log(marker_i)
				request.get(this.$GLOBAL.flask_web_local_url+'/get_device_data_type_1_from_map?region_id='+this.transform_marker_region[marker_i]).then(res => 	{
					let marker_content_data = `	
										${this.$GLOBAL.map_data_info[marker_i][2]}
										<br/>海侧行程模拟量-AIW36：${res['device_data_type_1'][0]['value']}
										
										<br/>海侧行程读值-VW108：${res['device_data_type_1'][4]['value']}
										<br/>陆侧行程模拟量-AIW38：${res['device_data_type_1'][1]['value']}
										
										<br/>陆侧行程读值-VW124：${res['device_data_type_1'][5]['value']}
									`
									// <br/>海侧行程模拟量-AIW52：${res['device_data_type_1'][2]['value']}
									// <br/>陆侧行程模拟量--AIW54：${res['device_data_type_1'][3]['value']}
					this.$GLOBAL.map_marker_content[marker_i].setContent(marker_content_data)
					
					// let map_label_data = `
					// 				<div style="text-align: left;line-height: 20px;">
					// 					${this.$GLOBAL.map_data_info[marker_i][2]}
					// 					海侧行程模拟量-AIW36：${res['device_data_type_1'][0]['value']}
					// 					<br/>海侧行程模拟量-AIW52：${res['device_data_type_1'][2]['value']}
					// 					<br/>海侧行程读值-VW108：${res['device_data_type_1'][4]['value']}
					// 					<br/>陆侧行程模拟量-AIW38：${res['device_data_type_1'][1]['value']}
					// 					<br/>陆侧行程模拟量--AIW54：${res['device_data_type_1'][3]['value']}
					// 					<br/>陆侧行程读值-VW124：${res['device_data_type_1'][5]['value']}
					// 				</div>
					// 				`
					// this.$GLOBAL.map_label[marker_i].setLabel(map_label_data)
										
				}).catch(function(e){
					console.error(e)
					ElMessage.error('数据加载失败 ！！！')
				})	
			},
			// 11.16未完成
			// 添加更新标注数据的定时器
			add_marker_data_timer(marker_i){
				let that = this
				// 设置定时器更新数据
				if(!this.marker_data_timer[marker_i]){
					// this.marker_data_timer[marker_i] = setInterval(this.get_device_data,1000,marker_i)
					this.marker_data_timer[marker_i] = setInterval(function(){
						that.get_device_data(marker_i)
						let date = new Date()
						let time =date.toTimeString().substring(0,8);
						// console.log(time)
					},850) 
				} 
			},
			see_more_device_data(){
				this.$emit("map_action","see_more");//自定义事件  向父组件传递值
			},
			// 添加监听事件
			addClickHandler(marker_i){
				
				let that = this
				// 鼠标点击选择地点
				this.$GLOBAL.map_marker[marker_i].addEventListener("click",function(e){					
					// console.log(e.lnglat.lng+" , "+e.lnglat.lat)
					// console.log()
					let region_id = "--"
					for(let i in that.$GLOBAL.map_data_info){
						// console.log(parseFloat(that.$GLOBAL.map_data_info[i][0]) )
						
						if((Math.abs(parseFloat(that.$GLOBAL.map_data_info[i][0]) - parseFloat(e.lnglat.lng)) < 0.00001)
						&&(Math.abs(parseFloat(that.$GLOBAL.map_data_info[i][1]) - parseFloat(e.lnglat.lat)) < 0.00001)){
							// console.log(that.$GLOBAL.map_data_info[i])
							region_id = that.$GLOBAL.map_data_info[i][2].split(" ")[4]
						}
					}
					
					// console.log(region_id)
					// let region_id = "device"+(index_i+1);
					
					that.$GLOBAL.tdtMap.panTo(e.lnglat,15);					
					that.$emit("map_action",region_id);//自定义事件  向父组件传递值
					
					let item_obj = document.getElementById("device_item_"+region_id)
					if(item_obj){
					// 	// 重置未被选中的obj的背景色
					// 	for(let i in that.$GLOBAL.map_data_info){
					// 		let un_select_obj = document.getElementById("device_item_device"+(parseInt(i)+1))
					// 		un_select_obj.className="map_device_data_item_layout"
					// 	}
						for(let i in that.select_options){
							let un_select_obj = document.getElementById("device_item_"+that.select_options[i]['value'])
							un_select_obj.className="map_device_data_item_layout"
						}
					// 	// 设置选中的obj的背景色
						item_obj.className="map_device_data_item_layout map_device_data_item_layout_click"
					// 	// 将其滚动到可视范围内
						item_obj.scrollIntoView()
						window.scrollTo(0,0)
					}
					
					// 在全局变量中更新当前选择的设备id
					that.$GLOBAL.now_device_id = region_id
					
				});
				// // 改为鼠标悬浮显示
				// this.$GLOBAL.map_marker[marker_i].addEventListener("mouseover",function(e){											
				// 	//开启信息窗口
				// 	that.$GLOBAL.tdtMap.openInfoWindow(that.$GLOBAL.map_marker_content[marker_i],e.lnglat);
				// 	// that.$GLOBAL.tdtMap.addOverLay(that.$GLOBAL.map_label[marker_i]);
				// });
				//添加鼠标离开后关闭提示框
				this.$GLOBAL.map_marker[marker_i].addEventListener("mouseout",function(e){
					that.$GLOBAL.tdtMap.removeOverLay(that.$GLOBAL.map_marker_content[marker_i]);
					// that.$GLOBAL.tdtMap.closeInfoWindow()
				});
				
				// InfoWindow窗口打开事件
				that.$GLOBAL.map_marker_content[marker_i].addEventListener("open",function(e){
					// 设置定时器更新数据
					that.add_marker_data_timer(marker_i)
				})
				// InfoWindow窗口关闭事件
				that.$GLOBAL.map_marker_content[marker_i].addEventListener("close",function(e){
					clearInterval(that.marker_data_timer[marker_i]);
					that.marker_data_timer[marker_i] = ""
					// console.log(marker_i+"           *******************************")
				})
				// label点击事件
				that.$GLOBAL.map_label[marker_i].addEventListener("click",function(e){
					for(let obj in that.$GLOBAL.map_label){
						// console.log(that.$GLOBAL.map_label[obj])
						that.$GLOBAL.map_label[obj].setZindex(1)
					}
					that.$GLOBAL.map_label[marker_i].setZindex(99)
					// console.log(e)
				})
			},
			status_list_content_isShow_btn(){
				if(this.status_list_content_isShow){
					this.status_list_content_isShow = false
				}else{
					this.status_list_content_isShow = true
				}
			},
			// 查看故障设备
			select_error_device(error_item){
				this.select_all_data_list_item(error_item)
			},
			// 数据概览中选择数据项
			select_all_data_list_item(item){
				// console.log(item)
				// console.log(this.$GLOBAL.map_data_info)
				// 在全局变量中更新当前选择的设备id
				this.$GLOBAL.now_device_id = item.device_id
				
				// 截取计算item_id
				// let item_id = parseInt(item.device_id.replace("device","")) - 1
				let item_id = 0
				for(let i in this.$GLOBAL.map_data_info){
					
					if(this.$GLOBAL.map_data_info[i][2].split(" ")[4]==item.device_id){
						// console.log(this.$GLOBAL.map_data_info[i])
						item_id = i
					}
					// if(this.$GLOBAL.map_data_info[i][2].indexOf(item.device_id)!=-1){
					// 	item_id = i
					// }
				}
				// console.log(this.$GLOBAL.map_data_info[item_id])
				// 设置经纬度
				let select_lnglat = new T.LngLat(this.$GLOBAL.map_data_info[item_id][0],this.$GLOBAL.map_data_info[item_id][1])
				// 设置中心视角
				// this.$GLOBAL.tdtMap.panTo(select_lnglat);
				this.$GLOBAL.tdtMap.panTo(select_lnglat,15);
				this.$GLOBAL.markers_list.clearMarkers()
				this.$GLOBAL.markers_list = new T.MarkerClusterer(this.$GLOBAL.tdtMap, {markers: this.$GLOBAL.map_marker});
				this.$GLOBAL.markers_list.setGridSize(15)	
				
				// this.$GLOBAL.tdtMap.openInfoWindow(this.$GLOBAL.map_marker_content[item_id],select_lnglat)
				
				// item背景色设置
				let item_obj = document.getElementById("device_item_"+item.device_id)
				if(item_obj){
					// 重置未被选中的obj的背景色
					// for(let i in this.$GLOBAL.map_data_info){
					// 	let un_select_obj = document.getElementById("device_item_device"+(parseInt(i)+1))
					// 	un_select_obj.className="map_device_data_item_layout"
					// }
					for(let i in this.select_options){
						let un_select_obj = document.getElementById("device_item_"+this.select_options[i]['value'])
						un_select_obj.className="map_device_data_item_layout"
					}
					// 设置选中的obj的背景色
					item_obj.className="map_device_data_item_layout map_device_data_item_layout_click"
					// 将其滚动到可视范围内
					item_obj.scrollIntoView()
					window.scrollTo(0,0)
				}
				
				// 修改select控件的值
				this.$emit("map_action",item.device_id);//自定义事件  向父组件传递值
				
			},
			// 隐藏/显示全部数据label框
			_MenuItem_show_all_btn(){
				
				// if(!this.show_all_sign){
				// 	this.show_all_sign=true
				// 	let that = this
				// 	// 创建更新全部数据的定时器
				// 	that.get_all_device_data()
				// 	if(!this.map_label_timer){
				// 		this.map_label_timer = setInterval(function(){
				// 			that.get_all_device_data()
				// 		},900) 
				// 	}
				// 	this.ContextMenu.getItems()[1].setText("关闭全部数据")
				// 	for(let i=0;i<this.$GLOBAL.map_data_info.length;i++){
				// 		this.$GLOBAL.tdtMap.addOverLay(this.$GLOBAL.map_label[i]);
				// 	}
				// }else{
				// 	this.show_all_sign=false
				// 	this.ContextMenu.getItems()[1].setText("显示全部数据")
				// 	for(let i=0;i<this.$GLOBAL.map_data_info.length;i++){
				// 		this.$GLOBAL.tdtMap.removeOverLay(this.$GLOBAL.map_label[i]);
				// 	}
				// 	// 右侧数据概览也为关闭状态
				// 	if(!this.all_data_list_isShow&&!this.all_data_status_isShow){
				// 		// 移除定时器
				// 		clearInterval(this.map_label_timer);
				// 	}
				// 	this.map_label_timer = ""
				// }
			},
			// 右键菜单_切换最大视角按钮
			Set_max_Zoom(){
				this.$GLOBAL.tdtMap.panTo(new T.LngLat(101.57959, 32.26856),5);
				// 重置标记
				this.$GLOBAL.markers_list.clearMarkers()	
				this.$GLOBAL.markers_list = new T.MarkerClusterer(this.$GLOBAL.tdtMap, {markers: this.$GLOBAL.map_marker});
				this.$GLOBAL.markers_list.setGridSize(15)	
				// console.log(this.$GLOBAL.tdtMap.getCenter())
				
				this.$GLOBAL.tdtMap.closeInfoWindow()
			},
			device_status_list_isShow_btn(){
				let that = this
				if(!this.all_data_status_isShow){
					this.all_data_status_isShow=true
					this.get_all_device_data()
					// 创建更新全部数据的定时器
					if(!this.map_label_timer){
						this.map_label_timer = setInterval(function(){
							that.get_all_device_data()
						},2200) 
					}
					
					this.ContextMenu.getItems()[2].setText("隐藏设备数量")
				}else{
					// 数据显示功能也为关闭状态
					if(!this.show_all_sign&&!this.all_data_list_isShow){
						// 移除定时器
						// clearInterval(this.map_label_timer);
					}
					this.all_data_status_isShow=false
					this.ContextMenu.getItems()[2].setText("显示设备数量")
				}
			},
			// 隐藏/显示数据概览列表
			all_data_list_isShow_btn(){
				let that = this
				if(!this.all_data_list_isShow){
					this.all_data_list_isShow=true
					that.get_all_device_data()
					// 创建更新全部数据的定时器
					if(!this.map_label_timer){
						this.map_label_timer = setInterval(function(){
							that.get_all_device_data()
						},2200) 
					}
					this.ContextMenu.getItems()[1].setText("隐藏数据概览")
					setTimeout(() => {
						// item背景色设置
						// console.log(that.$GLOBAL.now_device_id)
						let item_obj = document.getElementById("device_item_"+that.$GLOBAL.now_device_id)
						// 设置选中的obj的背景色
						item_obj.className="map_device_data_item_layout map_device_data_item_layout_click"
						// 将其滚动到可视范围内
						item_obj.scrollIntoView()
						window.scrollTo(0,0)
					}, 600)
				}else{
					// 数据显示功能也为关闭状态
					if(!this.show_all_sign&&!this.all_data_status_isShow){
						// 移除定时器
						clearInterval(this.map_label_timer);
					}
					this.all_data_list_isShow=false
					this.ContextMenu.getItems()[1].setText("显示数据概览")
				}
			},
			// 获取用户配置信息
			get_init_settings_data(){
				
				request.get(this.$GLOBAL.flask_web_local_url+'/get_init_settings?username='+sessionStorage.getItem('user')).then(res => {
					this.init_show_all_label = res['init_device_label_isShow']
					this.init_show_all_list = res['init_device_data_list_isShow']
					this.init_show_all_status = res['init_device_status_list_isShow']
					if(this.init_show_all_list){
						let is_Phone = sessionStorage.getItem("is_Phone")
						if(is_Phone=="false"){
							this.all_data_list_isShow_btn()
						}
					}
					if(this.init_show_all_label){
						// this._MenuItem_show_all_btn()
					}
					if(this.init_show_all_status){
						
						this.device_status_list_isShow_btn()	
					}
				})
			},
			// 获取所有设备信息
			get_all_device_info(){
				request.post(this.$GLOBAL.flask_web_local_url+'/get_private_device_info',{"username":sessionStorage.getItem('user')}).then(res => {
					this.select_options = []
					for(let i in res){
						let info_data = {
							'value':res[i]['device_id'],
							'label':res[i]['device_id']+" -- "+res[i]['address'],
							'region':res[i]['region'],
							'address':res[i]['address'],
						}
						this.select_options.push(info_data)
					}
					setTimeout(() => {
						this.initTdtMap()
					},100)
					// console.log(new Date())
				})
			},
			// 初始化天地图
			initTdtMap(){
				// console.log(new Date())
				let that = this
				//初始化地图对象
				this.$GLOBAL.tdtMap = new T.Map(this.tdtMapDivID)
				//设置显示地图的中心点和级别
				// this.$GLOBAL.tdtMap.centerAndZoom(new T.LngLat(114.4393964, 30.4204975), 18)
				this.$GLOBAL.tdtMap.centerAndZoom(new T.LngLat(101.57959, 32.26856),5);
				// console.log(this.$GLOBAL.map_data_info)
				// console.log(this.$GLOBAL.map_marker)
				this.$GLOBAL.map_marker = []
				// 遍历标注数据并添加到地图中
				for(let i=0;i<this.$GLOBAL.map_data_info.length;i++){
					let index_i = parseInt(this.$GLOBAL.map_data_info[i][2].split(" ")[4].replace("device","")) - 1
					
					// 创建标记
					this.$GLOBAL.map_marker[i] = new T.Marker(new T.LngLat(this.$GLOBAL.map_data_info[i][0],this.$GLOBAL.map_data_info[i][1]));  // 创建标注
					this.$GLOBAL.map_marker[i].setIcon(new T.Icon({
						iconUrl: this.marker_normal_icon_url,
						iconSize: new T.Point(25, 28),
						iconAnchor: new T.Point(12, 28)
					}))
					// let content = this.$GLOBAL.map_data_info[i][2];
					// 将标注添加到地图中   --现通过聚合组件控制
					// this.$GLOBAL.tdtMap.addOverLay(this.$GLOBAL.map_marker[i]);   
					
					// 创建地图文本对象
					this.$GLOBAL.map_label[i] = new T.Label({
						text:`
								<div style="text-align: left;line-height: 20px;">
									${this.$GLOBAL.map_data_info[i][2]}
									海侧行程模拟量-AIW36：--
									
									<br/>海侧行程读值-VW108：--
									<br/>陆侧行程模拟量-AIW38：--
									
									<br/>陆侧行程读值-VW124：--
									<br/>test：--
								</div>
							`,
							// <br/>海侧行程模拟量-AIW52：--
							// <br/>陆侧行程模拟量--AIW54：--
						position:new T.LngLat(this.$GLOBAL.map_data_info[i][0],this.$GLOBAL.map_data_info[i][1]),
						offset: new T.Point(-12, 15)
					})
					
					// 创建地图标记内容对象
					this.$GLOBAL.map_marker_content[i] = new T.InfoWindow(
						`	${this.$GLOBAL.map_data_info[i][2]}
							<br/>海侧行程模拟量-AIW36：--
							
							<br/>海侧行程读值-VW108：--
							<br/>陆侧行程模拟量-AIW38：--
							
							<br/>陆侧行程读值-VW124：--
						`,{offset:new T.Point(0.5,-20)})
						// <br/>陆侧行程模拟量--AIW54：--
						// <br/>海侧行程模拟量-AIW52：--
					// 初始化一次数据
					// this.get_device_data(i)
					
					// console.log(this.$GLOBAL.map_data_info[i])
					// 添加相应事件
					this.addClickHandler(i);
					
				}
				
				// 聚合对象
				this.$GLOBAL.markers_list = new T.MarkerClusterer(this.$GLOBAL.tdtMap, {markers: this.$GLOBAL.map_marker});
				this.$GLOBAL.markers_list.setGridSize(15)				
				
				
				//在地图中添加文本对象
				// this.$GLOBAL.tdtMap.addOverLay(this.$GLOBAL.map_label[0]);
				
				// 添加右键菜单
				this.ContextMenu = new T.ContextMenu({
				    width: 150
				});
				let _Set_max_Zoom = new T.MenuItem("切换默认视角", this.Set_max_Zoom);
				this.ContextMenu.addItem(_Set_max_Zoom);
				// let _MenuItem_show_all = new T.MenuItem("显示全部数据", this._MenuItem_show_all_btn);
				// this.ContextMenu.addItem(_MenuItem_show_all);
				let _all_data_list_isShow_btn = new T.MenuItem("显示数据概览", this.all_data_list_isShow_btn);
				this.ContextMenu.addItem(_all_data_list_isShow_btn);
				let _device_status_list_isShow_btn = new T.MenuItem("显示设备数量", this.device_status_list_isShow_btn);
				this.ContextMenu.addItem(_device_status_list_isShow_btn);
				this.$GLOBAL.tdtMap.addContextMenu(this.ContextMenu);

				
				// 设置地图显示级别范围
				this.$GLOBAL.tdtMap.setMinZoom(3);
				this.$GLOBAL.tdtMap.setMaxZoom(18);
				// 设置地图样式
				// this.$GLOBAL.tdtMap.setStyle('block')
				// this.$GLOBAL.tdtMap.setStyle('indigo')	

				// 去掉logo
				document.getElementsByClassName("tdt-control-copyright")[0].style.display = 'none'
				
				// 默认显示device1
				// this.init_show_data()			
				
				// 初始化设置
				this.get_init_settings_data()	
				
				// 预加载一次
				this.all_data_list_isShow=true
				this.get_all_device_data()
				this.all_data_list_isShow=false
				
				
				this.$GLOBAL.tdtMap.addEventListener("click",function(e){
					// console.log(that.$GLOBAL.device_info_data_obj)
					that.$GLOBAL.device_info_data_obj['lon'] = e.lnglat.getLng()
					that.$GLOBAL.device_info_data_obj['lat'] = e.lnglat.getLat()
					ElMessage.success(
						`标记成功!	经度:${e.lnglat.getLng()}	纬度:${e.lnglat.getLat()}`
					)
					// ElMessage.success(`标记成功!`)
					// ElMessage.success(`经度:${e.lnglat.getLng()}`)
					// ElMessage.success(`纬度:${e.lnglat.getLat()}`)
					that.$GLOBAL.tdtMap.removeOverLay(that.click_marker)
					that.click_marker = new T.Marker(new T.LngLat(e.lnglat.getLng(), e.lnglat.getLat()));
					that.$GLOBAL.tdtMap.addOverLay(that.click_marker)
					// console.log(e.lnglat.getLng()+","+e.lnglat.getLat())
				})

			},
			init_show_data(){
				let init_show_data_i = 0
				let that = this
				this.$emit("map_action","device"+(parseInt(init_show_data_i)+1));//自定义事件  向父组件传递值
				setTimeout(() => {
					let lngLat = new T.LngLat(that.$GLOBAL.map_data_info[init_show_data_i][0],
											that.$GLOBAL.map_data_info[init_show_data_i][1])
					// 不默认打开信息窗口
					// that.$GLOBAL.tdtMap.openInfoWindow(that.$GLOBAL.map_marker_content[init_show_data_i],lngLat);
					that.$GLOBAL.tdtMap.panTo(lngLat)
				}, 200)
			},
		},
		beforeDestroy() {
			for(let i in this.marker_data_timer){
				clearInterval(this.marker_data_timer[i]);
			}
			clearInterval(this.map_label_timer);
		},
		// 切换界面时调用
		beforeUnmount(){
			for(let i in this.marker_data_timer){
				clearInterval(this.marker_data_timer[i]);
			}
			clearInterval(this.map_label_timer);
		}
	  }
</script>

<style>
	.divTdtMap {
		position: relative;
	    margin: 0px;
	    padding: 0px;
	    width: 100%;
	    height: 100%;
		border-radius: 10px;
		z-index: 0;
		/* filter:grayscale(0%) sepia(0%) invert(0) saturate(1)  hue-rotate(310deg) brightness(100%); */
		/* filter: hue-rotate(320deg); */

	}
	.t_map_bg{
		border-radius: 10px;
		position: absolute;
		width: 100%;
		height: 100%;
		/* background-color: rgba(19, 141, 255, 0.1) ; */
		/* 不触发点击事件 */
		pointer-events:none;
		z-index: 88;
		/* -webkit-filter: grayscale(100%); */
		/* opacity:0.7; */
		/* filter:grayscale(100%) sepia(51%) invert(100%) saturate(350%) ; */
	}
	.map_device_data_list{
		border-radius: 10px;
		position: absolute;
		width: 280px;
		height: 100%;
		/* overflow: auto; */
		background-color: rgba(0, 0, 0, 0.5) ;
		z-index: 99;
		transition: width 0.5s 0s;
	}
	.map_device_data_item_layout{
		margin-top: 10px;
		padding-top: 5px;
		/* width: 97%; */
		width: 245px;
		/* max-width: 270px; */
		margin-left: 3%;
		/* height: 230px; */
		background-color: rgba(40, 55, 82, 0.8) ; 
		margin-bottom: 20px;
		overflow: auto;
		box-shadow: 0px 0px 5px 5px rgba(108, 204, 255, 0.7) ,inset 0px 0px 10px 5px rgba(108, 204, 255, 0.7);
		border-radius: 10px;
		display: inline-block;
	}
	.map_device_data_item_layout_btn{
		/* background-color: rgba(255, 255, 255, 0.8) ; */
	}
	.map_device_data_item_layout:hover{
		/* background-color: rgba(0, 170, 255, 0.7) ; */
	}
	.map_device_data_item_layout_click{
		background-color: rgba(85, 170, 255, 0.7) ; 
		
	}
	.map_device_data_item{
		width: calc(100% - 40px);
		margin-left: 20px;
		text-align: left;
		color: aliceblue;
	}
	.map_device_data_item_btn{
		
		/* width: 130px; */
		width: calc(100% - 40px);
		height: 30px;
		line-height: 30px;
		background-color: rgba(170, 255, 127, 0.5);
		border-radius: 10px;
		margin-left: 20px;
		margin-top: 5px;
		margin-bottom: 5px;
		text-align: center;
		color: aliceblue;
	}
	.map_device_data_item_btn:hover{
		background-color: rgba(170, 170, 255, 0.9);
	}
	.map_device_status_list{
		
		border-radius: 10px;
		position: absolute;
		margin-left: calc(100% - 140px);
		width: 130px;
		/* font-size: 17px; */
		/* min-width: 150px; */
		height: 100%;
		/* background-color: rgba(255, 255, 255, 0.1) ; */
		z-index: 99;
		/* 不触发点击事件 */
		pointer-events:none;
	}
	.status_list_header{
		margin-top: 10px;
		width: 100%;
		height: 40px;
		line-height: 40px;
		background-color: rgba(85, 170, 255, 0.1) ;
		background-color: rgba(0, 0, 0, 0.6) ;
		border-radius: 10px;
		box-shadow: 0px 0px 5px 3px rgba(85, 255, 255, 0.7) ,inset 0px 0px 10px 5px rgba(85, 255, 255, 0.7);
		
	}
	.status_list_header_item{
		width: calc(100% - 10px);
		margin-left: 5px;
		font-size: 16px;
		height: 25px;
		font-weight: 600;
		color: #ffffff ;
	}
	.status_list_content{
		overflow: auto;
		width: 100%;
		margin-top: 5px;
		height: calc(100% - 105px);
		pointer-events: auto;
		background-color: rgba(0, 0, 0, 0.1) ;
	}
	.status_list_content_title{
		box-shadow: 0px 0px 5px 5px rgba(255, 85, 0, 0.7) ,inset 0px 0px 10px 5px rgba(255, 85, 0, 0.7);
		margin-top: 10px;
		border-radius: 10px;
		color: #ff5500;
		font-size: 18px;
		height: 35px;
		line-height: 35px;
		font-weight: 600;
		width: calc(100% );
		/* margin-left: 10px; */
		background-color: rgba(0, 0, 0, 0.6) ;
		pointer-events:auto;
	}
	.status_list_content_title:hover{
		box-shadow: 0px 0px 5px 5px rgba(255, 85, 0, 0.7) ,inset 0px 0px 10px 5px rgba(255, 85, 0, 0.7);
		background-color: rgba(255, 170, 0, 0.4) ;
	}
	.status_list_content_item_layout{
		width: calc(100% - 20px);
		margin-left: 10px;
		margin-top: 10px;
		margin-bottom: 15px;
		height: 40px;
		color: #000000;
		font-size: 16px;
		background-color: rgba(170, 0, 0, 0.1) ;
		background-color: rgba(0, 0, 0, 0.6) ;
		border-radius: 10px;
		padding-left: 10px;
		padding-top: 6px;
		box-shadow: 0px 0px 5px 5px rgba(255, 85, 0, 0.6) ,inset 0px 0px 10px 5px rgba(255, 85, 0, 0.6);
		pointer-events: auto;
	}
	.status_list_content_item_layout:hover{
		/* box-shadow: 0px 0px 5px 5px rgba(255, 85, 0, 0.6) ,inset 0px 0px 10px 5px rgba(255, 85, 0, 0.6); */
		background-color: rgba(0, 85, 127, 0.8) ;
	}
	.status_list_content_item{
		width: calc(100% - 20px);
		text-align: left;
		height: 25px;
		line-height: 25px;
		color: #55ffff;
	}
	.expand_btn_layout{
		margin-top: calc(20% - 25px);
		margin-left: 280px;
		z-index: 100;
		position: absolute;
		width: 25px;
		height: 50px;
		background-color:rgba(0, 0, 0, 0.7);

		color: #55ffff;
		font-size: 40px;
		line-height: 50px;
		transition: margin-left 0.5s 0s;
		/* background: url(../assets/expand_open.png) no-repeat; */
		background-image: url(../assets/expand_open.png);
		background-repeat:no-repeat;
		background-size: 25px 50px;
		border-top: 5px solid rgba(0, 0, 0, 0.7);
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.expand_btn_open{
		background-image: url(../assets/expand_open.png);
		background-repeat:no-repeat;
		background-size: 25px 40px;
		background-clip: padding-box;
	}
	.expand_btn_close{
		background-image: url(../assets/expand_close.png);
		background-repeat:no-repeat;
		background-size: 25px 40px;
		background-clip: padding-box;
	}
	.map_device_data_header_1{
		/* background-color: rgba(108, 204, 255, 0.7); */
		/* width: 50%; */
		/* margin-left: 25%; */
		height: 50px;
		/* position: fixed; */
		position: relative;
		/* width: 100%; */
		width: calc(100% - 80px);
		margin-left: 40px;
		/* margin-left: 280px; */
		transition: margin-left 0.5s 0s;
	}
	.map_device_data_header_2{
		/* background-color: rgba(108, 204, 255, 0.7); */
		width: 50%;
		/* margin-left: 25%; */
		height: 50px;
		position: fixed;
		width: 180px;
		/* margin-left: 280px; */
		transition: margin-left 0.5s 0s;
	}
	.device_item_select_col{
		/* background-color: #55aaff; */
		color: #6dfaff;
		font-size: 18px;
		font-weight: 600;
		line-height:40px;
		position: absolute;
	}
	.device_item_select_col_1{
		/* background-color: #55aaff; */
		color: #6dfaff;
		font-size: 18px;
		font-weight: 600;
		line-height:40px;
		/* position: absolute; */
	}
	.device_item_select_col .el-input__wrapper{
		background-color: rgba(0, 0, 0, 0.4) ;
		box-shadow: 0px 0px 5px 2px rgba(85, 255, 255, 0.7) ,inset 0px 0px 5px 2px rgba(85, 255, 255, 0.7);
		border: 1px solid #55ffff;
	}
	.map_device_data_list_layout{
		height: calc(100% - 50px);
		width: 100%;
		overflow: auto;
	}
</style>