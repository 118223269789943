<template>
	<div class="device_data_mainLayout">
		<div class="boxBorder1 ">
		    <div class="boxBorder2">
				<el-row class="phone_main_el_row_layout">
					<el-col :span="1"></el-col>
				    <el-col :span="6"><div class="phone_main_title_style_b" /></el-col>						
				    <el-col :span="10">
						<div class="phone_main_title_style_a" >
							设备状态数据
						</div>
					</el-col>
				    <el-col :span="6"><div class="phone_main_title_style_c" /></el-col>
					<el-col :span="1"></el-col>
				</el-row>
				
				<div class="phone_device_data_position_layout">
					<el-row class="phone_device_data_position_row">
<!-- 						<el-col :span="8" class="device_data_position_col">
							当前区域:  {{now_region_position}}
						</el-col>
						<el-col :span="8" class="device_data_position_col">
							设备id:  {{now_region_id}}
						</el-col> -->
						<el-col :span="1"></el-col>
						<el-col :span="20" class="phone_device_data_position_col">
							<el-select v-model="now_region_id" 
									class="phone_now_region_select" 
									placeholder="Select" 
									@change="select_position_change"
									filterable 
									size="small"
									
									>
							    <el-option
							      v-for="item in select_options"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value"
								  class="select_options_item"
							    />
							</el-select>
						</el-col>
						<el-col :span="1"></el-col>
						<el-col :span="2">
							<!-- <div class="show_map_btn" style="line-height: 60px; color: aliceblue;" v-if="false" @click="show_position_map"></div> -->
							<div class="phone_show_map_btn" style="line-height: 60px; color: aliceblue;" @click="saveAsTxt"></div>
							
						</el-col>
					</el-row>
				</div>
				<div class="phone_device_data_layout">
					<!-- <div class="device_position_map" id="device_position_map">
						<TdtMap v-if="position_map_isShow" v-on:map_action="from_map_action" style="position: relative;z-index: 88;">

						</TdtMap>
					</div> -->
					<el-row style="height: 100%;color: #6dfaff;font-weight: 500;font-size: 16px;" >
						<div class="device_data2_layout"> 
							<div class="phone_device_data2_select">
								<el-row  class="customer_main_header_row">
									<el-col :span="6">
										<div class="phone_device_data2_select_col">
											数据名：
										</div>
									</el-col>
									<el-col :span="6" >
										<div class="phone_device_data2_select_col">
											<el-input v-model="device_data2_title_select"
												placeholder="  " 
												clearable
												size="small"
												style="margin-top: -5px;"
												/>
										</div>
									</el-col>
							
									<el-col :span="5">
										<div class="phone_device_data2_select_col">
											状态:
										</div>
									</el-col>
									<el-col :span="6" >
										<div class="phone_device_data2_select_col device_data2_value_select">
											<el-select 
												v-model="device_data2_value_select" 
												placeholder="Select" 
												size="small"
												style="margin-top: -5px;"
												>
												<el-option
												  v-for="item in device_data2_value_select_options"
												  :key="item.value"
												  :label="item.label"
												  :value="item.value"
												  class="device_data2_value_select_item"
												/>
											</el-select>
										</div>
									</el-col>
							
									<!-- <el-col :span="8" >
										<div class="device_data2_select_button">
											<el-button @click="select_device_data2" size="large">
												查询
											</el-button>
										</div>
									</el-col> -->
							
								</el-row>
							</div>
							<div class="phone_device_data2_show_layout_3"  >
								<!-- <div class="phone_device_data2_show1" v-for="item in device_data2_list">
									<div class="phone_device_data2_show1_icon">
										
									</div>
									<div :class="item.value==0?'phone_device_data2_show1_value_close':'phone_device_data2_show1_value_open'">
									</div>
									<div class="phone_device_data2_show1_title">
										{{item.content}}
									</div>
								</div> -->
								
<!-- 								<div :class="item.id%2==0?'phone_device_data2_show1':'phone_device_data2_show1_2' " -->
								<div class="phone_device_data2_show1_2" 

										v-for="item in device_data2_list" 
										v-show="item.notes=='1'"
										>
									<!-- v-show="item.notes=='1'" -->
									
									<!-- <div class="phone_device_data2_show1_icon">
										
									</div> -->
									<!-- <div :class="item.value==0?'phone_device_data2_show1_value_close':'phone_device_data2_show1_value_open'">
										
									</div -->
									<!-- <div :class="item.value==0?'phone_device_data2_show1_value_close_2':'phone_device_data2_show1_value_open_2'"> -->
									<div :class="item.class">
										<!-- device_data2_show4_1_type1 -->
										<!-- {{item.value==0?"N":"Y"}} -->
										<!-- {{item.value==0?"N":"Y"}} -->
										<!-- {{item.value==0?"否":"是"}} -->
									</div>
									<div class="phone_device_data2_show1_title">
										{{item.content}}
										<!-- 侧试侧试侧试侧试侧试侧试侧试侧试侧试侧试侧试侧试侧试侧试侧试 -->
										<!-- <span style="color: #6dfaff;">{{item.data_position}}</span> -->
										
									</div>
								</div>
							</div>
						</div>
					</el-row>
					<el-tabs   v-model="tabs_index" tab-position="bottom" v-if="false" >
					    <el-tab-pane label="设备实时数据" :disabled="show_real_time_device_data">
							<el-row style="height: 100%;" >
								<el-col :span="5" >
									<div class="device_data1_show1">
										<div class="device_data1_show1_progress">
											<el-progress type="dashboard" 
												:percentage="percentage_AIW36/1.85" 
												:color="progress_color1" 
												stroke-width="15"
												width="150"
												>
												<template #default="{ percentage }">
												        <div class="data1_percentage-value">
															{{ percentage_AIW36 }}
														</div>
												        <div class="data1_percentage-label">
															AIW36
														</div>
												      </template>
											</el-progress>
										</div>	
										海侧行程模拟量
									</div>
									<div class="device_data1_show1">
										<div class="device_data1_show1_progress">
											<el-progress type="dashboard" 
												:percentage="percentage_AIW52/1.85" 
												:color="progress_color1" 
												stroke-width="15"
												width="150"
												>
												<template #default="{ percentage }">
												        <div class="data1_percentage-value">
															{{ percentage_AIW52 }}
														</div>
												        <div class="data1_percentage-label">
															AIW52
														</div>
												      </template>
											</el-progress>
										</div>	
										海侧行程模拟量
									</div>
									<div class="device_data1_show1">
										<div class="device_data1_show1_progress">
											<el-progress type="dashboard" 
												:percentage="percentage_AIW108/1.85" 
												:color="progress_color1" 
												stroke-width="15"
												width="150"
												>
												<template #default="{ percentage }">
												        <div class="data1_percentage-value">
															{{ percentage_AIW108 }}
														</div>
												        <div class="data1_percentage-label">
															VW108
														</div>
												      </template>
											</el-progress>
										</div>	
										海侧行程读值
									</div>
								</el-col>
								<el-col :span="14" >
									<div class="device_data1_list">
										<el-row>
											<el-col :span="12">
												<div class="device_data1_show4">
													<div class="device_data1_show4_title">
														{{device_data1_list[0].title}}
														- {{device_data1_list[0].id}}
													</div>
													<div :class="device_data1_list[0].value==0?'device_data1_show4_value_close':'device_data1_show4_value_open'">
														
													</div>
													
												</div>
											</el-col>
											<el-col :span="12">
												<div class="device_data1_show4">
													<div class="device_data1_show4_title">
														{{device_data1_list[1].title}}
														- {{device_data1_list[1].id}}
													</div>
													<div :class="device_data1_list[1].value==0?'device_data1_show4_value_close':'device_data1_show4_value_open'">
														
													</div>
												</div>
											</el-col>
										</el-row>
										
										<el-row>
											<el-col :span="12">
												<div class="device_data1_show3">
													<div class="device_data1_show4_title">
														{{device_data1_list[2].title}}
														- {{device_data1_list[2].id}}
													</div>
													<div :class="device_data1_list[2].value==0?'device_data1_show4_value_close':'device_data1_show4_value_open'">
														
													</div>
												</div>
											</el-col>
											<el-col :span="12">
												<div class="device_data1_show3">
													<div class="device_data1_show4_title">
														{{device_data1_list[3].title}}
														- {{device_data1_list[3].id}}
													</div>
													<div :class="device_data1_list[3].value==0?'device_data1_show4_value_close':'device_data1_show4_value_open'">
														
													</div>
												</div>
											</el-col>
										</el-row>
										
										<el-row>
											<el-col :span="12">
												<div class="device_data1_show3">
													<div class="device_data1_show4_title">
														{{device_data1_list[4].title}}
														- {{device_data1_list[4].id}}
													</div>
													<div :class="device_data1_list[4].value==0?'device_data1_show4_value_close':'device_data1_show4_value_open'">
														
													</div>
												</div>
											</el-col>
											<el-col :span="12">
												<div class="device_data1_show3">
													<div class="device_data1_show4_title">
														{{device_data1_list[5].title}}
														- {{device_data1_list[5].id}}
													</div>
													<div :class="device_data1_list[5].value==0?'device_data1_show4_value_close':'device_data1_show4_value_open'">
														
													</div>
												</div>
											</el-col>
										</el-row>
										
										<el-row>
											<el-col :span="12">
												<div class="device_data1_show4">
													<div class="device_data1_show4_title">
														{{device_data1_list[6].title}}
														- {{device_data1_list[6].id}}
													</div>
													<div :class="device_data1_list[6].value==0?'device_data1_show4_value_close':'device_data1_show4_value_open'">
														
													</div>
												</div>
											</el-col>
											<el-col :span="12">
												<div class="device_data1_show4">
													<div class="device_data1_show4_title">
														{{device_data1_list[7].title}}
														- {{device_data1_list[7].id}}
													</div>
													<div :class="device_data1_list[7].value==0?'device_data1_show4_value_close':'device_data1_show4_value_open'">
														
													</div>
												</div>
											</el-col>
										</el-row>

										<div class="device_count_data_layout">
											<el-row style="margin-bottom: 10px;">
												<el-col :span="12" >
													<div class="device_count_data_show1">
														海侧电机运行时间: {{count_run_time_1_1}}
													</div>
												</el-col>
												<el-col :span="12" >
													<div class="device_count_data_show2"> 
														陆侧电机运行时间: {{count_run_time_2_1}}
													</div>
												</el-col>
											</el-row>
											
											<el-row style="margin-bottom: 10px;">
												<el-col :span="12" >
													<div class="device_count_data_show1">
														海侧运行时间: {{count_run_time_1_2}}
													</div>
												</el-col>
												<el-col :span="12" >
													<div class="device_count_data_show2">
														陆侧运行时间:{{count_run_time_2_2}}
													</div>
												</el-col>
											</el-row>
											
											<el-row style="margin-bottom: 10px;">
												<el-col :span="12" >
													<div class="device_count_data_show1">
														海侧锚定运行时间: {{count_run_time_1_3}}
													</div>
												</el-col>
												<el-col :span="12" >
													<div class="device_count_data_show2">
														陆侧锚定运行时间: {{count_run_time_2_3}}
													</div>
												</el-col>
											</el-row>
											<el-row >
												<el-col :span="8" >
													<div class="device_count_data_show3" v-if="count_all_error>0" >
														故障统计: 
														<span style="color: #ff0000;font-size: 20px;"> 
															{{count_all_error}}
														</span> 
													</div>
													<div class="device_count_data_show3" v-else>
														故障统计: 0
													</div>
												</el-col>
												<el-col :span="8" >
													<div class="device_count_data_show3" v-if="count_marine_error>0" >
														海侧故障: 
														<span style="color: #ff0000;font-size: 20px;"> 
															{{count_marine_error}}
														</span> 
													</div>
													<div class="device_count_data_show3" v-else>
														海侧故障: 0
													</div>
												</el-col>
												<el-col :span="8" >
													<div class="device_count_data_show3" v-if="count_land_error>0" >
														陆侧故障: 
														<span style="color: #ff0000;font-size: 20px;"> 
															{{count_land_error}}
														</span> 
													</div>
													<div class="device_count_data_show3" v-else>
														陆侧故障: 0
													</div>
												</el-col>
											</el-row>
										</div>
										
									</div>
								</el-col>
								<el-col :span="5" >
									<div class="device_data1_show2">
										<div class="device_data1_show1_progress">
											<el-progress type="dashboard" 
												:percentage="percentage_AIW38/1.85" 
												:color="progress_color1" 
												stroke-width="15"
												width="150"
												>
												<template #default="{ percentage }">
												        <div class="data1_percentage-value">
															{{ percentage_AIW38 }}
														</div>
												        <div class="data1_percentage-label">
															AIW38
														</div>
												      </template>
											</el-progress>
										</div>	
										陆侧行程模拟量
									</div>
									<div class="device_data1_show2">
										<div class="device_data1_show1_progress">
											<el-progress type="dashboard" 
												:percentage="percentage_AIW54/1.85" 
												:color="progress_color1" 
												stroke-width="15"
												width="150"
												>
												<template #default="{ percentage }">
												        <div class="data1_percentage-value">
															{{ percentage_AIW54 }}
														</div>
												        <div class="data1_percentage-label">
															AIW54
														</div>
												      </template>
											</el-progress>
										</div>	
										陆侧行程模拟量
									</div>
									<div class="device_data1_show2">
										<div class="device_data1_show1_progress">
											<el-progress type="dashboard" 
												:percentage="percentage_AIW124/1.85" 
												:color="progress_color1" 
												stroke-width="15"
												width="150"
												>
												<template #default="{ percentage }">
												        <div class="data1_percentage-value">
															{{ percentage_AIW124 }}
														</div>
												        <div class="data1_percentage-label">
															VW124
														</div>
												      </template>
											</el-progress>
										</div>	
										陆侧行程读值
									</div>
								</el-col>
							</el-row>
							
						</el-tab-pane>
					   
						<el-tab-pane label="设备状态数据" :disabled="show_status_device_data">
							<div class="device_data2_select">
								<el-row :gutter="20"  class="customer_main_header_row">
									<el-col :span="5">
										<div class="phone_device_data2_select_col">
											数据名称:
										</div>
									</el-col>
									<el-col :span="3" >
										<div class="phone_device_data2_select_col">
											<el-input v-model="device_data2_title_select"
												placeholder="  " 
												clearable
												/>
										</div>
									</el-col>

									<el-col :span="5">
										<div class="phone_device_data2_select_col">
											状态:
										</div>
									</el-col>
									<el-col :span="3" >
										<div class="phone_device_data2_select_col device_data2_value_select">
											<el-select v-model="device_data2_value_select" placeholder="Select" size="large">
												<el-option
												  v-for="item in device_data2_value_select_options"
												  :key="item.value"
												  :label="item.label"
												  :value="item.value"
												  class="device_data2_value_select_item"
												/>
											</el-select>
										</div>
									</el-col>

									<el-col :span="8" >
										<div class="device_data2_select_button">
											<el-button @click="select_device_data2" size="large">
												查询
											</el-button>
										</div>
									</el-col>

								</el-row>
							</div>
							<div class="phone_device_data2_show_layout_3"  >
								<div class="phone_device_data2_show1" v-for="item in device_data2_list">
									<div class="phone_device_data2_show1_icon">
										
									</div>
									<div :class="item.value==0?'phone_device_data2_show1_value_close':'phone_device_data2_show1_value_open'">
										<!-- {{item.value==0?"关":"开"}} -->
									</div>
									<div class="phone_device_data2_show1_title">
										{{item.content}}
										<!-- {{item.data_position}} -->
									</div>
								</div>
									
									
							</div>
							
						</el-tab-pane>
					   
						<el-tab-pane label="设备维护数据" :disabled="show_maintenance_device_data">
							<div class="device_data3_show_layout" >
								<el-row style="width: calc(100% - 25px);">
									<el-col :span="2" >
										<div class="device_data3_col_title" style="border-top-left-radius: 20px;">
											序号
										</div>
									</el-col>
									<el-col :span="16">
										<div class="device_data3_col_title">
											检查内容
										</div>
									</el-col>
									<el-col :span="6">
										<div class="device_data3_col_title" style="border-top-right-radius: 20px;">
											检查结果
										</div>
									</el-col>
								</el-row>
								<div class="device_data3_show1_layout">
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col" style="margin-top: 0px;">
												{{device_data3_list[0].id}}
											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content" style="margin-top: 0px;">
											{{device_data3_list[0].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col" style="margin-top: 0px;">
												{{device_data3_list[0].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col">
												{{device_data3_list[1].id}}
											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content" >
												{{device_data3_list[1].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col">
												{{device_data3_list[1].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col">
												{{device_data3_list[2].id}}
											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content" >
												{{device_data3_list[2].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col">
												{{device_data3_list[2].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col">
												{{device_data3_list[3].id}}
											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content">
												{{device_data3_list[3].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col">
												{{device_data3_list[3].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col">
												{{device_data3_list[4].id}}
											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content">
												{{device_data3_list[4].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col">
												{{device_data3_list[4].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col">
												{{device_data3_list[5].id}}

											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content">
												{{device_data3_list[5].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col">
												{{device_data3_list[5].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col">
												{{device_data3_list[6].id}}
											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content">
												{{device_data3_list[6].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col">
												{{device_data3_list[6].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col">
												{{device_data3_list[7].id}}
											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content">
												{{device_data3_list[7].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col">
												{{device_data3_list[7].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col">
												{{device_data3_list[8].id}}
											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content">
												{{device_data3_list[8].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col">
												{{device_data3_list[8].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
									<el-row style="width: calc(100% - 10px);">
										<el-col :span="2" >
											<div class="device_data3_col" style="border-bottom-left-radius: 20px;">
												{{device_data3_list[9].id}}
											</div>
										</el-col>
										<el-col :span="16">
											<div class="device_data3_col device_data3_col_content">
												{{device_data3_list[9].content}}
											</div>
										</el-col>
										<el-col :span="6">
											<div class="device_data3_col" style="border-bottom-right-radius: 20px;">
												{{device_data3_list[9].value=="1" ? "是":"否"}}
											</div>
										</el-col>
									</el-row>
								</div>
							
								<div class="device_data3_update_time">
									数据更新时间：{{device_data3_list[10].update_time}}
								</div>
							</div>
					   	
						</el-tab-pane>
					</el-tabs>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import request from "@/utils/request";
	import { ElMessage } from 'element-plus'
	import TdtMap from '../components/TdtMap.vue'

	export default{
		components:{
			TdtMap,
		},
		data() {
			return{
				now_region_position:"",
				now_region_id:"none",
				tabs_index:"1",
				position_map_isShow:false,
				device_data2_title_select:"",
				device_data2_value_select:"——",
				device_data2_value_select_options:[
					{
					    label: '否',
						value: '0',
					},{
					    label: '是',
					    value: '1',
					},{
						value: '——',
						label: '——',
					}
				],
				device_data1_list:[
					{
						id:"Q1.2",
						value:0,
						title:"海侧夹紧状态"
					},{
						id:"Q1.3",
						value:0,
						title:"海侧松开状态"
					},{
						id:"Q1.4",
						value:0,
						title:"陆侧夹紧状态"
					},{
						id:"Q1.5",
						value:0,
						title:"陆侧松开状态"
					},{
						id:"Q13.4",
						value:0,
						title:"陆侧锚定插入到位"
					},{
						id:"Q13.5",
						value:0,
						title:"陆侧锚定提起到位"
					},{
						id:"Q13.6",
						value:0,
						title:"海侧锚定插入到位"
					},{
						id:"Q13.7",
						value:0,
						title:"海侧锚定提起到位"
					},
				],
				count_run_time_1_1:"--",
				count_run_time_1_2:"--",
				count_run_time_1_3:"--",
				count_run_time_2_1:"--",
				count_run_time_2_2:"--",
				count_run_time_2_3:"--",
				count_all_error:"--",
				count_marine_error:"--",
				count_land_error:"--",
				
				percentage_AIW36:"--",
				percentage_AIW52:"--",
				percentage_AIW108:"--",
				percentage_AIW38:"--",
				percentage_AIW54:"--",
				percentage_AIW124:"--",
				show_real_time_device_data:true,
				show_status_device_data:true,
				show_maintenance_device_data:true,
			
				device_data2_list:[
					// {
					// 	id:1,
					// 	value:0,
					// 	content:"远程松指令",
					// 	icon:"",
					// },
					// {
					// 	id:2,
					// 	value:1,
					// 	content:"远程紧指令",
					// 	icon:"",
					// },
					// {
					// 	id:3,
					// 	value:0,
					// 	content:"陆侧锚定接触器故障",
					// 	icon:"",
					// },{
					// 	id:4,
					// 	value:1,
					// 	content:"陆侧锚定抬起超时",
					// 	icon:"",
					// },
					// {
					// 	id:5,
					// 	value:0,
					// 	content:"陆侧锚定落下超时",
					// 	icon:"",
					// },
					// {
					// 	id:6,
					// 	value:0,
					// 	content:"海侧锚定接触器故障",
					// 	icon:"",
					// },
					// {
					// 	id:7,
					// 	value:0,
					// 	content:"海侧锚定抬起超时",
					// 	icon:"",
					// },
					// {
					// 	id:8,
					// 	value:0,
					// 	content:"海侧锚定落下超时",
					// 	icon:"",
					// },
					// {
					// 	id:9,
					// 	value:0,
					// 	content:"传感器断线1M故障",
					// 	icon:"",
					// },
					// {
					// 	id:11,
					// 	value:0,
					// 	content:"海侧夹紧超时M",
					// 	icon:"",
					// },
					// {
					// 	id:11,
					// 	value:1,
					// 	content:"海侧打开超时M",
					// 	icon:"",
					// },
					// {
					// 	id:12,
					// 	value:1,
					// 	content:"电机1接触器故障M",
					// 	icon:"",
					// },
					// {
					// 	id:13,
					// 	value:0,
					// 	content:"海侧补压过多M",
					// 	icon:"",
					// },
					// {
					// 	id:14,
					// 	value:0,
					// 	content:"海侧空开跳闸",
					// 	icon:"",
					// },
					// {
					// 	id:15,
					// 	value:1,
					// 	content:"传感器断线2M故障",
					// 	icon:"",
					// },
					// {
					// 	id:16,
					// 	value:0,
					// 	content:"陆侧松开超时M",
					// 	icon:"",
					// },
					// {
					// 	id:17,
					// 	value:0,
					// 	content:"陆侧夹紧超时M",
					// 	icon:"",
					// },
					// {
					// 	id:18,
					// 	value:1,
					// 	content:"电机2接触器故障M",
					// 	icon:"",
					// },
					// {
					// 	id:19,
					// 	value:0,
					// 	content:"陆侧补压过多M",
					// 	icon:"",
					// },
					// {
					// 	id:21,
					// 	value:0,
					// 	content:"陆侧空开跳闸",
					// 	icon:"",
					// },
					// {
					// 	id:21,
					// 	value:0,
					// 	content:"plc扩展模块1故障",
					// 	icon:"",
					// },
					// {
					// 	id:22,
					// 	value:0,
					// 	content:"plc扩展模块2故障",
					// 	icon:"",
					// },
					// {
					// 	id:23,
					// 	value:1,
					// 	content:"远程/本地",
					// 	icon:"",
					// },
					// {
					// 	id:24,
					// 	value:0,
					// 	content:"海侧松开",
					// 	icon:"",
					// },
					// {
					// 	id:25,
					// 	value:0,
					// 	content:"海侧夹紧",
					// 	icon:"",
					// },
					// {
					// 	id:26,
					// 	value:0,
					// 	content:"陆侧松开",
					// 	icon:"",
					// },
					// {
					// 	id:27,
					// 	value:1,
					// 	content:"陆侧夹紧",
					// 	icon:"",
					// },
					// {
					// 	id:28,
					// 	value:0,
					// 	content:"旁路",
					// 	icon:"",
					// },
					// {
					// 	id:29,
					// 	value:0,
					// 	content:"海侧空开",
					// 	icon:"",
					// },
					// {
					// 	id:31,
					// 	value:0,
					// 	content:"陆侧空开",
					// 	icon:"",
					// },
					// {
					// 	id:31,
					// 	value:0,
					// 	content:"远程松开",
					// 	icon:"",
					// },
					// {
					// 	id:32,
					// 	value:0,
					// 	content:"远程夹紧",
					// 	icon:"",
					// },
					// {
					// 	id:33,
					// 	value:1,
					// 	content:"陆侧锚定空开",
					// 	icon:"",
					// },
					// {
					// 	id:34,
					// 	value:0,
					// 	content:"海侧锚定空开",
					// 	icon:"",
					// },
					// {
					// 	id:35,
					// 	value:0,
					// 	content:"陆侧锚定电机1热继动作",
					// 	icon:"",
					// },
					// {
					// 	id:36,
					// 	value:0,
					// 	content:"陆侧锚定电机2热继动作",
					// 	icon:"",
					// },
					// {
					// 	id:37,
					// 	value:0,
					// 	content:"海侧锚定电机1热继动作",
					// 	icon:"",
					// },
					// {
					// 	id:38,
					// 	value:1,
					// 	content:"海侧锚定电机2热继动作",
					// 	icon:"",
					// },
					// {
					// 	id:39,
					// 	value:0,
					// 	content:"陆侧锚定提起",
					// 	icon:"",
					// },
					// {
					// 	id:40,
					// 	value:0,
					// 	content:"陆侧锚定插入",
					// 	icon:"",
					// },
					// {
					// 	id:41,
					// 	value:1,
					// 	content:"海侧锚定提起",
					// 	icon:"",
					// },
					// {
					// 	id:42,
					// 	value:0,
					// 	content:"海侧锚定插入",
					// 	icon:"",
					// },
					// {
					// 	id:43,
					// 	value:0,
					// 	content:"远程锚定提起",
					// 	icon:"",
					// },
					// {
					// 	id:44,
					// 	value:0,
					// 	content:"远程锚定插入",
					// 	icon:"",
					// },
					// {
					// 	id:45,
					// 	value:0,
					// 	content:"M1电机得电",
					// 	icon:"",
					// },
					// {
					// 	id:46,
					// 	value:1,
					// 	content:"M2电机得电",
					// 	icon:"",
					// },
					// {
					// 	id:47,
					// 	value:0,
					// 	content:"故障",
					// 	icon:"",
					// },
					// {
					// 	id:48,
					// 	value:0,
					// 	content:"海侧夹紧状态反馈",
					// 	icon:"",
					// },
					// {
					// 	id:49,
					// 	value:1,
					// 	content:"海侧松开状态反馈",
					// 	icon:"",
					// },
					// {
					// 	id:50,
					// 	value:0,
					// 	content:"陆侧夹紧状态反馈",
					// 	icon:"",
					// },
					// {
					// 	id:51,
					// 	value:1,
					// 	content:"陆侧松开状态反馈",
					// 	icon:"",
					// },
					// {
					// 	id:52,
					// 	value:1,
					// 	content:"电气故障指示灯",
					// 	icon:"",
					// },
					// {
					// 	id:53,
					// 	value:0,
					// 	content:"液压故障指示灯",
					// 	icon:"",
					// },
					// {
					// 	id:54,
					// 	value:0,
					// 	content:"海侧液位低报警",
					// 	icon:"",
					// },
					// {
					// 	id:55,
					// 	value:1,
					// 	content:"海侧高温报警",
					// 	icon:"",
					// },
					// {
					// 	id:56,
					// 	value:0,
					// 	content:"海侧液压站堵塞报警",
					// 	icon:"",
					// },
					// {
					// 	id:57,
					// 	value:1,
					// 	content:"海侧电气故障",
					// 	icon:"",
					// },
					// {
					// 	id:58,
					// 	value:0,
					// 	content:"陆侧液位低报警",
					// 	icon:"",
					// },
					// {
					// 	id:59,
					// 	value:0,
					// 	content:"陆侧高温报警",
					// 	icon:"",
					// },
					// {
					// 	id:61,
					// 	value:0,
					// 	content:"陆侧液压站堵塞报警",
					// 	icon:"",
					// },
					// {
					// 	id:61,
					// 	value:0,
					// 	content:"陆侧电气故障",
					// 	icon:"",
					// },
					// {
					// 	id:62,
					// 	value:0,
					// 	content:"陆侧锚定正转中继",
					// 	icon:"",
					// },
					// {
					// 	id:63,
					// 	value:1,
					// 	content:"陆侧锚定反转中继",
					// 	icon:"",
					// },
					// {
					// 	id:64,
					// 	value:0,
					// 	content:"海侧锚定正转中继",
					// 	icon:"",
					// },
					// {
					// 	id:65,
					// 	value:0,
					// 	content:"海侧锚定反转中继",
					// 	icon:"",
					// },
					// {
					// 	id:66,
					// 	value:0,
					// 	content:"陆侧锚定插入到位反馈",
					// 	icon:"",
					// },
					// {
					// 	id:67,
					// 	value:0,
					// 	content:"陆侧锚定提起到位反馈",
					// 	icon:"",
					// },
					// {
					// 	id:68,
					// 	value:1,
					// 	content:"海侧锚定插入到位反馈",
					// 	icon:"",
					// },
					// {
					// 	id:69,
					// 	value:1,
					// 	content:"海侧锚定提起到位反馈",
					// 	icon:"",
					// }				
				],
				device_data3_list:[
					{
						id:1,
						content:"金属结构有无严重变形及裂纹、焊缝有无开裂等情况",
						value:"检查结果",
					},
					{
						id:2,
						content:"各部位的螺栓紧固情况，如法兰面螺栓、复位支架、液压站、电控箱、锚定机构连接螺栓等",
						value:"检查结果",
					},
					{
						id:3,
						content:"导向滚轮能否顺利转动，踏面磨损情况",
						value:"检查结果",
					},
					{
						id:4,
						content:"清槽板、开合机构/复位弹簧是否完好，是否有零件缺失，如夹紧块",
						value:"检查结果",
					},
					{
						id:5,
						content:"油缸的伸缩动作是否正常，夹钳转动是否灵活",
						value:"检查结果",
					},
					{
						id:6,
						content:"锚定机构是否正常，如销轴是否灵活，限位开关、自动锚定是否正常等",
						value:"检查结果",
					},
					{
						id:7,
						content:"线缆连接是否松动，传感器是否正常，电控柜各指示灯是否正常",
						value:"检查结果",
					},
					{
						id:8,
						content:"液压站的油位，油缸以及液压回路是否存在漏油情况，油缸保压是否正常",
						value:"检查结果",
					},
					{
						id:9,
						content:"轨道及轨道沟槽是否满足使用要求",
						value:"检查结果",
					},
					{
						id:10,
						content:"是否定期加注润滑油脂(每个月一次)",
						value:"检查结果",
					},
					{
						id:11,
						update_time:"--------------------------"
					},
				],
				update_dataList_timer:"",

				progress_color1 : [
				  { color: '#55ffff', percentage: 100 },
				  { color: '#55ff7f', percentage: 80 },
				  { color: '#55aaff', percentage: 40 },
				],
				
				init_sign:"1",
				init_sign_equipment:"1",
				select_options:[
				],
				socket:"",
			}
		},
		watch: {

		},
		methods:{
			saveAsTxt(){
				// let str = JSON.stringify(this.device_data1_list)
				let date = new Date()
				let date_time = date.getFullYear()+"-"
							+(date.getMonth()+1)+"-"
							+date.getDate()+" "
							+date.toTimeString().substring(0,2)+"-"
							+date.toTimeString().substring(3,5)+"-"
							+date.toTimeString().substring(6,8);
				let count = 1
				let str = ""
				for(let i in this.device_data2_list){
					// console.log(this.device_data2_list[i])
					str = str + count + ".	"
						+this.device_data2_list[i]['value']+"	"	
						+this.device_data2_list[i]['content']
						+"("+this.device_data2_list[i]['data_position']+")"
						+"\n"
					count++
				}
				// let str = "1.	海侧电机运行时间Q0.6):			"+this.count_run_time_1_1+"\n"
				// let id = parseInt(this.now_region_id.replace("device",""))-1
				let options_index = 0
				for(let i in this.select_options){
					if(this.select_options[i]['value']==this.now_region_id){
						options_index = i
					}
				}
				let str_device_info = "当前设备:	"+this.now_region_id+"	"+
						this.now_region_position+"	"+this.select_options[options_index]['address']+"\n\n"
				let all_str = str_device_info + str 
				const blob = new Blob([all_str]);
				const href = URL.createObjectURL(blob);
			    const alink = document.createElement("a");
			    alink.style.display = "none";
			    alink.download = "type_2_"+date_time+".txt"; // 下载后文件名
				alink.href = href;
			    document.body.appendChild(alink);
			    alink.click();
			    document.body.removeChild(alink); // 下载完成移除元素
			    URL.revokeObjectURL(href); // 释放掉blob对象
			
			},
			from_map_action(val){
				if(val=="see_more"){
					this.show_position_map()
					this.tabs_index="1"
					
					
				}else{
					// 从地图选择当前区域设备id
					// console.log(val)
					this.now_region_id=val
					// let id = parseInt(val.replace("device",""))-1
					// this.now_region_position=this.select_options[id]['region']					
					for(let i in this.select_options){
						if(this.select_options['value']==val){
							this.now_region_position=this.select_options[i]['region']
						}
					}
					// 从服务器更新数据
					this.update_dataList()
				}
				
			},
			select_position_change(){
				// 在全局变量中更新当前选择的设备id
				this.$GLOBAL.now_device_id = this.now_region_id
				
				// let id = parseInt(this.now_region_id.replace("device",""))-1
				// this.now_region_position=this.select_options[id]['region']
				for(let i in this.select_options){
					if(this.select_options[i]['value']==this.now_region_id){
						this.now_region_position=this.select_options[i]['region']
					}
				}
				// console.log(this.position_map_isShow)
				if(this.position_map_isShow){
					let lnglat = this.$GLOBAL.map_marker[id].getLngLat()
					this.$GLOBAL.tdtMap.panTo(lnglat)
					this.$GLOBAL.tdtMap.openInfoWindow(this.$GLOBAL.map_marker_content[id],lnglat)
					
					let item_obj = document.getElementById("device_item_"+this.now_region_id)
					if(item_obj){
						// 重置未被选中的obj的背景色
						for(let i in this.$GLOBAL.map_data_info){
							let un_select_obj = document.getElementById("device_item_device"+(parseInt(i)+1))
							un_select_obj.className="map_device_data_item_layout"
						}
						// 设置选中的obj的背景色
						item_obj.className="map_device_data_item_layout map_device_data_item_layout_click"
						// 将其滚动到可视范围内
						item_obj.scrollIntoView()
						window.scrollTo(0,0)
					}
				}
				// 从服务器更新数据
				this.update_dataList()
			},
			update_from_websocket(){
				if("WebSocket" in window){
				// 　　　　	console.log("您的浏览器支持WebSocket");
				let date = new Date()
				// 根据时间生成id
				let time_id =date.toTimeString().substring(0,2)+date.toTimeString().substring(3,5)+date.toTimeString().substring(6,8);
				// 　　　　	this.socket = new WebSocket("wss://120.26.82.44:9999/web/websocket_vue/"+time_id)
				　　this.socket = new WebSocket("wss://9jcrobot.com/web/websocket_vue/"+time_id)
					this.socket.onopen = function(){
						//当WebSocket创建成功时，触发onopen事件
						console.log("websocket 连接服务器成功")
					}
					let that = this
					
					this.socket.onmessage = function(e){
						//当客户端收到服务端发来的消息时，触发onmessage事件，参数e.data包含server传递过来的数据	
						let msg = JSON.parse(e.data)
						console.log(msg);
	
						}
					this.socket.onclose = function(e){
					　　//当客户端收到服务端发送的关闭连接请求时，触发onclose事件
					　　console.log("websocket 与服务器连接关闭");
					}
					this.socket.onerror = function(e){
					　　//如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
					　　console.log(e);
					}
				}else{
			　　		console.log("您的浏览器不支持WebSocket");
				}
			},
			//页面窗口监听
			window_resize(){
				console.log("resize");
			},
			show_position_map(){
				let that = this
				// if(!that.position_map_isShow){
				// 	that.tabs_index="0"
				// }
				setTimeout(function () {
				    if(that.position_map_isShow){
				    	document.getElementById("device_position_map").style.marginTop="calc(100% + 200px)"
						that.position_map_isShow=false
				    	
				    }else{
				    	document.getElementById("device_position_map").style.marginTop="0px"
						setTimeout(() => {
							that.position_map_isShow=true
							setTimeout(() => {
								that.select_position_change()
							}, 200)
						}, 500)
				    }
				 }, 100)
			},
			select_device_data2(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_device_data_type_2?region_id='+this.now_region_id).then(res => 	{
					let obj = res['device_data_type_2']
					this.device_data2_list = []
					// 是
					for(let i in obj){
						// if(obj[i]['notes']=="1"){
							// 内容筛选
							if(obj[i].content.indexOf(this.device_data2_title_select)!=-1||this.device_data2_title_select==""){
								// 状态筛选
								if(obj[i].value==this.device_data2_value_select||this.device_data2_value_select=="——"){
									if(['AIW36','AIW38','VW108','VW124','AIW52','AIW54'].indexOf(obj[i]['data_position'])==-1){
										if(obj[i]['value']==1||obj[i]['value']=='1'){
											let check_data_title = ['陆侧锚定接触器故障','陆侧锚定抬起超时','陆侧锚定落下超时','海侧锚定接触器故障','海侧锚定抬起超时','海侧锚定落下超时','传感器断线1M故障','海侧防爬器夹紧超时M','海侧防爬器打开超时M','电机1接触器故障M','海侧防爬器补压过多M','海侧空开跳闸','传感器断线2M故障','陆侧防爬器松开超时M','陆侧防爬器夹紧超时M','电机2接触器故障M','陆侧防爬器补压过多M','陆侧空开跳闸','plc扩展模块1故障','plc扩展模块2故障','陆侧锚定电机1热继动作','陆侧锚定电机2热继动作','海侧锚定电机1热继动作','海侧锚定电机2热继动作','故障','电气故障指示灯','液压故障指示灯','海侧液位低报警','海侧高温报警','海侧液压站堵塞报警','海侧电气故障','陆侧液位低报警','陆侧高温报警','陆侧液压站堵塞报警','陆侧电气故障']
											obj[i]['class'] = 'phone_device_data2_show4_1_type1'
											for(let j in check_data_title){
												if(obj[i]['content'].indexOf(check_data_title[j])!=-1){
													obj[i]['class'] = 'phone_device_data2_show4_1_type2'
													break
												}
											}
											this.device_data2_list.push(obj[i])
										}
									}
								}
							}
						// }
					}
					// 否
					for(let i in obj){
						// if(obj[i]['notes']=="1"){
							// 内容筛选
							if(obj[i].content.indexOf(this.device_data2_title_select)!=-1||this.device_data2_title_select==""){
								// 状态筛选
								if(obj[i].value==this.device_data2_value_select||this.device_data2_value_select=="——"){
									if(['AIW36','AIW38','VW108','VW124','AIW52','AIW54'].indexOf(obj[i]['data_position'])==-1){
										if(obj[i]['value']==0||obj[i]['value']=='0'){
											obj[i]['class'] = 'phone_device_data2_show4_0'
											this.device_data2_list.push(obj[i])
										}
									}
								}
							}
						// }
					}
					
					// for(let i in obj){
					// 	// if(obj[i]['notes']=="1"){
					// 		// 内容筛选
					// 		if(obj[i].content.indexOf(this.device_data2_title_select)!=-1||this.device_data2_title_select==""){
					// 			// 状态筛选
					// 			if(obj[i].value==this.device_data2_value_select||this.device_data2_value_select=="——"){
					// 				if(['AIW36','AIW38','VW108','VW124','AIW52','AIW54'].indexOf(obj[i]['data_position'])==-1){
					// 					this.device_data2_list.push(obj[i])
					// 				}
					// 			}
					// 		}
					// 	// }
					// }
				}).catch(function(){
					ElMessage.error('设备状态数据加载失败 ！！！')
				})	
			},
			get_device_data_type_1(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_device_data_type_1?region_id='+this.now_region_id).then(res => 	{
					this.percentage_AIW36=res['device_data_type_1'][0].value
					this.percentage_AIW38=res['device_data_type_1'][1].value
					this.percentage_AIW52=res['device_data_type_1'][2].value
					this.percentage_AIW54=res['device_data_type_1'][3].value
					this.percentage_AIW108=res['device_data_type_1'][4].value
					this.percentage_AIW124=res['device_data_type_1'][5].value
					for(let i = 0;i<this.device_data1_list.length;i++){
						this.device_data1_list[i].id=res['device_data_type_1'][6+i].data_position
						this.device_data1_list[i].value=res['device_data_type_1'][6+i].value
						this.device_data1_list[i].title=res['device_data_type_1'][6+i].content
					}
					this.count_run_time_1_1=res['device_data_type_1'][14].value
					this.count_run_time_1_2=res['device_data_type_1'][15].value
					this.count_run_time_1_3=res['device_data_type_1'][16].value
					this.count_run_time_2_1=res['device_data_type_1'][17].value
					this.count_run_time_2_2=res['device_data_type_1'][18].value
					this.count_run_time_2_3=res['device_data_type_1'][19].value
					this.count_all_error=res['device_data_type_1'][20].value
					this.count_marine_error=res['device_data_type_1'][21].value
					this.count_land_error=res['device_data_type_1'][22].value
					
				}).catch(function(){
					ElMessage.error('设备实时数据加载失败 ！！！')
				})	
			},
			get_device_data_type_2(){
				this.select_device_data2()
				// request.get(this.$GLOBAL.flask_web_local_url+'/get_device_data_type_2').then(res => 	{
				// 	this.device_data2_list = []
				// 	for(let i in res['device_data_type_2']){
				// 		this.device_data2_list.push(res['device_data_type_2'][i])
				// 		// this.device_data2_list[i].id=res['device_data_type_2'][i].type_id
				// 		// this.device_data2_list[i].value=res['device_data_type_2'][i].value
				// 		// this.device_data2_list[i].content=res['device_data_type_2'][i].content
				// 		// this.device_data2_list[i].data_position=res['device_data_type_2'][i].data_position
				// 	}
				// })
			},
			get_device_data_type_3(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_device_data_type_3?region_id='+this.now_region_id).then(res => 	{
					for(let i = 0 ;i< this.device_data3_list.length-1;i++){
						this.device_data3_list[i].content=res['device_data_type_3'][i].content
						this.device_data3_list[i].value=res['device_data_type_3'][i].value
					}
					let i = this.device_data3_list.length-1
					this.device_data3_list[i].update_time=res['device_data_type_3'][0].time
				}).catch(function(){
					ElMessage.error('设备维护数据加载失败 ！！！')
				})	
			},
			update_dataList(){
				if(!this.position_map_isShow){
					switch(this.tabs_index){
						case "0":
							this.get_device_data_type_1()
							break
						case "1":
							this.get_device_data_type_2()
							break
						case "2":
							this.get_device_data_type_3()
							break
					}
				}

			},
			check_user_permission(){
				let promiseArr = [];
				    
				//异步方法1
			    let p1 = new Promise((resolve, reject) => {
					// 调用全局的判断用户权限方法
					this.$GLOBAL.methods.check_permission('show_real_time_device_data').then(val=>{
						if(val=="500"){
							this.$router.push("/login")
						}else {
							if(val){
								this.show_real_time_device_data=false
							}else{
								this.show_real_time_device_data=true
							}
						}
						//结束任务
						resolve();
					})
				})
				//异步方法2
				let p2 = new Promise((resolve, reject) => {
					// 调用全局的判断用户权限方法
					this.$GLOBAL.methods.check_permission('show_status_device_data').then(val=>{
						if(val=="500"){
							this.$router.push("/login")
						}else {
							if(val){
								this.show_status_device_data=false
							}else{
								this.show_status_device_data=true								
							}
						}
						//结束任务
						resolve();
					})
				})
				//异步方法3
				let p3 = new Promise((resolve, reject) => {
					// 调用全局的判断用户权限方法
					this.$GLOBAL.methods.check_permission('show_maintenance_device_data').then(val=>{
						if(val=="500"){
							this.$router.push("/login")
						}else {
							if(val){
								this.show_maintenance_device_data=false
							}else{
								this.show_maintenance_device_data=true
							}
						}
						//结束任务
						resolve();
					})
				})
					
				promiseArr.push(p1)
				promiseArr.push(p2)
				promiseArr.push(p3)
				
				//等所有promise任务执行完毕后再执行 
				Promise.all(promiseArr).then(res => {
			        //上边异步方法执行完毕之后 会执行这里的方法			 
			        if(this.show_real_time_device_data){
			        	if(this.show_status_device_data){
			        		if(this.show_maintenance_device_data){
			        			
			        		}else{
								this.tabs_index="2"
							}
			        	}else{
							this.tabs_index="1"
						}
			        }
					this.update_dataList()
			    })

			},
			// 获取所有设备信息
			get_all_device_info(){
				request.post(this.$GLOBAL.flask_web_local_url+'/get_private_device_info',{"username":sessionStorage.getItem('user')}).then(res => {
					for(let i in res){
						let info_data = {
							'value':res[i]['device_id'],
							// 'label':res[i]['device_id']+" -- "+res[i]['address'],
							'label':res[i]['address']+" -- "+res[i]['region'],
							'region':res[i]['region'],
							'address':res[i]['address'],
						}
						this.select_options.push(info_data)
					}
					// 通过服务器获取默认选择的区域设备id  *****************************
					this.get_init_settings_data()
				})
			},
			// 获取用户配置信息
			get_init_settings_data(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_init_settings?username='+sessionStorage.getItem('user')).then(res => {
					// console.log(this.select_options)
					this.now_region_id = this.select_options[0]['value']
					this.now_region_position = this.select_options[0]['region']
					
					for(let i in this.select_options){
						if(this.select_options[i]['value']==res['init_device_id']){
							this.now_region_id = res['init_device_id']
							this.now_region_position = this.select_options[i]['region']
						}
					}
					this.update_dataList()
					this.update_dataList_timer = setInterval(this.update_dataList,950)//定时获取
				})
			},
		},
		computed: {
	
		},
		//模板渲染完成后执行，可以拿到DOM节点和数据
		mounted(){
			
			// // 通过服务器获取默认选择的区域设备id  *****************************
			// this.get_init_settings_data()
			
			//窗口调整事件监听
			// window.addEventListener("resize",this.window_resize);
			// console.log(document.getElementsByClassName("el-tabs__header")[0].style.width="1500px")

			this.get_all_device_info()

			// this.check_user_permission()
			// document.getElementsByClassName("device_data2_value_select_item")[1].style.color="#8aff6d"
			
			// this.update_dataList()
			// this.update_dataList_timer = setInterval(this.update_dataList,450)//定时获取
			// this.show_position_map()
		},
		beforeDestroy() {
			clearInterval(this.update_dataList_timer);
			  // clearInterval(this.robot_timer);
			  // clearInterval(this.equipment_timer);
			  // clearInterval(this.video_show_timer);
			  
			  // this.init_sign=="1"
		},
		// 切换界面时调用
		beforeUnmount(){
			clearInterval(this.update_dataList_timer);
			// clearInterval(this.robot_timer);
			// clearInterval(this.equipment_timer);
			// clearInterval(this.video_show_timer);
			// sessionStorage.setItem("video_sigon", this.video_read_count);
			// // this.init_sign=="1"
			// this.socket.close();
		}
		
	}
</script>

<style>
	.device_data_mainLayout{
		/* margin-top: -20px; */
		height: calc(100% - 1px);
		width: 100%;
		/* height: calc(100vh - 135px); */
		/* min-height: calc(100vh - 150px); */
		/* min-height: 100%; */
		padding-right: 30px;
		/* background-color: #2b6dab; */
		/* min-height: 100%; */	
		overflow: hidden;
	}
	.main_el_row_layout{
		z-index: 99;
	}
	.device_data_position_layout{
		margin-top: 20px;
		margin-left: 50px;
		height: 60px;
		/* height: 8%; */
		min-height: 60px;
		width: calc(100% - 100px);
		background-color: rgba(19, 77, 88, 0.4);
		border-radius: 10px;
		border: 1px solid #77f7f8;
		box-shadow: 0px 0px 3px 2px #6dfaff;
	}
	.device_data_position_row{
		height: 100%;
	}
	.device_data_position_col{
		height: 100%;
		/* background-color: #6dfaff; */
		color: #6dfaff;
		line-height: 60px;
		font-size: 20px;
		
	}
	.now_region_select{
		margin-top: -5px;
		margin-left: -100px;
		width: 300px;
	}
	.phone_device_data_layout{
		margin-top:10px;
		height: calc(100% - 125px);
		width: calc(100%);
		/* background-color: #195b69; */
		border-radius: 15px;
		/* margin-left: 20px; */
		
	}
	.el-tab-pane{
		/* background-color: rgba(109, 250, 255, 0.2); */
		height: calc(90vh - 225px);
		width: calc(100% - 10px) ;
		color: #6dfaff;
		font-size: 18px;
		font-weight: 500;
		margin: 5px;
		overflow: auto;
		/* margin-left: ; */
		margin-top: 3px;
		/* border-radius: 20px; */
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px;
		/* border: 1px solid rgba(109, 250, 255, 0.8); */
		border-bottom: 2px solid rgba(109, 250, 255, 0.8); 
		/* border-top: 2px solid rgba(109, 250, 255, 0.8); */
		/* box-shadow: 0px 0px 3px 2px rgba(109, 250, 255, 0.8); */
	}
	.device_data1_show1{
		width: calc(100% - 10px);
		margin-left: 10px;
		height: 30%;
		background-color: rgba(109, 250, 255, 0.2);
		border: 1px solid rgba(109, 250, 255, 0.8);
		box-shadow: 0px 0px 3px 2px rgba(109, 250, 255, 0.8);
		border-radius: 10px;
		margin-bottom: 20px;
		overflow: auto;
	}
	.device_data1_show2{
		width: calc(100% - 10px);
		height: 30%;
		background-color: rgba(109, 250, 255, 0.2);
		border: 1px solid rgba(109, 250, 255, 0.8);
		box-shadow: 0px 0px 3px 2px rgba(109, 250, 255, 0.8);
		border-radius: 10px;
		margin-bottom: 20px;
		overflow: auto;
	}
	.device_data1_list{
		position: relative;
		overflow: hidden;
		margin-left: 20px;
		width: calc(100% - 40px);
		height: calc(100% - 20px);
		border-radius: 10px;
		/* border-top-left-radius: 10px; */
		/* border-top-right-radius: 10px; */
		border: 1px solid rgba(184, 184, 184, 0.8); 
		box-shadow: 0px 0px 3px 2px rgba(202, 202, 202, 0.8); 
		background-color: rgba(153, 153, 153, 0.5); 
	}
	.device_position_map{
		/* z-index: 77;
		position: absolute;
		width: calc(100% - 20px);
		margin-left: 10px;
		height: 82%;
		background-color: #999999;
		border-radius: 10px;
		transition: margin 0.8s 0s;
		margin-top: calc(100% + 200px); */
	}
	.device_data1_show3{
		margin-top: 10px;
		width: calc(100% - 20px);
		margin-left: 10px;
		height: 60px;
		line-height: 60px;
		background-color: rgba(185, 185, 185, 0.5); 
		overflow: auto;
	}
	.device_data1_show4{
		margin-top: 10px;
		width: calc(100% - 20px);
		margin-left: 10px;
		height: 60px;
		line-height: 60px;
		background-color: rgba(85, 170, 255, 0.5); 
		overflow: auto;
	}
	.device_data1_show4_title{
		width: 70%;
		height: 60px;
		float: left;
		/* background-color: #55ffff; */
	}
	.device_data1_show4_value{
		width: 30%;
		height: 60px;
		float: left;
		/* background-color: #55ffff; */
	}
	.device_data1_show4_value_close{
		width: 30%;
		width: 50px;
		height: 50px;
		margin-top: 5px;
		float: left;
		/* background:url(../assets/device_close.png) no-repeat; */
		background:url(../assets/device_close_1.png) no-repeat;
		background-size: 50px 50px ;
		
		/* box-shadow: 0px 0px 5px 3px rgba(81, 81, 81, 0.5) ,inset 0px 0px 15px 15px rgba(81, 81, 81, 0.6); */
		/* border-radius: 50px; */
	}
	.device_data1_show4_value_close::after{
		position: absolute;
		margin-left: -23px;
		margin-top: 2px;
		content: "";
		width: 46px;
		height: 46px;
		border-radius: 50px;
		/* background-color: rgba(255, 85, 0, 0.5); */
		box-shadow: 0px 0px 5px 3px #515151 ,inset 0px 0px 15px 5px rgba(81, 81, 81, 0.7);
	}
	.device_data1_show4_value_open{
		width: 30%;
		width: 50px;
		height: 50px;
		margin-top: 5px;
		float: left;
		background:url(../assets/device_open.png) no-repeat;
		background:url(../assets/device_open_1.png) no-repeat;
		background-size: 50px 50px ;
		/* box-shadow: 0px 0px 5px 3px rgba(85, 255, 255, 0.5) ,inset 0px 0px 15px 15px rgba(85, 255, 255, 0.6); */
		/* border-radius: 50px; */
	}
	.device_data1_show4_value_open::after{
		position: absolute;
		margin-left: -24px;
		margin-top: 1px;
		content: "";
		width: 48px;
		height: 48px;
		border-radius: 50px;
		/* background-color: rgba(255, 85, 0, 0.5); */
		box-shadow: 0px 0px 5px 3px #55ffff ,inset 0px 0px 15px 2px rgba(85, 255, 255, 0.5);
	}
	
	
	.device_count_data_layout{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 280px;
		
		/* border: 1px solid #000000; */
		/* background-color: #999999; */
	}
	.device_count_data_show1{
		width: calc(100% - 20px);
		margin-left: 10px;
		height: 60px;
		line-height: 60px;
		text-align: left;
		padding-left: 100px;
		background-color: rgba(85, 170, 255, 0.5); 
		overflow: auto;
	}
	.device_count_data_show2{
		width: calc(100% - 20px);
		margin-left: 10px;
		height: 60px;
		line-height: 60px;
		background-color: rgba(185, 185, 185, 0.5); 
		/* background-color: rgba(85, 170, 255, 0.5); */
		text-align: left;
		padding-left: 100px;
		overflow: auto;
	}
	.device_count_data_show3{
		width: calc(100% - 20px);
		margin-left: 10px;
		height: 55px;
		line-height: 55px;
		background-color: rgba(50, 50, 48, 0.5);
		text-align: left;
		padding-left: 100px;

	}
	.phone_device_data2_select{
		width: calc(100% - 10px);
		margin-left: 10px;
		/* margin-left: 5%; */
		height: 40px;
		background-color: rgba(185, 185, 185, 0.5); 
		border: 1px solid rgba(184, 184, 184, 0.8);
		box-shadow: 0px 0px 3px 2px rgba(202, 202, 202, 0.8); 
		border-radius: 20px;
	}
	.phone_device_data2_show_layout_3{
		margin-top: 10px;
		height: calc(100% - 20px);
		width: calc(100% - 0);
		/* overflow-x: hidden; */
		/* overflow-y: scroll; */
		overflow: auto;
		text-align: left;
		margin-left: 5%;

	}
	.phone_device_data2_show1{
		position: relative;
		margin-left: 5px;
		width: 125px;
		/* width: auto; */
		height: 45px;
		/* font-size: 17px; */
		/* line-height: 17px; */
		background-color: rgba(179, 179, 179, 0.8); 
		border-radius: 20px;
		margin-bottom: 5px;
		display: inline-block;
	}
	.phone_device_data2_show1_2{
		
		margin-left: 5px;
		width: 125px;
		/* width: auto; */
		height: 45px;
		/* background-color: rgba(179, 179, 179, 0.8); */
		border-radius: 20px;
		margin-bottom: 5px;
		background-color: rgba(192, 213, 212, 0.8);  
		
		/* display: inline-block; */
		
		/* display: flex; */
		float: left;
		
		position: relative;
	}
	.phone_device_data2_show1_icon{
		margin-left: 20px;
		width: 60px;
		height: 60px;
		/* border: 1px solid #6dfaff; */
		/* background-color: #6dfaff; */
		/* display: inline-block; */
		line-height: 60px;
		float: left;
		background-image:url(../assets/device_state.png);
		background-size: 60px 60px ;
	}
	.phone_device_data2_show1_icon_1{
		margin-left: 20px;
		width: 60px;
		height: 60px;
		/* border: 1px solid #6dfaff; */
		/* background-color: #6dfaff; */
		/* display: inline-block; */
		line-height: 60px;
		float: left;
		background-image:url(../assets/rejidongzuo.png);
		background-size: 60px 60px ;
	}
	.phone_device_data2_show1_value{
		float: left;
		width: 100px;
		height: 50px;
		/* background-color: #ffffff; */
		/* display: inline-block; */
		line-height: 60px;
	}
	.phone_device_data2_show1_value_close{
		margin-left: 10px;
		float: left;
		width: 37px;
		height: 37px;
		/* background-color: #ffffff; */
		/* display: inline-block; */
		line-height: 60px;
		background-image:url(../assets/device_close.png);
		background-image:url(../assets/device_close_1.png);
		background-size: 37px 37px ;
		margin-right: 6px;
		margin-top: 1px;
	}
	.phone_device_data2_show1_value_open{
		margin-left: 10px;
		float: left;
		width: 35px;
		height: 35px;
		/* background-color: #ffffff; */
		/* display: inline-block; */
		line-height: 60px;
		background-image:url(../assets/device_open.png);
		background-image:url(../assets/device_open_1.png);
		background-size: 35px 35px ;
		margin-right: 6px;
		margin-top: 2px;
	}
	.phone_device_data2_show1_value_close_2{
		float: left;
		width: 25px;
		height: 45px;
		color: #515151;
		font-size: 20px;
		line-height: 45px;
		margin-left: 5px;
		font-weight: 600;
	}
	.phone_device_data2_show1_value_open_2{
		float: left;
		width: 25px;
		height: 45px;
		color: #55ff00;
		font-size: 20px;
		line-height: 45px;
		margin-left: 5px;
		font-weight: 600;	
	}
	.phone_device_data2_show1_title{
		position: relative;
		text-align: left;
		font-size: 15px;
		/* margin-left: 15px; */
		line-height: 45px;
		color: #000000;
		height: 45px;
		width: 85px;
		/* margin-right: 10px; */
		/* background-color: #ffffff; */
		overflow-y: auto;
/* 		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center; */
	}
	.el-tabs__header{
		width: 100%;
		/* max-width: 1400px; */
		/* width: 1500px; */
	}
	.device_data3_show_layout{
		/* overflow: scroll; */
		/* overflow-x: hidden; */
		position: relative;
		width: 80%;
		height: 95%;
		margin-left: 10%;
		border: 1px solid rgba(85, 255, 255, 0.8);
		box-shadow: 0px 0px 3px 2px rgba(85, 255, 255, 0.8); 
		border: 1px solid rgba(184, 184, 184, 0.8);
		box-shadow: 0px 0px 3px 2px rgba(202, 202, 202, 0.8); 
		border-radius: 10px;
		background-color: rgba(153, 153, 153, 0.5); 
	}
	.device_data3_show1_layout{
		margin-top: 10px;
		overflow: scroll;
		overflow-x: hidden;
		width: 100%;
		height: calc(100% - 70px - 50px );
	}
	.device_data3_update_time{
		/* position: absolute; */
		/* bottom: 0; */
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: right;
		padding-right: 5%;
	}
	.device_data3_col_title{
		margin-top: 10px;
		height: 50px;
		width: calc(100% - 10px);
		margin-left: 10px;
		background-color: rgba(85, 170, 255, 0.6);
		line-height: 50px;
	}
	.device_data3_col{
		overflow: auto;
		margin-top: 5px;
		height: 50px;
		width: calc(100% - 10px);
		margin-left: 10px;
		background-color: rgba(255, 255, 255, 0.3);
		line-height: 50px;
	}
	.device_data3_col_content {
		text-align: left;
		padding-left: 20px;
	}
	.phone_device_data2_select_col{
		/* background-color: #55aaff; */
		/* width: 100%; */
		
		height: 40px;
		color: #6dfaff;
		font-size: 16px;
		/* font-weight: 600; */
		/* text-align: right; */
		line-height:40px;
	}
	.device_data2_select_button{
		height: 70px;
		color: #6dfaff;
		font-size: 18px;
		font-weight: 600;
		/* text-align: left; */
		line-height: 65px;
		/* padding-top: 21px; */
	}
	.device_data2_select_button .el-button{
		background-color: rgba(108, 204, 255, 0.1);
		box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
		color: #6cccff;
		font-size: 15px;
		font-weight: 600;
		border: none;
	}
	.device_data2_value_select :nth-child(1) {
		/* background-color: #6cccff !important; */
		color: #8aff6d !important;
	}

	.device_data1_show1_progress{
		margin-top: 10px;
		margin-bottom: -12px;
	}
	.data1_percentage-value{
		margin-top: 40px;
		height: 40px;
		width: 150px;
		font-size: 40px;
		/* background-color: #00a1ff; */
		/* overflow: hidden; */
		/* margin-left: 10px; */
		/* text-align: left; */
		/* background-color: #002832; */
		display: inline-block;
		color: #55ffff;
		font-weight: 600;
	}
	.data1_percentage-label{
		margin-top: 30px;
		font-size: 20px;
		/* display: inline-block; */
		color: #55ffff;
		font-weight: 600;
		/* background-color: #aaaaff; */
		/* text-align: left; */
		height: 16px;
		/* width: 90px; */
		/* min-width: 90px; */
	}
	.el-progress-circle :first-child {
		stroke:rgba(182, 182, 182, 0.9);
		stroke-linecap:round;
	}
	.show_map_btn{
		margin-top: 2px;
		margin-left: 10px;
		background: url(../assets/show_map_btn.png) no-repeat;
		background-size: 50px 50px ;
		width: 60px;
		height: 60px;
	}
	.select_options_item{
		/* text-align: center; */
		/* background-color: rgba(85, 170, 255, 0.8) ; */
		/* color: #55ffff !important; */
	}
	.device_data2_layout{
		height: calc(90vh - 180px);
		width: calc(100% - 10px) ;
	}
	.phone_device_data2_show4_0{
		float: left;
		width: 20px;
		height: 20px;
		color: #a6a6a6;
		font-size: 22px;
		line-height: 40px;
		margin-left: 3px;
		font-weight: 600;	
		background-color: rgba(95, 95, 95, 0.8);
		box-shadow: 0px 0px 5px 3px rgba(95, 95, 95, 0.8) ,inset 0px 0px 15px 5px rgba(95, 95, 95, 0.8);
		border-radius: 30px;
		margin-top: 12px;
	}
	.phone_device_data2_show4_1_type1{
		float: left;
		width: 20px;
		height: 20px;
		color: #55ff00;
		font-size: 22px;
		line-height: 40px;
		margin-left: 3px;
		font-weight: 600;	
		background-color: rgba(85, 255, 127, 0.8);
		box-shadow: 0px 0px 5px 3px rgba(85, 255, 127, 0.8) ,inset 0px 0px 15px 5px rgba(85, 255, 127, 0.8);
		border-radius: 30px;
		margin-top: 12px;
	}
	.phone_device_data2_show4_1_type2{
		float: left;
		width: 20px;
		height: 20px;
		color: #ff5500;
		font-size: 22px;
		line-height: 40px;
		margin-left: 3px;
		font-weight: 600;	
		background-color: rgba(255, 85, 0, 0.8);
		box-shadow: 0px 0px 5px 3px rgba(255, 85, 0, 0.8) ,inset 0px 0px 15px 5px rgba(255, 85, 0, 0.8);
		border-radius: 30px;
		margin-top: 12px;
	}
</style>