import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/global.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import videojs from "video.js"
import "video.js/dist/video-js.css";
import * as echarts from 'echarts'
import { ElMessage } from 'element-plus'

import global from './components/global.vue'
// import request from "@/utils/request";

const app =createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus,{
    locale: zhCn
})
app.config.productionTip = false
app.config.globalProperties.$video = videojs
app.config.warnHandler = () => null;
app.config.globalProperties.$echarts = echarts
app.mount('#app')
// 全局变量
app.config.globalProperties.$GLOBAL = global
// app.config.globalProperties.$REQUEST = request
// 
router.beforeEach((to,from,next) => {
	let is_Phone = false
	let screenWidth = document.documentElement.clientWidth
	if(screenWidth<1400){
		is_Phone= true
	}
	console.log(screenWidth)
	sessionStorage.setItem("is_Phone",is_Phone)
	if(is_Phone){
		// ElMessage.success("is_Phone")
		// 如果即将进入的路由对象是登录页，则进行跳转，否则验证是否携带Token,如果有，则进
		// 行跳转，没有，则不允许跳转
		if(to.path === "/phone_login"){
		    next()
			// next("/phone_login")
		}
		// else if(to.path === "/phone_login"){
		// 	next()
		// }
		else if(to.path === "/submit_data"){
			window.document.body.style.height="100vh"
			next()
		}else{
		    let username = sessionStorage.getItem('user')
			let power = sessionStorage.getItem('power')
			// 部分账号只有主页面查看权限
		    if (username){
				console.log(to.path)
				switch(to.path){
					case "/MGT/phone_Device_Data":
						next()
							break
				}
				next()
				} else {
					if(to.path !== "/login"){
						ElMessage.error('请先登录！')
					}					
					next("/phone_login")
				}
		}
	}else{
		// 如果即将进入的路由对象是登录页，则进行跳转，否则验证是否携带Token,如果有，则进
		// 行跳转，没有，则不允许跳转
		if(to.path === "/login"){
		    next()
		}else if(to.path === "/submit_data"){
			window.document.body.style.height="100vh"
			next()
		}else{
		    let username = sessionStorage.getItem('user')
			let power = sessionStorage.getItem('power')
			// 部分账号只有主页面查看权限
		    if (username){
				next()
				// if(power=="2"){
				// 	if(to.path=="/MGT/main"){
				// 		next()
				// 	}else{
				// 		next("/MGT/main")
				// 		ElMessage.error('该账户无此权限！已为您返回主页面')
				// 	}
				// }else if(power=="1"){
				// 	next()
				// }else{
				// 	ElMessage.error('账号认证失败，请与管理员联系')
				// 	next("/login")
				// }
				} else {
					ElMessage.error('请先登录！')
					next("/login")
				}
			// let username = sessionStorage.getItem('user')
			// // root账号只有主页面查看权限
		    // if (username){
			// 	if(username=="test"){
			// 		if(to.path=="/MGT/main"){
			// 			next()
			// 		}else{
			// 			next("/MGT/main")
			// 			ElMessage.error('该账户无此权限！已为您返回主页面')
			// 		}
			// 	}else if(username=="root"){
			// 		next()
			// 	}else{
			// 		next("/login")
			// 	}
		    // } else {
			// 	ElMessage.error('请先登录！')
			// 	next("/login")
			// }
	}
	console.log("from: "+from.path+" ----> to: "+to.path)
	
    }
})




