<template>
	<!-- <div > -->
		<!-- test ....... -->
		<el-container>
		    <el-header 
				class="phone_el-header_style"
				>
				<Header></Header>
				
				  <!-- 智能巡检机器人平台 -->
			</el-header>
			<el-container class="phone_container" >
		        <el-aside >
					<Aside></Aside>
					<!-- —&nbsp;功能列表&nbsp;— -->
				</el-aside>
		        <el-main id="main_layout" class="phone_main_layout_style">
					<!-- 主界面 -->
					<router-view/>
				</el-main>
		    </el-container>
			<!-- <el-footer class="el-footer_style">
				<div class="boxBorder1">
					<div class="boxBorder2">
						<div class="footer_text">
							<a target="_blank" href="https://beian.miit.gov.cn" >
								鄂ICP备2023006219号-1
							</a>
						</div>
					</div>
				</div>
			</el-footer> -->
			
		</el-container>
	<!-- </div> -->
</template>

<script>
	// document.getElementById('aside').style.height=window.innerHeight*0.9355+'px'
	import Header from '../components/phone_Header.vue'
	import Aside from '../components/phone_Aside.vue'
	export default{
		name:'mainLayout',
		components:{
			Header,
			Aside
		},
		data() {
			return{
				// isCollapse :Aside.data().isCollapse
			}
		}
		
	}
</script>

<style>
	.phone_el-header_style{
		background-color: rgba(19, 77, 88, 0.5);
		box-shadow:inset 0px 0px 4px 3px rgba(109, 250, 255, 1.0),0px 0px 4px 3px rgba(109, 250, 255, 1.0)  ;
		height: 65px;
		padding: 5px;
		border-radius: 10px;
	}
	.phone_main_layout_style{
		/* background-color: rgba(85, 170, 255, 0.5); */
		padding-right: 0px !important;
	    transition: margin-left 0.3s 0s;
		margin-left: -280px !important;
		/* margin-top: 50px !important; */
		
	}
	.phone_container{
		height: calc(100vh - 60px );
	}
	.phone_container .el-aside{
		width: 290px !important;
		overflow: hidden;
		/* background-color: aliceblue; */
	}
</style>