<template>
	<div class="phone_setting_mainLayout">
		<div class="boxBorder1 ">
		    <div class="boxBorder2">
				<el-row >
					<el-col :span="1">
					</el-col>
				    <el-col :span="6"><div class="phone_main_title_style_b" /></el-col>		
				    <el-col :span="10">
						<div class="phone_main_title_style_a" >
							系统设置
						</div>
					</el-col>
				    <el-col :span="6"><div class="phone_main_title_style_c" /></el-col>
					<el-col :span="1"></el-col>
				</el-row>
				<el-main class="phone_setting_main">
					<el-collapse v-model="main_activeNames" >
						<el-collapse-item title="系统权限设置" name="1" v-show="set_system">
							<div class="phone_setting_item_power">
								<div class="power_setting_title">
									一级权限：
								</div>
								<div class="phone_power_setting">

									<div>
										<div class="phone_power_item_layout">
											设备实时数据 
											<el-switch v-model="setting_power_data[0]['device_data_1']" class="power_item_switch"/>
										</div>
										<div class="phone_power_item_layout">
											设备状态数据 
											<el-switch v-model="setting_power_data[0]['device_data_2']" class="power_item_switch"/>
										</div>
										<div class="phone_power_item_layout">
											设备维护数据
											<el-switch v-model="setting_power_data[0]['device_data_3']" class="power_item_switch"/>
										</div>
									</div>

									<div class="phone_power_item_layout">
										查询账号信息
										<el-switch v-model="setting_power_data[0]['see_user_data']" class="power_item_switch"/>
									</div>
									<div class="phone_power_item_layout">
										修改账号信息
										<el-switch v-model="setting_power_data[0]['edit_user_data']" class="power_item_switch"/>
									</div>
									<div>
										<div class="phone_power_item_layout">
											系统设置
											<el-switch disabled v-model="setting_power_data[0]['system_setting']" class="power_item_switch"/>
										</div>
									</div>
									
									<div class="phone_power_item_save" @click="seva_permission_btn(1)">
										保存一级权限设置
									</div>
									
								</div>
								
								<div class="power_setting_title">
									二级权限：
								</div>
								<div class="phone_power_setting">

									<div>
										<div class="phone_power_item_layout">
											设备实时数据 
											<el-switch v-model="setting_power_data[1]['device_data_1']" class="power_item_switch"/>
										</div>
										<div class="phone_power_item_layout">
											设备状态数据 
											<el-switch v-model="setting_power_data[1]['device_data_2']" class="power_item_switch"/>
										</div>
										<div class="phone_power_item_layout">
											设备维护数据
											<el-switch v-model="setting_power_data[1]['device_data_3']" class="power_item_switch"/>
										</div>
									</div>
									
									<div class="phone_power_item_layout">
										查询账号信息
										<el-switch v-model="setting_power_data[1]['see_user_data']" class="power_item_switch"/>
									</div>
									<div class="phone_power_item_layout">
										修改账号信息
										<el-switch v-model="setting_power_data[1]['edit_user_data']" class="power_item_switch"/>
									</div>
									<div>
										<div class="phone_power_item_layout">
											系统设置
											<el-switch v-model="setting_power_data[1]['system_setting']" class="power_item_switch"/>
										</div>
									</div>
									
									<div class="phone_power_item_save" @click="seva_permission_btn(2)">
										保存二级权限设置
									</div>
								</div>
								
								<div class="power_setting_title">
									三级权限：
								</div>
								<div class="phone_power_setting" style="margin-bottom: 20px;">

									<div>
										<div class="phone_power_item_layout">
											设备实时数据 
											<el-switch v-model="setting_power_data[2]['device_data_1']" class="power_item_switch"/>
										</div>
										<div class="phone_power_item_layout">
											设备状态数据 
											<el-switch v-model="setting_power_data[2]['device_data_2']" class="power_item_switch"/>
										</div>
										<div class="phone_power_item_layout">
											设备维护数据
											<el-switch v-model="setting_power_data[2]['device_data_3']" class="power_item_switch"/>
										</div>
									</div>
									
									<div class="phone_power_item_layout">
										查询账号信息
										<el-switch v-model="setting_power_data[2]['see_user_data']" class="power_item_switch"/>
									</div>
									<div class="phone_power_item_layout">
										修改账号信息
										<el-switch v-model="setting_power_data[2]['edit_user_data']" class="power_item_switch"/>
									</div>
									<div>
										<div class="phone_power_item_layout">
											系统设置
											<el-switch v-model="setting_power_data[2]['system_setting']" class="power_item_switch"/>
										</div>
									</div>
									
									<div class="phone_power_item_save" @click="seva_permission_btn(3)">
										保存三级权限设置
									</div>
								</div>
								
							</div>
							
					    </el-collapse-item>
						<el-collapse-item title="设备数据显示" name="2">
							<div class="phone_setting_item_init">
								<div class="power_setting_title">
									设备数据：
								</div>
								<div class="phone_power_setting">
									<div class="phone_init_device_id_layout">
										<div class="phone_init_device_id_title">
											默认设备id：
										</div>
										<el-select v-model="init_region_id"
												class="phone_select_init_device_id_layout" 
												placeholder="Select" 
												@change="select_position_change"
												filterable 
												size="default">
										    <el-option
										      v-for="item in select_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
											  class="select_options_item"
										    />
										</el-select>
									</div>
									<div >
										<div class="phone_init_item_layout" v-show="false">
											地图-数据标签显示 
											<el-switch v-model="init_show_all_label" class="power_item_switch"/>
										</div>
										<div class="phone_init_item_layout">
											地图-数据概览显示 
											<el-switch v-model="init_show_all_list" class="power_item_switch"/>
										</div>
										<div class="phone_init_item_layout">
											<!-- 地图-数据状态显示 -->
											地图-设备数量显示 
											<el-switch v-model="init_show_all_status" class="power_item_switch"/>
										</div>
									</div>
									<div class="phone_power_item_save_2" @click="seva_init_device_btn">
										保存设备数据设置
									</div> 
								
								</div>
								
							<!-- 	<div class="init_item_save" @click="seva_permission_btn(3)">
									保存设备数据设置
								</div> -->
							</div>
						    
						</el-collapse-item>
						<el-collapse-item title="设备地点维护" name="3" v-show="set_system">
							<div class="phone_add_device_data_info_btn" @click="add_device_data_info">
								添加设备
							</div>
							<div class="phone_setting_item_init main_device_data_info_layout">
								<div class="device_data_info_layout">
									<el-table
										:row-style="rowState"
										:cell-style="cellState"
										:header-row-style="headerRowState"
										:header-cell-style="headerCellState"
										:data="device_data_info" 
										:border="parentBorder" 
										style="width: 100%"
										height="100%"
										>
									    <el-table-column label="设备id" prop="device_id" />
									    <el-table-column label="区域" prop="region" />
										<el-table-column label="地址" prop="address" />
										<el-table-column label="经度" prop="lon" />
										<el-table-column label="纬度" prop="lat" />
										<el-table-column label="操作" width="120">
										    <template #default="scope">
												<div class="device_info_data_edit_btn" @click="edit_device_data_info(scope)">
													修改
												</div>
												<el-popconfirm
													title="确定删除吗？" 
													@confirm="delete_device_data_info(scope)"
												>
													<template #reference>
														<div class="device_info_data_delete_btn" >
															删除
														</div>
													</template>
												</el-popconfirm>
										    </template>
										</el-table-column>
										
									</el-table>
								</div>
								
								<div class="phone_add_device_data_info_layout" id="add_device_data_info_layout">
									<div class="add_device_info_close" @click="add_device_data_close">
										X
									</div>
									<div class="phone_add_device_info_title">
										{{add_device_info_title}}
									</div>
									<div class="phone_add_device_info_item">
										<el-row>
											<el-col :span="8">设备区域：</el-col>
											
											<el-col :span="15">
												<el-input v-model="device_info_data_obj['region']"
													placeholder="  " 
													clearable
													@change=""
													size="small"
													/>
											</el-col>
											<el-col :span="1"></el-col>
										</el-row>
									</div>
									<div class="phone_add_device_info_item">
										<el-row>
											<el-col :span="8">设备地址：</el-col>
											
											<el-col :span="15">
												<el-input v-model="device_info_data_obj['address']"
													placeholder="  " 
													clearable
													@change=""
													size="small"
													/>
											</el-col>
											<el-col :span="1"></el-col>
										</el-row>
									</div>
									<div class="phone_add_device_info_item">
										<el-row>
											<el-col :span="8">经度：</el-col>
											
											<el-col :span="15">
												<el-input v-model="device_info_data_obj['lon']"
													placeholder="  " 
													clearable
													@change=""
													size="small"
													/>
											</el-col>
											<el-col :span="1"></el-col>
										</el-row>
									</div>
									<div class="phone_add_device_info_item">
										<el-row>
											<el-col :span="8">纬度：</el-col>
											
											<el-col :span="15">
												<el-input v-model="device_info_data_obj['lat']"
													placeholder="  " 
													clearable
													@change=""
													size="small"
													/>
											</el-col>
											<el-col :span="1"></el-col>
										</el-row>
									</div>
									<div class="phone_add_device_info_btn">
										<el-row>
											<el-col :span="4"></el-col>
											<el-col :span="7" class="phone_add_device_info_btn_reset" @click="add_device_info_btn_reset">重置</el-col>
											<el-col :span="2"></el-col>
											<el-col :span="7" class="phone_add_device_info_btn_save"  @click="add_device_info_btn_save" v-show="add_device_info_btn_save_isShow">提交</el-col>
											<el-col :span="7" class="phone_add_device_info_btn_save"  @click="edit_device_info_btn_save" v-show="!add_device_info_btn_save_isShow">保存</el-col>
											
										</el-row>
									</div>
									
								</div>
						    </div>
							
						</el-collapse-item>
						<el-collapse-item title="设备状态配置" name="4" v-show="set_system">
						    <div class="phone_setting_item_init ">
								<div class="phone_device_type_is_show_header">
									<el-row>
										<el-col :span="2"></el-col>
										<el-col :span="20" class="history_device_position_col">
											<el-select v-model="choose_device_type" 
													style="margin-top: -20px;"
													placeholder="Select" 
													@change="select_all_device_type"
													filterable 
													size="default">
											    <el-option
											      v-for="item in select_options"
											      :key="item.value"
											      :label="item.label"
											      :value="item.value"
												  class="history_select_options_item"
											    />
											</el-select>
										</el-col>
										<el-col :span="2"></el-col>
										<el-col :span="2"></el-col>
										<el-col :span="20" >
											<div class="phone_device_type_save_btn">
												<el-button @click="device_type_save" size="default"
													style="width: 100% !important;
													margin-top: -50px;">
													保存
												</el-button>
											</div>
										</el-col>
										<el-col :span="2"></el-col>
									</el-row>
								</div>
								<div>
									<el-table
										:row-style="rowState"
										:cell-style="cellState"
										:header-row-style="headerRowState"
										:header-cell-style="headerCellState"
										:data="choose_device_type_data" 
										:border="parentBorder" 
										style="width: 100%"
										height="100%"
										>
									    <el-table-column label="设备id" prop="device_id" width="150px"/>
									    <el-table-column label="类型id" prop="type_id" width="150px"/>
										<el-table-column label="地址" prop="data_position" width="150px"/>
										<el-table-column label="内容" prop="content" />
										<!-- <el-table-column label="纬度" prop="notes" /> -->
										<el-table-column label="是否显示" width="200">
										    <template #default="scope">
												<!-- {{scope['row']['notes']}} -->
												<!-- <el-switch v-model="scope['row']['notes']" class="power_item_switch"/> -->
												<el-switch
												    v-model="scope['row']['notes']"
												    inline-prompt
												    active-text="是"
												    inactive-text="否"
													active-value="1"
													inactive-value="0"
												  />
										    </template>
										</el-table-column>
										
									</el-table>
								</div>
						    </div>
						</el-collapse-item>
						<el-collapse-item title="实时数据配置" name="5" v-show="set_system">
						    <div class="setting_sssj">
								<div class="phone_device_type_is_show_header">
									<el-row>
										<el-col :span="2"></el-col>
										<el-col :span="20" class="history_device_position_col">
											<el-select v-model="choose_device_type" 
													style="margin-top: -20px;"
													placeholder="Select" 
													@change="select_all_device_type"
													filterable 
													size="default">
											    <el-option
											      v-for="item in select_options"
											      :key="item.value"
											      :label="item.label"
											      :value="item.value"
												  class="history_select_options_item"
											    />
											</el-select>
										</el-col>
										<el-col :span="2"></el-col>
										<el-col :span="2"></el-col>
										<el-col :span="20" >
											<div class="phone_device_type_save_btn">
												<el-button @click="device_type_save_show_1" size="default"
													style="width: 100% !important;
													margin-top: -50px;">
													保存
												</el-button>
											</div>
										</el-col>
										<el-col :span="2"></el-col>
									</el-row>
								</div>
								<!-- <div style="color: aliceblue;">{{choose_device_type_data}}</div> -->
								<el-row>
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										显示数据：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_display_type_id" 
											class="phone_permission_device_select" 
											placeholder="选择要显示的数据"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											@change="display_devices_change"
											fit-input-width=true
											placement ="top-start"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>	
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										故障回顾：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_error_check_type_id" 
											class="phone_permission_device_select" 
											placeholder="选择故障条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>	
									</el-col>
									<el-col :span="2" ></el-col>
								</el-row>
								<el-row style="margin-top: 20px;">
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										海侧电机运行时间：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_hcdjys_time_type_id" 
											class="phone_permission_device_select" 
											placeholder="请设置计时条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										海侧运行时间：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_hcys_time_type_id" 
											class="phone_permission_device_select" 
											placeholder="请设置计时条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										海侧锚定运行时间：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_hcmdys_time_type_id" 
											class="phone_permission_device_select" 
											placeholder="请设置计时条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>
									</el-col>
								</el-row>
								<el-col :span="2" ></el-col>
								<el-row style="margin-top: 50px;">
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										陆侧电机运行时间：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_lcdjys_time_type_id" 
											class="phone_permission_device_select" 
											placeholder="请设置计时条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										陆侧运行时间：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_lcys_time_type_id" 
											class="phone_permission_device_select" 
											placeholder="请设置计时条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										陆侧锚定运行时间：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_lcmdys_time_type_id" 
											class="phone_permission_device_select" 
											placeholder="请设置计时条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>
									</el-col>
									<el-col :span="2" ></el-col>
								</el-row>
								
								<el-row style="margin-top: 20px;">
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										故障统计：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_gztj_type_id" 
											class="phone_permission_device_select" 
											placeholder="请设置计时条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										海侧故障：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_hcgz_type_id" 
											class="phone_permission_device_select" 
											placeholder="请设置计时条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20" style="color: #55ffff;font-size: 20px;text-align: left;line-height: 30px;">
										陆侧故障：
									</el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="2" ></el-col>
									<el-col :span="20">
										<el-select
											v-model="choose_lcgz_type_id" 
											class="phone_permission_device_select" 
											placeholder="请设置计时条件"
											multiple 
											filterable
											:disabled="unedit_admin_value"
											fit-input-width=true
											placement ="top-start"
											@change="display_devices_change"
											>
											<!-- :disabled="see_admin.display_device=='全部设备'" -->
											
										    <el-option
										      v-for="item in select_type_id_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
										    />
										</el-select>
									</el-col>
									<el-col :span="2" ></el-col>
								</el-row>

						    </div>
						</el-collapse-item>
						<!-- <el-collapse-item title="其它设置" name="6">
						    <div class="setting_item_qita">
						    	
						    </div>
						</el-collapse-item>
						<el-collapse-item title="其它设置" name="7">
						    <div class="setting_item_qita">
						    	
						    </div>
						</el-collapse-item> -->
					</el-collapse>

				<!-- 	<div class="setting_item_lixing">
						<div class="setting_item_lixing_header">
							巡检模式设置
						</div>
					</div> -->
					
				</el-main>
				
			</div>
		</div>
	</div>
</template>

<script>
	import request from "@/utils/request";
	import { ElMessage } from 'element-plus'
	export default{
		methods:{
			add_device_info_btn_reset(){
				this.device_info_data_obj={
					"device_id":"",
					"region":"",
					"address":"",
					"lon":"",
					"lat":"",
				}
				this.$GLOBAL.device_info_data_obj['lon']=""
				this.$GLOBAL.device_info_data_obj['lat']=""
			},
			edit_device_info_btn_save(){
				if(this.device_info_data_obj['region']==""||this.device_info_data_obj['address']==""||this.device_info_data_obj['lon']==""||this.device_info_data_obj['lat']==""){
					ElMessage.error('数据不能为空 ！！！')
				}else{
					request.post(this.$GLOBAL.flask_web_local_url+'/edit_device_info',this.device_info_data_obj).then(res =>{
						// console.log(res)					
						if(res=="200"){
							ElMessage.success('添加成功')
							this.get_device_data_info()
							this.add_device_data_close()
						}else if(res=="501"){
							ElMessage.warning("经纬度坐标已存在！！！")
						}else if(res=="502"){
							ElMessage.warning("设备地址不能为空！！！")
						}else if(res=="503"){
							ElMessage.warning("设备区域不能为空！！！")
						}else if(res=="504"){
							ElMessage.warning("设备地址重复！！！")
						}else{
							ElMessage.error('添加失败')
						}
					})
				}
			},
			add_device_info_btn_save(){
				if(this.device_info_data_obj['region']==""||this.device_info_data_obj['address']==""||this.device_info_data_obj['lon']==""||this.device_info_data_obj['lat']==""){
					ElMessage.error('数据不能为空 ！！！')
				}else if(JSON.stringify(this.device_info_data_obj) == "{}"){
					ElMessage.error('数据不能为空 ！！！')
				}else{
					request.post(this.$GLOBAL.flask_web_local_url+'/add_new_device_info',this.device_info_data_obj).then(res =>{
						// console.log(res)					
						if(res=="200"){
							ElMessage.success('添加成功')
							this.add_device_info_btn_reset()
							this.add_device_data_info()
							this.get_device_data_info()
							// this.add_device_data_close()
						}else if(res=="501"){
							ElMessage.warning("经纬度坐标已存在！！！")
						}else if(res=="502"){
							ElMessage.warning("设备地址不能为空！！！")
						}else if(res=="503"){
							ElMessage.warning("设备区域不能为空！！！")
						}else if(res=="504"){
							ElMessage.warning("设备地址重复！！！")
						}else{
							ElMessage.error('添加失败')
						}
					})
				}
				// console.log(this.device_info_data_obj)
			},
			add_device_data_close(){
				// 清空数据
				this.$GLOBAL.device_info_data_obj['lon']=""
				this.$GLOBAL.device_info_data_obj['lat']=""
				this.device_info_data_obj={}
				
				this.add_device_info_btn_save_isShow = true
				// 关闭窗口
				this.edit_device_info_isShow = false
				document.getElementById("add_device_data_info_layout").style.marginTop = "400px"
			},
			delete_device_data_info(e){
				let msg_data ={
					"device_id":e['row']['device_id']
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/delete_device_info',msg_data).then(res =>{
					if(res=="200"){
						ElMessage.success('删除成功')
						this.get_device_data_info()
					}else{
						ElMessage.error('删除失败')
					}
				})
			},
			edit_device_data_info(e){
				// console.log(e['row'])
				this.device_info_data_obj['device_id'] = e['row']['device_id']
				this.device_info_data_obj['region'] = e['row']['region']
				this.device_info_data_obj['address'] = e['row']['address']
				this.device_info_data_obj['lon'] = e['row']['lon']
				this.device_info_data_obj['lat'] = e['row']['lat']
				// 切换修改模式
				this.add_device_info_title="修改设备信息"
				this.add_device_info_btn_save_isShow = false
				// 判断窗口是否打开
				if(!this.edit_device_info_isShow){
					this.edit_device_info_isShow = true
					document.getElementById("add_device_data_info_layout").style.marginTop = "-800px"
				}
			},
			// 添加
			add_device_data_info(){
				// 判断是否已经进入修改模式
				if(!this.add_device_info_btn_save_isShow){
					// 清除修改模式中的输入内容
					this.device_info_data_obj={}
				}else{
					if(this.edit_device_info_isShow){
						this.edit_device_info_isShow = false
						document.getElementById("add_device_data_info_layout").style.marginTop = "400px"
					}else{
						this.edit_device_info_isShow = true
						document.getElementById("add_device_data_info_layout").style.marginTop = "-800px"
					}
				}
				this.add_device_info_title="添加新设备信息"
				this.add_device_info_btn_save_isShow = true
			},
			get_device_data_info(){
				// 获取设备地图数据信息
				request.get(this.$GLOBAL.flask_web_local_url+'/get_map_data_info').then(res => 	{
					this.device_data_info=[]
					for(let i in res){
						this.device_data_info.push(res[i])
					}
					// console.log(this.device_data_info)
					
					// 初始化判断
					if(this.$GLOBAL.device_info_data_obj['lon']!=""&&this.$GLOBAL.device_info_data_obj['lat']!=""){
						this.add_device_data_info()
						// console.log(this.$GLOBAL.device_info_data_obj)
						// console.log(this.device_data_info)
						this.device_info_data_obj['lon'] = this.$GLOBAL.device_info_data_obj['lon']
						this.device_info_data_obj['lat'] = this.$GLOBAL.device_info_data_obj['lat']
					}
				})
			},
			seva_init_device_btn(){
				let msg_data = {
					username:sessionStorage.getItem('user'),
					init_show_all_label:this.init_show_all_label,
					init_show_all_list:this.init_show_all_list,
					init_show_all_status:this.init_show_all_status,
					init_region_id:this.init_region_id,

				}
				request.post(this.$GLOBAL.flask_web_local_url+'/update_init_settings',msg_data).then(res =>{
					// console.log(res)
					if(res=="200"){
						ElMessage.success('保存成功')
					}
				}).catch(function(){
					ElMessage.error('保存失败 ！！！')
				})
			},
			seva_permission_btn(val){
				let msg_data = {
					permission_level:0,
					data:"",
				}
				switch(val){
					case 1:
						msg_data['permission_level']=1
						msg_data['data']=this.setting_power_data[0]						
						break;
					case 2:
						msg_data['permission_level']=2
						msg_data['data']=this.setting_power_data[1]	
						break;
					case 3:
						msg_data['permission_level']=3
						msg_data['data']=this.setting_power_data[2]	
						break;
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/update_permission_data',msg_data).then(res =>{
					// console.log(res)
					if(res=="200"){
						ElMessage.success('保存成功')
					}
				}).catch(function(){
					ElMessage.error('保存失败 ！！！')
				})
				
				// // 调用全局的判断用户权限方法
				// this.$GLOBAL.methods.check_permission('show_maintenance_device_data').then(val=>{
				// 	if(val=="500"){
				// 		this.$router.push("/login")
				// 	}else {
				// 		ElMessage.success(val+"---")
				// 		console.log(val)
				// 	}
				// })

			},
			device_type_save_show_1(){				
				let msg = {
					"device_id":this.choose_device_type,
					"error_check":this.choose_error_check_type_id,
					"data":this.choose_display_type_id,
					"hcdjys":this.choose_hcdjys_time_type_id,
					"hcys":this.choose_hcys_time_type_id,
					"hcmdys":this.choose_hcmdys_time_type_id,
					"lcdjys":this.choose_lcdjys_time_type_id,
					"lcys":this.choose_lcys_time_type_id,
					"lcmdys":this.choose_lcmdys_time_type_id,
					"gztj":this.choose_gztj_type_id,
					"hcgz":this.choose_hcgz_type_id,
					"lcgz":this.choose_lcgz_type_id,
				}
				console.log(msg)
				request.post(this.$GLOBAL.flask_web_local_url+'/update_device_type_by_id_2',msg).then(res => {
					if(res=="200"){
						ElMessage.success("保存成功")
					}
					// else if(res=="501"){
					// 	ElMessage.warning("经纬度坐标已存在！！！")
					// }else if(res=="502"){
					// 	ElMessage.warning("设备地址不能为空！！！")
					// }else if(res=="503"){
					// 	ElMessage.warning("设备区域不能为空！！！")
					// }else if(res=="504"){
					// 	ElMessage.warning("设备地址重复！！！")
					// }
				})
			},
			device_type_save(){
				let msg = {
					"device_id":this.choose_device_type,
					"data":this.choose_device_type_data
				}
				// console.log(msg)
				request.post(this.$GLOBAL.flask_web_local_url+'/update_device_type_by_id',msg).then(res => {
					if(res=="200"){
						ElMessage.success("保存成功")
					}
					// else if(res=="501"){
					// 	ElMessage.warning("经纬度坐标已存在！！！")
					// }else if(res=="502"){
					// 	ElMessage.warning("设备地址不能为空！！！")
					// }else if(res=="503"){
					// 	ElMessage.warning("设备区域不能为空！！！")
					// }else if(res=="504"){
					// 	ElMessage.warning("设备地址重复！！！")
					// }
				})
			},
			select_all_device_type(){
				request.post(this.$GLOBAL.flask_web_local_url+'/get_error_check_list',{"device_id":this.choose_device_type}).then(res => {
					let data_list = res['data_list'].split("|")
					// console.log(res)
					this.choose_error_check_type_id = []
					if(res['data_list']!=""){
						for( let i in data_list){
							this.choose_error_check_type_id.push(data_list[i])
						}
					}
					// console.log(this.choose_error_check_type_id)
				})
				request.post(this.$GLOBAL.flask_web_local_url+'/get_device_type_by_id',{"device_id":this.choose_device_type}).then(res => {
					this.choose_device_type_data = []
					this.choose_display_type_id = []
					this.choose_hcdjys_time_type_id = []
					this.choose_hcys_time_type_id = []
					this.choose_hcmdys_time_type_id = []
					this.choose_lcdjys_time_type_id = []
					this.choose_lcys_time_type_id = []
					this.choose_lcmdys_time_type_id = []
					this.choose_gztj_type_id = []
					this.choose_hcgz_type_id = []
					this.choose_lcgz_type_id = []
					this.select_type_id_options = []
					for(let i in res){
						
						if(res[i]['type_id'].indexOf("1_")!=-1){
							if(res[i]['type_id']=="1_15"&&res[i]['data_position']!=""){
								
								let msg_data = res[i]['data_position'].split("|")
								for(let j in msg_data){
									this.choose_hcdjys_time_type_id.push(msg_data[j])
								}
							}
							if(res[i]['type_id']=="1_16"&&res[i]['data_position']!=""){
								
								let msg_data = res[i]['data_position'].split("|")
								for(let j in msg_data){
									this.choose_hcys_time_type_id.push(msg_data[j])
								}
							}
							if(res[i]['type_id']=="1_17"&&res[i]['data_position']!=""){
								
								let msg_data = res[i]['data_position'].split("|")
								for(let j in msg_data){
									this.choose_hcmdys_time_type_id.push(msg_data[j])
								}
							}
							if(res[i]['type_id']=="1_18"&&res[i]['data_position']!=""){
								
								let msg_data = res[i]['data_position'].split("|")
								for(let j in msg_data){
									this.choose_lcdjys_time_type_id.push(msg_data[j])
								}
							}
							if(res[i]['type_id']=="1_19"&&res[i]['data_position']!=""){
								
								let msg_data = res[i]['data_position'].split("|")
								for(let j in msg_data){
									this.choose_lcys_time_type_id.push(msg_data[j])
								}
							}
							if(res[i]['type_id']=="1_20"&&res[i]['data_position']!=""){
								
								let msg_data = res[i]['data_position'].split("|")
								for(let j in msg_data){
									this.choose_lcmdys_time_type_id.push(msg_data[j])
								}
							}
							if(res[i]['type_id']=="1_21"&&res[i]['data_position']!=""){
								
								let msg_data = res[i]['data_position'].split("|")
								for(let j in msg_data){
									this.choose_gztj_type_id.push(msg_data[j])
								}
							}
							if(res[i]['type_id']=="1_22"&&res[i]['data_position']!=""){
								
								let msg_data = res[i]['data_position'].split("|")
								for(let j in msg_data){
									this.choose_hcgz_type_id.push(msg_data[j])
								}
							}
							if(res[i]['type_id']=="1_23"&&res[i]['data_position']!=""){
								
								let msg_data = res[i]['data_position'].split("|")
								for(let j in msg_data){
									this.choose_lcgz_type_id.push(msg_data[j])
								}
							}
						}
						if(res[i]['type_id'].indexOf("2_")!=-1){
							this.choose_device_type_data.push(res[i])
							
							if(['AIW36','AIW38','VW108','VW124','AIW52','AIW54'].indexOf(res[i]['data_position'])==-1){
								let info_data = {
									'value':res[i]['type_id'],
									'label':res[i]['content']+" -- "+res[i]['data_position'],
								}
								this.select_type_id_options.push(info_data)
								if(res[i]['show_1']==1||res[i]['show_1']=="1"){
									this.choose_display_type_id.push(res[i]['type_id'])
								}
								// console.log(res[i])
								
							}

						}
						
					}					
				})
			},
			get_all_device_info(){
				request.post(this.$GLOBAL.flask_web_local_url+'/get_private_device_info',{"username":sessionStorage.getItem('user')}).then(res => {
					for(let i in res){
						let info_data = {
							'value':res[i]['device_id'],
							'label':res[i]['device_id']+" -- "+res[i]['address'],
							'region':res[i]['region'],
							'address':res[i]['address'],
						}
						this.select_options.push(info_data)
					}
					// 通过服务器获取默认选择的区域设备id  *****************************
					this.get_init_settings_data()
					// // 获取对应id的设备数据类型
					// this.select_all_device_type()
				})
				// request.get(this.$GLOBAL.flask_web_local_url+'/get_device_info').then(res => {
				// 	// console.log(res)
				// 	for(let i in res){
				// 		let info_data = {
				// 			'value':res[i]['device_id'],
				// 			'label':res[i]['device_id']+" -- "+res[i]['address'],
				// 			'region':res[i]['region'],
				// 			'address':res[i]['address'],
				// 		}
				// 		this.select_options[i]=info_data
				// 		// console.log(i)
				// 	}
				// })
			},
			// 获取用户配置信息
			get_init_settings_data(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_init_settings?username='+sessionStorage.getItem('user')).then(res => {
					// this.init_region_id = res['init_device_id']
					this.init_show_all_label = res['init_device_label_isShow']
					this.init_show_all_list = res['init_device_data_list_isShow']
					this.init_show_all_status = res['init_device_status_list_isShow']
					
					this.init_region_id = this.select_options[0]['value']
					for(let i in this.select_options){
						if(this.select_options[i]['value']==res['init_device_id']){
							this.init_region_id = res['init_device_id']
						}
					}
					this.choose_device_type = this.init_region_id
					// 获取对应id的设备数据类型
					this.select_all_device_type()
				})
			},
		},
		data(){
			return {
				choose_error_check_type_id:[],
				choose_display_type_id:[],
				choose_hcdjys_time_type_id:[],
				choose_hcys_time_type_id:[],
				choose_hcmdys_time_type_id:[],
				choose_lcdjys_time_type_id:[],
				choose_lcys_time_type_id:[],
				choose_lcmdys_time_type_id:[],
				choose_gztj_type_id:[],
				choose_hcgz_type_id:[],
				choose_lcgz_type_id:[],
				setting_power_data:[
					{
						"device_data_1":false,
						"device_data_2":false,
						"device_data_3":false,
						"see_user_data":false,
						"edit_user_data":false,
						"system_setting":false,
					},
					{
						"device_data_1":false,
						"device_data_2":false,
						"device_data_3":false,
						"see_user_data":false,
						"edit_user_data":false,
						"system_setting":false,
					},
					{
						"device_data_1":false,
						"device_data_2":false,
						"device_data_3":false,
						"see_user_data":false,
						"edit_user_data":false,
						"system_setting":false,
					},
				],
				select_options:[],
				select_type_id_options:[],
				init_show_all_label:false,
				init_show_all_list:false,
				init_show_all_status:false,
				set_system:false,
				init_region_id:"device1",
				main_activeNames:["5"],//默认展开   ['1']
				maxHeight_model:'calc((100vh - 150px)*0.68)',
				maxHeight_shebei:'calc((100vh - 150px)*0.68)',
				device_data_info:[],
				edit_device_info_isShow:false,
				add_device_info_title:"",
				device_info_data_obj:{
					"device_id":"",
					"region":"",
					"address":"",
					"lon":"",
					"lat":"",
				},
				choose_device_type:"",
				choose_device_type_data:[],
				add_device_info_btn_save_isShow:true,
				rowState:({ row,rowIndex }) => {
					let style ={
						// backgroundColor: '#01313d',
						// color: "#3f7f5e",
						// fontSize:"14px",
						// fontWeight:"600",
					}
					// if(row.end=="异常"){
					// 	style.backgroundColor='#ffa366'
					// }
					return style
				},
				// 单元格样式
				cellState:function(row){
					let style ={
						// border:"1px solid #01313d",
						// borderLeft:"1px solid #01313d",
						// borderRight:"1px solid #01313d",
						// backgroundColor: 'rgba(108, 204, 255, 0.1)',
						// color: "#5cbc8b",
						textAlign: "center",
						borderTop:"1px solid #195b69",
						// borderBottom:"1px solid #195b69"
					}
					// console.log(row)
					if(row.columnIndex==5&&row.row.state=="未处理"){
						// style.backgroundColor='#ffa366';
						style.color='#bf7f00';
						// style.borderLeft="1px solid #ffa366"
						// style.borderRight="1px solid #ffa366"
						// style.borderRadius="2.2px"
					}
					
					// if(row.columnIndex==0){
						// style.border="1px solid #6dfaff"
						// style.borderBottom="1px solid #6dfaff"
					// }
					// console.log(row.row.end)
					return style
					
				},
				//标题行样式
				headerRowState:function(row, rowIndex){
					// console.log(row)
					// console.log(rowIndex)
					let style ={
						// backgroundColor: '#195b69',
						// color: "#55aaff",
						
					}
					return style
				},
				//标题单元格样式
				headerCellState:function(row, rowIndex){
					// console.log(row.columnIndex)
					let style ={
						backgroundColor: '#195b69',
						color: "#55aaff",
						textAlign: "center",
						// borderRadius : "15px",
						// border: 'none !important',
					}
					// if(row.columnIndex==0){
					// 	style.borderTopLeftRadius = "10px"
					// }
					// if(row.columnIndex==7){
					// 	style.borderTopRightRadius = "10px"
					// }
					return style
				},
			}
		},
		watch: {
			main_activeNames(newVal, oldVal) {
			    // 监听 num 属性的数据变化
				// 作用 : 只要 num 的值发生变化,这个方法就会被调用 
	            //只要没有发生变化，就没有办法进行其他的操作
			    //newData是更新后的数据
	            //oldData是旧数据
		        // console.log('oldVal:',oldVal)
			    // console.log('newVal:',newVal)
				console.log(this.main_activeNames)
			}
		},
		//模板渲染完成后执行，可以拿到DOM节点和数据
		mounted(){
			
		// 	1.系统权限设置
			request.get(this.$GLOBAL.flask_web_local_url+'/get_permission_data').then(res => 	{
				for(let i in res){
					this.setting_power_data[i].device_data_1=res[i].show_real_time_device_data
					this.setting_power_data[i].device_data_2=res[i].show_status_device_data
					this.setting_power_data[i].device_data_3=res[i].show_maintenance_device_data
					this.setting_power_data[i].see_user_data=res[i].query_user_data
					this.setting_power_data[i].edit_user_data=res[i].edit_user_data
					this.setting_power_data[i].system_setting=res[i].set_system
				}
			}).catch(function(){
					ElMessage.error('数据加载失败 ！！！')
			})	
			
			// 判断能否修改系统设置
			this.$GLOBAL.methods.check_permission('set_system').then(val=>{
				if(val=="500"){
					this.$router.push("/login")
				}else {
					if(val){
						this.set_system=true
					}else{
						this.set_system=false
					}
				}
			})
			
		// 	2.设备数据设置
			// 获取设备信息
			this.get_all_device_info()
			// // 获取用户配置信息
			// this.get_init_settings_data()
			// 4.设备状态配置
			
		// 3.设备信息维护
			this.get_device_data_info()
			
			
			
		},
	}
</script>

<style>

	.init_device_id_layout{
		margin-left: 50px;
		width: 500px;
		height: 50px;
	}
	.phone_init_device_id_title{
		color: #55ffff;
		font-size: 16px;
		width: 120px;
		height: 30px;
		line-height: 30px;
		/* float: left; */
		text-align: center;
		margin-top: 15px;
		/* background-color: #00557f; */
	}
	.phone_select_init_device_id_layout{
		/* margin-top: 7px; */
		width: calc(100% - 10px);
		margin-left: 10px;
		/* float: left; */
	}
	.phone_init_item_layout{
		margin-left: 10px;
		height: 40px;
		/* min-width: 120px; */
		width: calc(100% - 10px);
		background-color: rgba(255, 255, 255, 0.7);
		border-radius: 20px;
		line-height: 40px;
		color: #002832;
		font-size: 17px;
		padding-left: 15px;
		display: inline-block;
		margin-top: 5px;
		margin-bottom: 2px;
	}
	.main_device_data_info_layout{
		overflow: hidden !important;
	}
	.device_data_info_layout{
		/* height: 90%; */
		height: 520px;
		/* margin-top: 10px; */
		position: relative;
	}
	.phone_add_device_data_info_btn{
		/* position: absolute; */
		width: 96%;
		margin-left: 2%;
		height: 40px;
		margin-top: -10px;
		margin-bottom: 10px;
		line-height: 40px;
		background-color: #00a1ff;
		background-color: rgba(0, 161, 255, 0.8);
		border-radius: 20px;
		color: #ffffff;
		font-size: 17px;
		text-align: center;border-width: 92%;	
		transition: margin-left 0.6s 0s,width 0.6s 0s;
		
	}
	.phone_add_device_data_info_btn:hover{
		
		width: 90%;
		margin-left: 5%;
		background-color: rgba(0, 170, 255, 0.9);
	}
	.phone_add_device_data_info_layout{
		z-index: 99;
		position: absolute;
		margin-left: -10px;
		width: calc(100% - 20px);
		height: 215px;
		/* height: 600px; */
		background-color: rgba(0, 85, 127, 0.97);
		box-shadow: 0px 0px 5px 3px #55ffff, inset 0px 0px 10px 3px #55ffff;
		border-radius: 30px;
		margin-top: 400px;
		transition: margin 0.6s 0s;
	}
	.phone_add_device_info_title{
		width: 100%;
		text-align: center;
		font-size: 19px;
		color: #55ffff;
		border-bottom:1px solid #ffffff;
		margin-bottom: 5px;
	}
	.phone_add_device_info_item{
		width: 100%;
		text-align: right;
		font-size: 17px;
		margin-top: 2px;
		
	}
	.phone_add_device_info_btn{
		width: 100%;
		text-align: right;
		font-size: 18px;
		margin-top: 5px;
		/* font-weight: 600; */
	}
	.phone_add_device_info_btn_reset{
		text-align: center;
		background-color: #d5d5d5;
		color: #00aaff;
		border-radius: 10px;
	}
	.phone_add_device_info_btn_save{
		text-align: center;
		background-color: #00aaff;
		color: #55ffff;
		border-radius: 10px;
	}
	.add_device_info_close{
		position: absolute;
		line-height: 40px;
		font-size: 30px;
		float: right;
		color: #ff5500;
		font-weight: 600;
		width: 60px;
		height: 40px;
		margin-left: calc(100% - 75px);
		text-align: center;
	}
	.add_device_info_close:hover{
		line-height: 40px;
		font-size: 40px;
		color: #ff5500;
		font-weight: 600;
	}
	.device_info_data_edit_btn{
		background-color: rgba(85, 170, 255, 0.7);
		color: #55ffff;
		border-radius: 6px;
		width: calc(50% - 10px);
		display: inline-block;
	}
	.device_info_data_delete_btn{
		background-color: rgba(255, 170, 0, 0.7);
		color: #ffffff;
		border-radius: 6px;
		margin-left: 20px;
		width: calc(50% - 10px);
		display: inline-block;
	}
	.phone_device_type_is_show_header{
		margin-top: 10px;
		margin-left: 2%;
		margin-bottom: 15px;
		width: 96%;
		height: 90px;
		background-color: #00557f;	
		background-color: rgba(19, 77, 88, 0.4);
		border-radius: 10px;
		border: 1px solid #77f7f8;
		box-shadow: 0px 0px 3px 2px #6dfaff;
	}
	.phone_device_type_save_btn{
		height: 50px;
		width: 100%;
		color: #6dfaff;
		font-size: 18px;
		font-weight: 600;
		/* text-align: left; */
		line-height: 56px;
		/* padding-top: 21px; */
	}
	.phone_device_type_save_btn .el-button{
		background-color: rgba(108, 204, 255, 0.1);
		box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
		color: #6cccff;
		font-size: 15px;
		font-weight: 600;
		border: none;
	}
	.setting_sssj{
		box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
		width: 100%;
		height: 1000px;
		margin-bottom: -20px;
		padding:5px ;
		overflow: auto;
	}
	.el-input__wrapper{
		
	}
	.phone_permission_device_select{
		/* margin-top: -5px; */
		/* height: 260px !important; */
		width: 100%;
		/* color: #8aff6d !important; */
		
	}
	.phone_permission_device_select .el-input__wrapper{
		height: 180px;
	}
	.phone_setting_mainLayout{
		/* margin-top: -20px; */
		/* height: 100%; */
		width: 100%;
		height: calc(100vh - 100px);
		/* min-height: calc(100vh - 150px); */
		/* min-height: 100%; */
		padding-right: 30px;
		/* background-color: #2b6dab; */
	}
	.phone_setting_main{
		/* background-color: #00557f; */
		margin-top: 5px;
		margin-left: -5px;
		height: calc(100% - 20px) ;
		width: calc(100% + 10px);
		padding-top: 0;
		color: #00a1ff;
	}
	.phone_setting_item_power{
		box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
		width: calc(100% - 0px);
		height: calc((100vh - 100px)*0.7);
		
		margin-bottom: -20px;
		/* margin-left: 5px; */
		color: #55aaff;
		text-align: left;
		/* padding: 10px; */
		font-size: 16px;
		overflow: auto;
		background-color: rgba(0, 170, 255, 0.2);
		margin-top: -10px;
	}
	.phone_power_item_save{
		/* position: absolute; */
		width: calc(100% - 20px);
		margin-left: 15px;
		height: 40px;
		line-height: 40px;
		background-color: #00a1ff;
		background-color: rgba(0, 161, 255, 0.8);
		border-radius: 20px;
		color: #ffffff;
		font-size: 18px;
		text-align: center;
		/* border-width: 92%;	 */
		bottom: 0px;
		margin-top: 5px;
		transition: margin-left 0.6s 0s,width 0.6s 0s;
		
	}
	.phone_power_item_save_2{
		/* position: absolute; */
		width: calc(100% - 10px);
		margin-left: 10px;
		height: 40px;
		line-height: 40px;
		background-color: #00a1ff;
		background-color: rgba(0, 161, 255, 0.8);
		border-radius: 20px;
		color: #ffffff;
		font-size: 18px;
		text-align: center;
		/* border-width: 92%;	 */
		bottom: 0px;
		margin-top: 5px;
		transition: margin-left 0.6s 0s,width 0.6s 0s;
		
	}
	.phone_power_setting{
		position: relative;
		height: calc(100% - 70px);
		overflow: auto;
		width: 100%;
		padding-right: 10px;
		/* margin-left: 1%; */
		/* border: 1px solid; */
		border-top: 2px solid rgba(85, 255, 255, 1.0);
		border-bottom: 2px solid rgba(85, 255, 255, 1.0);
		border-radius: 20px;
		/* border-bottom: 2px solid #ffffff; */
		/* text-align: center; */
		margin-top: 10px;
		/* margin-bottom: 20px; */
		/* padding-left: 10px; */
	}
	.phone_power_item_layout{
		margin-left: 15px;
		height: 40px;
		/* min-width: 120px; */
		width: calc(100% - 20px);
		background-color: rgba(255, 255, 255, 0.7);
		border-radius: 20px;
		line-height: 40px;
		color: #002832;
		font-size: 18px;
		padding-left: 15px;
		display: inline-block;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.phone_setting_item_init{
/* 		position: relative;
		box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
		width: calc(100% - 80px);
		height: calc((100vh - 100px)*0.7);
		margin-bottom: -20px;
		margin-left: 40px;
		color: #55aaff;
		text-align: left;
		font-size: 16px;
		overflow: auto;
		background-color: rgba(0, 170, 255, 0.2); */
		
		box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
		width: calc(100% - 0px);
		height: calc((100vh - 100px)*0.7);
		
		margin-bottom: 5px;
		/* margin-left: 5px; */
		color: #55aaff;
		text-align: left;
		/* padding: 10px; */
		font-size: 16px;
		overflow: auto;
		background-color: rgba(0, 170, 255, 0.2);
		margin-top: -5px;
	}
</style>