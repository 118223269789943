import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import TestView from '../views/test.vue'
import MainLayout from'../layout/mainLayout.vue'
import phone_MainLayout from'../layout/phone_mainLayout.vue'
// import uesrHistory from '../views/userHistory.vue'
// import userMonitor from '../views/userMonitor.vue'
// import userError from '../views/userError.vue'
// import userSettings from '../views/userSettings.vue'

import login from '../views/login.vue'
import mgtAdmin from '../views/New_admin.vue'
import new_device_data from '../views/New_Device_Data.vue'
import New_Device_Data_1 from '../views/New_Device_Data_1.vue'
import New_Device_Data_2 from '../views/New_Device_Data_2.vue'
import New_Device_Data_3 from '../views/New_Device_Data_3.vue'
import New_Device_Data_4 from '../views/New_Device_Data_4.vue'
import New_Device_Data_5 from '../views/New_Device_Data_5.vue'
import New_Private_Device_1 from '../views/New_Private_Device_1.vue'
import New_Private_Device_2 from '../views/New_Private_Device_2.vue'
import New_Private_Device_2_s from '../views/New_Private_Device_2_s.vue'

import New_Private_Device_3 from '../views/New_Private_Device_3.vue'
import New_Private_Device_4 from '../views/New_Private_Device_4.vue'

import New_Settings from '../views/New_Settings.vue'
import New_History from '../views/New_History.vue'
import New_Submit from '../views/New_submit_data.vue'
import New_Documents from '../views/New_Documents.vue'

import phone_login from '../views/phone_login.vue'
import phone_Device_Data_1 from '../views/phone_Device_Data_1.vue'
import phone_Private_Device_1 from '../views/phone_Private_Device_1.vue'
import phone_Private_Device_2 from '../views/phone_Private_Device_2.vue'
import phone_Private_Device_3 from '../views/phone_Private_Device_3.vue'
import phone_Private_Device_4 from '../views/phone_Private_Device_4.vue'
import phone_mgtAdmin from '../views/phone_New_admin.vue'
import phone_New_History from '../views/phone_New_History.vue'
import phone_New_Documents from '../views/phone_New_Documents.vue'
import phone_New_Settings from '../views/phone_New_Settings.vue'

const routes = [
  {
  	path: '/',
  	name: 'home',
	redirect:"/login",
  },{
  	path: '/submit_data',
  	name: 'submit_data',
	component:New_Submit
  },
  {
  	path: '/login',
  	name: 'login',
	component:login
  	// redirect:"/MGT",
  },
  {
  	path: '/phone_login',
  	name: 'phone_login',
  	component:phone_login
  	// redirect:"/MGT",
  },
  {
    path: '/phone',
    name: 'phoneMainLayout',
    component: phone_MainLayout,
  	redirect:"/phone/main",
  	children:[
		{
			path:'main',
			name:'phone_main',
			component:phone_Device_Data_1,
		},
		{
			path: 'phone_Device_Data',
			name: 'phone_Device_Data',
			component:phone_Device_Data_1
		},
		{
			path: 'phone_Private_Device_1',
			name: 'phone_Private_Device_1',
			component:phone_Private_Device_1
		},
		{
			path: 'phone_Private_Device_2',
			name: 'phone_Private_Device_2',
			component:phone_Private_Device_2
		},
		{
			path: 'phone_Private_Device_3',
			name: 'phone_Private_Device_3',
			component:phone_Private_Device_3
		},
		{
			path: 'phone_Private_Device_4',
			name: 'phone_Private_Device_4',
			component:phone_Private_Device_4
		},
		{
			path: 'phone_mgtAdmin',
			name: 'phone_mgtAdmin',
			component:phone_mgtAdmin
		},
		{
			path: 'phone_New_History',
			name: 'phone_New_History',
			component:phone_New_History
		},
		{
			path: 'phone_New_Documents',
			name: 'phone_New_Documents',
			component:phone_New_Documents
		},
		{
			path: 'phone_New_Settings',
			name: 'phone_New_Settings',
			component:phone_New_Settings
		},
	],
  },
  {
    path: '/MGT',
    name: 'MainLayout',
    component: MainLayout,
	redirect:"/MGT/main",
	children:[

	{
		path:'main',
		name:'main',
		component:new_device_data,
	},{
		path:'Device_Data_1',
		name:'New_Device_Data_1',
		component:New_Device_Data_1,
	},{
		path:'Device_Data_2',
		name:'New_Device_Data_2',
		component:New_Device_Data_2,
	},{
		path:'Device_Data_3',
		name:'New_Device_Data_3',
		component:New_Device_Data_3,
	},{
		path:'Device_Data_4',
		name:'New_Device_Data_4',
		component:New_Device_Data_4,
	},{
		path:'Device_Data_5',
		name:'New_Device_Data_5',
		component:New_Device_Data_5,
	},{
		path:'Private_Device_1',
		name:'New_Private_Device_1',
		component:New_Private_Device_1,
	},{
		path:'Private_Device_2',
		name:'New_Private_Device_2',
		component:New_Private_Device_2,
	},{
		path:'Private_Device_2_s',
		name:'New_Private_Device_2_s',
		component:New_Private_Device_2_s,
	},{
		path:'Private_Device_3',
		name:'New_Private_Device_3',
		component:New_Private_Device_3,
	},{
		path:'Private_Device_4',
		name:'New_Private_Device_4',
		component:New_Private_Device_4,
	},{
		path:'admin',
		name:'admin',
		component:mgtAdmin
	},{
		path:'history',
		name:'history',
		component:New_History
	},{
		path:'settings',
		name:'settings',
		component:New_Settings
	},
	{
		path:'documents',
		name:'documents',
		component:New_Documents
	}
	]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
