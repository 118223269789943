<template>
	<div class="admin_mainLayout">
		<div class="boxBorder1 ">
		    <div class="boxBorder2">
				<el-row>
					<el-col :span="2"></el-col>
				    <el-col :span="6"><div class="main_title_style_b" /></el-col>						
				    <el-col :span="8">
						<div class="main_title_style_a" >
							账号管理
						</div>
					</el-col>
				    <el-col :span="6"><div class="main_title_style_c" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				
				<div class="admin_list_layout" >
					<el-main class="admin_list_data">
						<div v-for="i in admin_data" class="admin_obj" :id="i.id">
							<div class="admin_obj_text">
								姓名：{{i.name.length>4?i.name.slice(0,2)+"…":i.name}}
							</div>
							<div class="admin_obj_text">
								性别：{{i.sex}}
							</div>
							<div class="admin_obj_text">
								职位：{{i.job.length>4?i.job.slice(0,3)+"…":i.job}}
							</div>
							<div class="admin_obj_text">
								权限：{{i.power}}级
							</div>
							<div class="admin_obj_text" style="max-height: 35px;">
								手机：
							</div>
							<div style="margin-left: 30px;margin-top: 5px;margin-bottom: -8px;color: #ffffff;">
								{{i.phone.slice(0,13)}}
							</div>
							<div class="admin_obj_text admin_obj_button" @click="see_more_click">
								查看更多
							</div>
						</div>
						
						<div  class="add_admin" @click="add_admin_click" v-show="edit_user_data">
							<el-icon size="80px" class="add_admin_icon">
								<Plus />
							</el-icon>
							<div class="add_admin_text">
								添加账户
							</div>
						</div>

					</el-main>
					
				</div>
			</div>
		</div>
	</div>
	
	<el-dialog v-model="dialogTableVisible" draggable @close="close_admin_dialog">
		<template #default="scope">
		    <div class="admin_dialog_layout">
				<div style="
					width: 100%;
					height: 75%;
				" >
                	<el-row
						style="
							margin-top: 30px;
						">
						<el-col :span="4" >
							
						</el-col>
						<el-col :span="4" 
							style="
								text-align: right;
							">
							<span class="required_fields_tip">*</span>用户名：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="see_admin.username"
								id="see_admin_name" :disabled="edit_username_sign"
							></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>

                    <el-row
						v-if="edit_username_sign==false"
						style="
							margin-top: 20px;
						">
						<el-col :span="5" >
							
						</el-col>
						<el-col :span="3" 
							style="
								text-align: right;
							">
							<span class="required_fields_tip">*</span>密码：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="see_admin.password"
								id="see_admin_password" :disabled="unedit_admin_value"
							></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>

					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="5" >
							
						</el-col>
						<el-col :span="3" 
							style="
								text-align: right;
							">
							<span class="required_fields_tip">*</span>姓名：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="see_admin.name"
								id="see_admin_name" :disabled="unedit_admin_value"
							></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="5" >
							
						</el-col>
						<el-col :span="3" 
							style="
								text-align: right;
							">
							<span class="required_fields_tip">*</span>性别：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="see_admin.sex"
								id="see_admin_sex" :disabled="unedit_admin_value" 
							></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="5" >
							
						</el-col>
						<el-col :span="3" 
							style="
								text-align: right;
							">
							<span class="required_fields_tip">*</span>职位：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="see_admin.job"
								id="see_admin_job" :disabled="unedit_admin_value" 
							></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="5" >
							
						</el-col>
						<el-col :span="3" 
							style="
								text-align: right;
							">
							<span class="required_fields_tip">*</span>权限：
						</el-col>
						<el-col :span="8" >
							<!-- <el-input v-model="see_admin.power"
								id="see_admin_power" :disabled="unedit_admin_value" 
							></el-input> -->
							<el-select v-model="see_admin.power"
									class="permission_level_select" 
									placeholder="Select" 
									:disabled="unedit_admin_value"
									size="default">
							    <el-option
							      v-for="item in permission_level_select_options"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value"
								  class="permission_level_select_item"
							    />
							</el-select>
						</el-col>
						<el-col :span="8" >
						</el-col>
					</el-row>
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="5" >
							
						</el-col>
						<el-col :span="3" 
							style="
								text-align: right;
							">
							<span class="required_fields_tip">*</span>手机：
						</el-col>
						<el-col :span="8">
							<el-input v-model="see_admin.phone"
								id="see_admin_phone" :disabled="unedit_admin_value" 
							></el-input>
							
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="5" >
							
						</el-col>
						<el-col :span="3" 
							style="
								text-align: right;
							">
							<span class="required_fields_tip">*</span>地址：
						</el-col>
						<el-col :span="8" >
							<el-input id="see_admin_address" :disabled="unedit_admin_value" v-model="see_admin.address"  ></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					<el-row
						style="
							margin-top: 20px;
						"
						v-if="edit_username_sign==true"
						>
						<el-col :span="4" >
							
						</el-col>
						<el-col :span="4" 
							style="
								text-align: right;
							"
							
							>
							专属设备：
						</el-col>
						<el-col :span="8" >
							<el-select 
								v-model="choose_display_devices" 
								class="permission_level_select" 
								placeholder="选择专属设备"
								multiple 
								filterable
								:disabled="unedit_admin_value"
								placement ="left-start"
								@change="display_devices_change"
								>
								<!-- :disabled="see_admin.display_device=='全部设备'" -->
								
							    <el-option
							      v-for="item in device_data_info"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value"
							    />
							</el-select>			
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					<!-- <el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="6" >
							
						</el-col>
						<el-col :span="2" 
							style="
								text-align: right;
							"
							
							>
							更多：
						</el-col>
						<el-col :span="8" >
							<el-input 
								type="textarea"
								v-model="see_admin.more"
								:rows="4"
								autosize
								id="see_admin_more" :disabled="unedit_admin_value" 
								>
							</el-input>
							
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row> -->
				</div>
				<el-row style="margin-top: 50px;">
					<el-col :span="15">
						
					</el-col>
					<el-col :span="4" style="text-align: right;">
						<el-button size="large" style="
							background-color: rgba(108, 204, 255, 0.1);
							box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
							color: #6cccff;
							font-size: 15px;
							font-weight: 600;
							border: none;
							"
							ref="save_change_button"
							id="save_change_button"
							@click="change_admin_dialog"
							v-show="edit_user_data"
							>
							{{admin_dialog_button_text}}
						</el-button>
					</el-col>
					<el-col :span="4">
						<el-popconfirm
							title="确定删除吗？" 
							@confirm="delete_admin"
						>
						  <template #reference>
						    <el-button 
								type="danger" 
								size="large" 
								style="
									background-color: rgba(255, 85, 127, 0.3);
									box-shadow: 0px 0px 5px 2px #ff557f, inset 0px 0px 10px 1px #ff557f;
									color: #ff557f;
									font-size: 15px;
									font-weight: 600;
									border: none;"
								v-show="edit_user_data">
								删除
							</el-button>
						  </template>
						</el-popconfirm>
					</el-col>
				</el-row>
		    </div>
		</template>
	</el-dialog>
</template>

<script>
	import request from "@/utils/request";
	import { Plus} from '@element-plus/icons-vue'
	import { ElMessage } from 'element-plus'
	export default{
		components:{
			Plus
		},
		data(){
			return {
				choose_display_devices:[],
				edit_user_data:false,
				dialogTableVisible:false,
				unedit_admin_value:true,
				admin_dialog_button_text:"修改",
				admin_data:[
					
				],
				see_admin:{},
                edit_username_sign:true,
				permission_level_select_options:[
					{
					    label: '1级',
					    value: '1',
					},{
					    label: '2级',
					    value: '2',
					},{
						value: '3',
						label: '3级',
					}
				],
				device_data_info:[],
			}
		},
		methods:{
			display_devices_change(){
				// console.log(this.choose_display_devices)
				for(let i in this.choose_display_devices){
					if(this.choose_display_devices[i]==99){
						this.choose_display_devices=[99]
						break
					}
				}
			},
			//查看详情
			see_more_click(e){
				// console.log(this.admin_data[0])
				this.choose_display_devices=[]
				let obj_id = e.target.offsetParent.id
				for(let i in this.admin_data){
					if(this.admin_data[i].id==obj_id){
						// this.see_admin=this.admin_data[i]
						this.see_admin.id=this.admin_data[i].id
						this.see_admin.address=this.admin_data[i].address
						this.see_admin.job=this.admin_data[i].job
						this.see_admin.more=this.admin_data[i].more
						this.see_admin.name=this.admin_data[i].name
						this.see_admin.password=this.admin_data[i].password
						this.see_admin.phone=this.admin_data[i].phone
						this.see_admin.power=this.admin_data[i].power
						this.see_admin.sex=this.admin_data[i].sex
						this.see_admin.username=this.admin_data[i].username
						this.see_admin.display_device=this.admin_data[i].display_device
						if(this.see_admin.display_device=="99"){
							this.choose_display_devices=[99]
						}else{
							let data_arr = this.see_admin.display_device.split(",")
							for(let i in data_arr){
								this.choose_display_devices.push(data_arr[i])
							}
						}
					}
				}
				// console.log(this.see_admin)
				// console.log(this.choose_display_devices)
				this.dialogTableVisible=true
			},
			// 关闭窗口
			close_admin_dialog(){
				// 对话框不可编辑
				this.unedit_admin_value=true
				// 清空数据
				this.see_admin={}
				this.admin_dialog_button_text="修改"
				// document.getElementById("save_change_button").innerHTML="修改"
                this.edit_username_sign=true
				// this.get_admin_data()
			},
			add_admin_click(){
				this.admin_dialog_button_text="添加"
				this.see_admin={}
				// 打开对话框
				this.dialogTableVisible=true
				// 对话框可编辑
				this.unedit_admin_value=false		
				// 用户名可编辑
				this.edit_username_sign=false
			},
			//对话框点击事件
			change_admin_dialog(e){
				// e.target.innerHTML=="保存"
				if(this.admin_dialog_button_text=="保存"){
					this.admin_dialog_button_text="修改"
					this.unedit_admin_value=true
					this.dialogTableVisible=false
					// 修改的数据上传到服务器保存
					// console.log(this.choose_display_devices)
					// console.log(this.see_admin)
					let display_device = ""
					for(let i in this.choose_display_devices){
						if(display_device==""){
							display_device = this.choose_display_devices[i]
						}else{
							display_device = display_device +","+this.choose_display_devices[i]
						}
						if(this.choose_display_devices[i]==99){
							display_device = 99
							break
						}
					}
					this.see_admin.display_device = display_device
					request.post(this.$GLOBAL.flask_web_local_url+'/update_admin_data',JSON.stringify(this.see_admin)).then(res =>{
						// console.log(res)
						this.get_admin_data()
					}).catch(function(){
						ElMessage.error('数据修改失败 ！！！')
					})	
				}else if(this.admin_dialog_button_text=="添加"){
					// 添加的数据上传到服务器保存
					this.add_admin()
				}else if(this.admin_dialog_button_text=="修改"){
					this.admin_dialog_button_text="保存"
					this.unedit_admin_value=false
				}
			},
			// 删除数据
			delete_admin(){
				let that = this
				request.post(this.$GLOBAL.flask_web_local_url+'/delete_admin_data',JSON.stringify(this.see_admin)).then(res =>{
					that.get_admin_data()
					that.dialogTableVisible=false
				}).catch(function(){
					ElMessage.error('数据删除失败 ！！！')
				})				
			},
			// 添加数据
			add_admin(){
				let that = this
				request.post(this.$GLOBAL.flask_web_local_url+'/add_admin_data',JSON.stringify(this.see_admin)).then(res =>{
					// console.log(res)
					that.get_admin_data()
					// 关闭对话框
					that.dialogTableVisible=false
					// 用户名不可编辑
					this.edit_username_sign=true
				}).catch(function(){
					ElMessage.error('数据添加失败 ！！！')
				})	
			},
			// 获取数据
			get_admin_data(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_admin_data').then(res => 	{
					// console.log(this.$GLOBAL.flask_web_local_url+'/get_admin_data')
					this.admin_data=[]
					for(let i in res){
						this.admin_data[i]=res[i]
					}
					// console.log(this.admin_data)
				}).catch(function(){
					ElMessage.error('数据加载失败 ！！！')
				})	
			},
			get_all_device_info(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_device_info').then(res => 	{
					this.device_data_info=[]
					let default_data = {
						'value':99,
						'label':"全部设备",
					}
					this.device_data_info.push(default_data)
					for(let i in res){
						let info_data = {
							'value':res[i]['device_id'],
							'label':res[i]['device_id']+" - "+res[i]['address'],
							'region':res[i]['region'],
							'address':res[i]['address'],
						}
						this.device_data_info.push(info_data) 
					}
					
				})
			},
		},
		// 页面初始化
		mounted(){
			// 获取admin数据表内容
			this.get_admin_data()
			// 调用全局的判断用户权限方法
			this.$GLOBAL.methods.check_permission('edit_user_data').then(val=>{
				if(val=="500"){
					this.$router.push("/login")
				}else {
					this.get_all_device_info()
					if(val){
						this.edit_user_data=true
					}else{
						this.edit_user_data=false
					}
				}
			})
		},
	}
</script>

<style>
	.permission_level_select{
		/* margin-top: -5px; */
		width: 260px;
		/* color: #8aff6d !important; */
		
	}

	.permission_level_select_item {
		text-align: center;
		background-color: rgba(191, 191, 191, 0.8) ;
	}
	.permission_level_select_item :nth-child(1){
		color: #6dfaff ;
	}
	.required_fields_tip{
		color: #ff5500 ;
		font-size: 26px;
	}
	.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
		background-color: rgba(255, 255, 255, 0.8) !important;
		border-radius: 10px;
	}
	.el-select__input{
		color: #00ffff !important;
		font-size: 20px !important;
	}
	.el-select-dropdown__empty{
		color: #00ffff !important;
	}
	.el-select__tags-text{
		color: #55aaff;
	}
	.el-select-tags-wrapper{
		height: 120px;
		overflow-y: auto;
		overflow-x: hidden;
	}
</style>