<template>
	<div class="admin_mainLayout">
		<div class="boxBorder1 ">
		    <div class="boxBorder2">
				<el-row>
					<el-col :span="1"></el-col>
				    <el-col :span="6"><div class="phone_main_title_style_b" /></el-col>						
				    <el-col :span="10">
						<div class="phone_main_title_style_a" >
							历史记录
						</div>
					</el-col>
				    <el-col :span="6"><div class="phone_main_title_style_c" /></el-col>
					<el-col :span="1"></el-col>
				</el-row>
				
				<div class="phone_history_main_layout">
					<el-tabs   
						v-model="tabs_index_1" 
						tab-position="bottom" 
						:before-leave="tab_click_handle" 
						
						>
						<el-tab-pane label="数据监控" class="phone_history_data_layout">
							<div class="phone_device_data_5_layout_title">
								<el-row>
									<el-col :span="1"></el-col>
									<el-col :span="11">
										<el-date-picker
												class="phone_date_picker_layout"
										        v-model="select_type_1_time[0]"
										        type="datetime"
										        placeholder="开始时间"
										        format="MM-DD HH:mm:ss"
										        date-format="YYYY/MM/DD "
										        time-format="hh:mm:ss"
												size="small"
												:clearable=false
												@change="select_type_1_time_onChange"
										      />
									</el-col>
									<el-col :span="11">
										<el-date-picker
												class="phone_date_picker_layout"
										        v-model="select_type_1_time[1]"
										        type="datetime"
										        placeholder="截止时间"
										        format="MM-DD HH:mm:ss"
										        date-format="YYYY/MM/DD "
										        time-format="hh:mm:ss"
												size="small"
												:clearable=false
												@change="select_type_1_time_onChange"
										      />
									</el-col>
									<el-col :span="1"></el-col>
								</el-row>
								<el-row>
									<el-col :span="1"></el-col>
									<el-col :span="20" style="margin-top: 5px;">
										<el-select v-model="select_type_1_device_id" 
												class="phone_error_history_now_region_select" 
												placeholder="Select" 
												@change="select_type_1_time_onChange"
												filterable 
												size="small">
										    <el-option
										      v-for="item in select_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
											  class="history_select_options_item"
										    />
										</el-select>
									</el-col>
									<!-- <el-col :span="8">
										<el-date-picker
												class="date_picker_layout"
										        v-model="select_type_1_time"
										        type="datetimerange"
										        start-placeholder="开始时间"
										        end-placeholder="结束时间"
										        format="YYYY-MM-DD HH:mm:ss"
										        date-format="YYYY/MM/DD "
										        time-format="hh:mm:ss"
												:shortcuts="shortcuts"
												size="large"
												@change="select_type_1_time_onChange"
												:clearable=false
										      />
											  
											  
									</el-col> -->
									<el-col :span="3">
										<div class="phone_return_btn_" v-if="return_btn_isShow" @click="return_btn_"></div>
									</el-col>
									
									
								</el-row>
							</div>
							<div class="phone_history_data_layout_1" >
										
										
										
									
										<div class="phone_history_data_1_show_default" v-show="show_5_default_isShow">
											<div class="box">
												<div class="box-circle"></div>
												<div class="box-circle1"></div>
												<div class="box-circle2"></div>
											</div>
										</div>
										<div class="phone_history_data_1_show" id="data_chistory_show_5">
											
										</div>
									
										<div class="phone_history_data_1_show_default" v-show="show_6_default_isShow">
											<div class="box">
												<div class="box-circle"></div>
												<div class="box-circle1"></div>
												<div class="box-circle2"></div>
											</div>
										</div>
										<div class="phone_history_data_1_show" id="data_chistory_show_6">
											
										</div>
										
										<div class="phone_history_data_1_show_default" v-show="show_1_default_isShow">
											<div class="box">
												<div class="box-circle"></div>
												<div class="box-circle1"></div>
												<div class="box-circle2"></div>
											</div>
										</div>
										<div class="phone_history_data_1_show" id="data_chistory_show_1" >
											
										</div>
																			
																			
										<div class="phone_history_data_1_show_default" v-show="show_2_default_isShow">
											<div class="box">
												<div class="box-circle"></div>
												<div class="box-circle1"></div>
												<div class="box-circle2"></div>
											</div>
										</div>
										<div class="phone_history_data_1_show" id="data_chistory_show_2">
											
										</div>
																			
										<div class="phone_history_data_1_show_default" v-show="show_3_default_isShow">
											<div class="box">
												<div class="box-circle"></div>
												<div class="box-circle1"></div>
												<div class="box-circle2"></div>
											</div>
										</div>
										<div class="phone_history_data_1_show" id="data_chistory_show_3">
											
										</div>
																			
										<div></div>
										<div class="phone_history_data_1_show_default" v-show="show_4_default_isShow">
											<div class="box">
												<div class="box-circle"></div>
												<div class="box-circle1"></div>
												<div class="box-circle2"></div>
											</div>
										</div>
										<div class="phone_history_data_1_show" id="data_chistory_show_4">
											
										</div>
							</div>
							
						</el-tab-pane>
						<el-tab-pane label="状态记录" class="phone_history_data_layout_2">
							<div class="phone_type_2_data_show_header" @click="close_data2_show" v-show="history_data_2_isShow">
								×
							</div>
							<div class="phone_type_2_data_show_layout" v-show="history_data_2_isShow">
								<!-- data_2_show_default_isShow -->
								<div class="phone_type_2_data_show_data_default"  v-show="data_2_show_default_isShow">
									<div class="box">
										<div class="box-circle"></div>
										<div class="box-circle1"></div>
										<div class="box-circle2"></div>
									</div>
								</div>
								<div class="phone_type_2_data_show_data" id="data_chistory_show_7" >
									
								</div>
							</div>
							<div class="phone_device_data_type_2_layout_title">
								<el-row>
									<el-col :span="1"></el-col>
										<el-col :span="11">
											<el-date-picker
													class="phone_date_picker_layout"
											        v-model="select_type_2_time[0]"
											        type="datetime"
											        placeholder="开始时间"
											        format="MM-DD HH:mm:ss"
											        date-format="YYYY/MM/DD "
											        time-format="hh:mm:ss"
													size="small"
													:clearable=false
													@change="select_type_2_time_onChange"
											      />
										</el-col>
										<el-col :span="11">
											<el-date-picker
													class="phone_date_picker_layout"
											        v-model="select_type_2_time[1]"
											        type="datetime"
											        placeholder="截止时间"
											        format="MM-DD HH:mm:ss"
											        date-format="YYYY/MM/DD "
											        time-format="hh:mm:ss"
													size="small"
													:clearable=false
													@change="select_type_2_time_onChange"
											      />
										</el-col>
										<el-col :span="1"></el-col>
									</el-row>
									<el-row>
										<el-col :span="1"></el-col>
										<el-col :span="22" >
											<el-select v-model="select_type_2_device_id" 
													class="phone_history_now_region_select" 
													placeholder="Select" 
													@change="select_type_2_time_onChange"
													filterable 
													size="small">
											    <el-option
											      v-for="item in select_options"
											      :key="item.value"
											      :label="item.label"
											      :value="item.value"
												  class="history_select_options_item"
											    />
											</el-select>
										</el-col>
									
									<el-col :span="1"></el-col>
									<el-col :span="9">
										<div class="phone_history_device_data2_select_col" style="margin-top: 4px;float: right;">
											数据名称： 
										</div>
									</el-col>
									<el-col :span="14" >
										<div class="phone_history_device_data2_select_col">
											<el-input v-model="device_data2_name_select"
												placeholder="  " 
												clearable
												@change="get_all_type2_davice_info"
												size="small"
												style="margin-top: 4px;"
												/>
										</div>
									</el-col>
									
									

									<el-col :span="3">
										<div class="return_btn_" v-if="return_btn_isShow" @click="return_btn_"></div>
									</el-col>
								</el-row>
							</div>
							<div class="phone_history_data_layout_2_content">
								<div class="phone_history_device_data2_show1" v-for="item in device_data2_list" @click="choose_one_type2_device_data(item)">
									<div class="phone_history_device_data2_show1_icon">
										
									</div>
									<div class="phone_history_device_data2_show1_title" >
										{{item.content}}
										<!-- 测试测试水水水水水水水水水水水水水水水水水水水 -->
									</div>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="维修记录" class="phone_history_data_layout_3">
							<div class="phone_device_data_5_layout_title">
								<el-row>
									<el-col :span="1"></el-col>
									<el-col :span="11">
										<el-date-picker
												class="phone_date_picker_layout"
										        v-model="select_type_3_time[0]"
										        type="datetime"
										        placeholder="开始时间"
										        format="MM-DD HH:mm:ss"
										        date-format="YYYY/MM/DD "
										        time-format="hh:mm:ss"
												size="small"
												:clearable=false
												@change="select_type_3_time_onChange"
										      />
									</el-col>
									<el-col :span="11">
										<el-date-picker
												class="phone_date_picker_layout"
										        v-model="select_type_3_time[1]"
										        type="datetime"
										        placeholder="截止时间"
										        format="MM-DD HH:mm:ss"
										        date-format="YYYY/MM/DD "
										        time-format="hh:mm:ss"
												size="small"
												:clearable=false
												@change="select_type_3_time_onChange"
										      />
									</el-col>
									<el-col :span="1"></el-col>
									<el-col :span="1"></el-col>
									<el-col :span="22" >
										<el-select v-model="select_type_3_device_id" 
												style="width: 100%;margin-top: 5px;"
												placeholder="Select" 
												@change="select_type_3_time_onChange"
												filterable 
												size="small">
										    <el-option
										      v-for="item in select_options"
										      :key="item.value"
										      :label="item.label"
										      :value="item.value"
											  class="history_select_options_item"
										    />
										</el-select>
									</el-col>
									<el-col :span="1"></el-col>
									<!-- <el-col :span="4"></el-col>
									<el-col :span="8">
										<el-date-picker
												class="date_picker_layout"
										        v-model="select_type_3_time"
										        type="daterange"
										        start-placeholder="开始时间"
										        end-placeholder="结束时间"
										        format="YYYY-MM-DD HH:mm:ss"
										        date-format="YYYY/MM/DD "
										        time-format="hh:mm:ss"
												size="large"
												@change="select_type_3_time_onChange"
												
										      />											  
									</el-col> -->
<!-- 									<el-col :span="1">
										<div class="return_btn_" v-if="return_btn_isShow" @click="return_btn_"></div>
									</el-col> -->
								</el-row>
							</div>
							<div class="phone_history_data_layout_3_content">
								<el-table
									:row-style="rowState"
									:cell-style="cellState"
									:header-row-style="headerRowState"
									:header-cell-style="headerCellState"
									:data="history_device_data_type_3" 
									:border="parentBorder" 
									style="width: 100%"
									height="100%"
									>
								    <el-table-column type="expand" width="28px">
										<template #default="props">
											<!-- {{props.row}} -->
										<el-row >
											<!-- <el-col :span="3"></el-col> -->
											<el-col :span="4" class="phone_data_type_3_expand_item_3" style="text-align: right;">
												序号
											</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item_3">
												检查内容
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_3" style="text-align: left;">
												检查结果
											</el-col>
										</el-row>
										
								        <el-row >
											<!-- <el-col :span="3"></el-col> -->
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >1</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												金属结构有无严重变形及裂纹、焊缝有无开裂等情况
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_1==1? "是" : "否"}}
											</el-col>
										</el-row>
										
										<el-row >
											
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >2</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												各部位的螺栓紧固情况，如法兰面螺栓、复位支架、液压站、电控箱、锚定机构连接螺栓等
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_2==1? "是" : "否"}}
											</el-col>
										</el-row>
											
										<el-row >
											
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >3</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												导向滚轮能否顺利转动，踏面磨损情况
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_3==1? "是" : "否"}}
											</el-col>
										</el-row>
										
										<el-row >
											
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >4</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												清槽板、开合机构/复位弹簧是否完好，是否有零件缺失，如夹紧块
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_4==1? "是" : "否"}}
											</el-col>
										</el-row>
										
										<el-row >
											
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >5</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												油缸的伸缩动作是否正常，夹钳转动是否灵活
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_5==1? "是" : "否"}}
											</el-col>
										</el-row>
										
										<el-row >
											
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >6</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												锚定机构是否正常，如销轴是否灵活，限位开关、自动锚定是否正常等
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_6==1? "是" : "否"}}
											</el-col>
										</el-row>
										
										<el-row >
											
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >7</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												线缆连接是否松动，传感器是否正常，电控柜各指示灯是否正常
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_7==1? "是" : "否"}}
											</el-col>
										</el-row>
										
										<el-row >
											
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >8</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												液压站的油位，油缸以及液压回路是否存在漏油情况，油缸保压是否正常
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_8==1? "是" : "否"}}
											</el-col>
										</el-row>
										
										<el-row >
											
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >9</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												轨道及轨道沟槽是否满足使用要求
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_9==1? "是" : "否"}}
											</el-col>
										</el-row>
										
										<el-row >
											
											<el-col :span="4" class="phone_data_type_3_expand_item_2" >10</el-col>
											<el-col :span="14" class="phone_data_type_3_expand_item">
												是否定期加注润滑油脂(每个月一次)
											</el-col>
											<el-col :span="6" class="phone_data_type_3_expand_item_2">
												{{props.row.value_10==1? "是" : "否"}}
											</el-col>
										</el-row>
										
										<el-row >
											<el-col :span="8" class="phone_data_type_3_expand_item_2" >提交信息：</el-col>
											<el-col :span="16" class="phone_data_type_3_expand_item">
												{{props.row.text}}
											</el-col>
										</el-row>
										
										</template>
								    </el-table-column>
								    <el-table-column label="维修上传时间" prop="submit_time" />
								    <el-table-column label="设备id" prop="device_id" />
									<el-table-column label="提交人" prop="user" />
								  </el-table>
							</div>
							
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import request from "@/utils/request";
	import { ElMessage } from 'element-plus'
	import { time } from "echarts";
	export default{
		components:{
			
		},
		data() {
			return{
				tabs_index_1:"1",
				shortcuts:[
					{
						text: '半小时前',
						value: () => {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 1000 * 60 * 30)
							return [start, end]
						},
					},
					{
						text: '一小时前',
						value: () => {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 1000 * 60 * 60 )
							return [start, end]
						},
					},
					{
						text: '- 10分钟',
						value: () => {
							let that = this
							const end = this.select_type_1_time[1]
							const start = this.select_type_1_time[0]
							start.setTime(start.getTime() - 1000 * 60 * 10)
							that.select_type_1_time[0] = ""
							that.select_type_1_time[1] = ""
							return [start, end]
						},
					},
					{
						text: '+ 10分钟',
						value: () => {
							let that = this
							const end = this.select_type_1_time[1]
							const start = this.select_type_1_time[0]
							start.setTime(start.getTime() + 1000 * 60 * 10)
							that.select_type_1_time[0] = ""
							that.select_type_1_time[1] = ""
							return [start, end]
						},
					},
					{
						text: '- 1小时',
						value: () => {
							let that = this
							const end = this.select_type_1_time[1]
							const start = this.select_type_1_time[0]
							start.setTime(start.getTime() - 1000 * 60 * 60)
							that.select_type_1_time[0] = ""
							that.select_type_1_time[1] = ""
							return [start, end]
						},
					},
					{
						text: '+ 1小时',
						value: () => {
							let that = this
							const end = this.select_type_1_time[1]
							const start = this.select_type_1_time[0]
							start.setTime(start.getTime() + 1000 * 60 * 60)
							that.select_type_1_time[0] = ""
							that.select_type_1_time[1] = ""
							return [start, end]
						},
					},
				],
				shortcuts_2:[
					{
						text: '半小时前',
						value: () => {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 1000 * 60 * 30)
							return [start, end]
						},
					},
					{
						text: '一小时前',
						value: () => {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 1000 * 60 * 60 )
							return [start, end]
						},
					},
					{
						text: '- 10分钟',
						value: () => {
							let that = this
							const end = this.select_type_2_time[1]
							const start = this.select_type_2_time[0]
							start.setTime(start.getTime() - 1000 * 60 * 10)
							that.select_type_2_time[0] = ""
							that.select_type_2_time[1] = ""
							return [start, end]
						},
					},
					{
						text: '+ 10分钟',
						value: () => {
							let that = this
							const end = this.select_type_2_time[1]
							const start = this.select_type_2_time[0]
							start.setTime(start.getTime() + 1000 * 60 * 10)
							that.select_type_2_time[0] = ""
							that.select_type_2_time[1] = ""
							return [start, end]
						},
					},
					{
						text: '- 1小时',
						value: () => {
							let that = this
							const end = this.select_type_2_time[1]
							const start = this.select_type_2_time[0]
							start.setTime(start.getTime() - 1000 * 60 * 60)
							that.select_type_2_time[0] = ""
							that.select_type_2_time[1] = ""
							return [start, end]
						},
					},
					{
						text: '+ 1小时',
						value: () => {
							let that = this
							const end = this.select_type_2_time[1]
							const start = this.select_type_2_time[0]
							start.setTime(start.getTime() + 1000 * 60 * 60)
							that.select_type_2_time[0] = ""
							that.select_type_2_time[1] = ""
							return [start, end]
						},
					},
				],
				data_chistory_show_1:"",
				data_chistory_show_1_option:{
				// 图表的标题
					title:{
						text:"海侧行程模拟量数值-AIW36",
						left:"10px",
						top:"8px",
						textStyle: {    // 标题样式
						    color: '#ffffff',    //字体颜色
						    // fontSize: 14,    //字体大小
						    // fontWeight: 400,    //字体粗细
						    // fontFamily: '',    //文字字体
						    // lineHeight: '',    //字体行高
						    // align:'center',//文字水平对齐方式（left/right）
						    // verticalAlign:'middle',//文字垂直对齐方式（top/bottom）
						  },
					},
					grid:{
						left: "70px",
					},
					toolbox: this.$GLOBAL.public_toolbox,
					// 图表的提示
					tooltip:this.$GLOBAL.public_tooltip,
					// 图例
					legend:{data:[""]},
					// x轴线
					dataZoom: this.$GLOBAL.public_dataZoom,
					xAxis:{
						show:false,
						axisTick: {
							// show: false,  //隐藏刻度线
						},
						data:[
							],
						axisLine: {
						    lineStyle: {
					            color: "rgb(255, 255, 255)",
				            }
				        },
						axisLabel: {
						    color: "rgb(255, 255, 255)", // y 轴文字颜色
						    formatter(v){
								return v.slice(11,16)
							},
							// 间隔 显示
							interval:3,
							fontSize: 14,  
							// rotate: 40,//倾斜度数

						},
					},
					// y轴线
					yAxis:this.$GLOBAL.public_yAxis,
					// 设置数据
					series:[
						{
							// 数据名称
							name:"海侧行程模拟量-AIW36",
							// 类型为柱状图
							type:"line",
							// 数据data
							data:[
								],
							color:"#00ff7f",
							label: { //鼠标放上去显示数值
							    show: true,
								color: '#ffffff',
								fontSize: 13,  
							    fontWeight: 'bold',
							    offset: [5, -3],
							    align: 'center',
								verticalAlign: 'middle',								  
							},
						},
					],
					// 每个执行延迟的时候（idx就是下标，随着下标的增大延迟会长）
					animationDelay: function () {
					    // 越往后的数据延迟越大
						// return idx * 100;
					},
					animationDuration:function(){
						// 每小格动画的时候
						// return idx*100;
					},				
				},
				data_chistory_show_2_option :{
				// 图表的标题
					title:{
						text:"陆侧行程模拟量数值-AIW38",
						left:"10px",
						top:"8px",
						textStyle: {    // 标题样式
						    color: '#ffffff',    //字体颜色
						    // fontSize: 14,    //字体大小
						    // fontWeight: 400,    //字体粗细
						    // fontFamily: '',    //文字字体
						    // lineHeight: '',    //字体行高
						    // align:'center',//文字水平对齐方式（left/right）
						    // verticalAlign:'middle',//文字垂直对齐方式（top/bottom）
						  },
					},
					toolbox: this.$GLOBAL.public_toolbox,
					// 图表的提示
					tooltip:this.$GLOBAL.public_tooltip,
					// 图例
					legend:{data:[""]},
					// x轴线
					dataZoom: this.$GLOBAL.public_dataZoom,
					xAxis:{
						show:false,
						axisTick: {
							// show: false,  //隐藏刻度线
						},
						data:[
							],
						axisLine: {
						    lineStyle: {
					            color: "rgb(255, 255, 255)",
				            }
				        },
						axisLabel: {
						    color: "rgb(255, 255, 255)", // y 轴文字颜色
						    formatter(v){
								return v.slice(11,16)
							},
							// 间隔 显示
							interval:3,
							fontSize: 14,  
							// rotate: 40,//倾斜度数

						},
					},
					// y轴线
					yAxis:this.$GLOBAL.public_yAxis,
					// 设置数据
					series:[
						{
							// 数据名称
							name:"陆侧行程模拟量数值-AIW38",
							// 类型为柱状图
							type:"line",
							// 数据data
							data:[
								],
							color:"#00ff7f",
							label: { //鼠标放上去显示数值
							    show: true,
								color: '#ffffff',
								fontSize: 13,  
							    fontWeight: 'bold',
							    offset: [5, -3],
							    align: 'center',
								verticalAlign: 'middle',								  
							},
						},
					],
					animationDelay: function () {
					},
					animationDuration:function(){
					},
				},
				data_chistory_show_3_option :{
				// 图表的标题
					title:{
						text:"海侧行程模拟量数值-AIW52",
						left:"10px",
						top:"8px",
						textStyle: {    // 标题样式
						    color: '#ffffff',    //字体颜色
						    // fontSize: 14,    //字体大小
						    // fontWeight: 400,    //字体粗细
						    // fontFamily: '',    //文字字体
						    // lineHeight: '',    //字体行高
						    // align:'center',//文字水平对齐方式（left/right）
						    // verticalAlign:'middle',//文字垂直对齐方式（top/bottom）
						  },
					},
					toolbox: this.$GLOBAL.public_toolbox,
					// 图表的提示
					tooltip:this.$GLOBAL.public_tooltip,
					// 图例
					legend:{data:[""]},
					// x轴线
					dataZoom: this.$GLOBAL.public_dataZoom,
					xAxis:{
						show:false,
						axisTick: {
							// show: false,  //隐藏刻度线
						},
						data:[
							],
						axisLine: {
						    lineStyle: {
					            color: "rgb(255, 255, 255)",
				            }
				        },
						axisLabel: {
						    color: "rgb(255, 255, 255)", // y 轴文字颜色
						    formatter(v){
								return v.slice(11,16)
							},
							// 间隔 显示
							interval:3,
							fontSize: 14,  
							// rotate: 40,//倾斜度数
				
						},
					},
					// y轴线
					yAxis:this.$GLOBAL.public_yAxis,
					// 设置数据
					series:[
						{
							// 数据名称
							name:"海侧行程模拟量数值-AIW52",
							// 类型为柱状图
							type:"line",
							// 数据data
							data:[
								],
							color:"#00ff7f",
							label: { //鼠标放上去显示数值
							    show: true,
								color: '#ffffff',
								fontSize: 13,  
							    fontWeight: 'bold',
							    offset: [5, -3],
							    align: 'center',
								verticalAlign: 'middle',								  
							},
						},
					],
					animationDelay:function () {
					},
					animationDuration:function () {
					},
				},
				data_chistory_show_4_option :{
				// 图表的标题
					title:{
						text:"陆侧行程模拟量数值-AIW54",
						left:"10px",
						top:"8px",
						textStyle: {    // 标题样式
						    color: '#ffffff',    //字体颜色
						    // fontSize: 14,    //字体大小
						    // fontWeight: 400,    //字体粗细
						    // fontFamily: '',    //文字字体
						    // lineHeight: '',    //字体行高
						    // align:'center',//文字水平对齐方式（left/right）
						    // verticalAlign:'middle',//文字垂直对齐方式（top/bottom）
						  },
					},
					toolbox: this.$GLOBAL.public_toolbox,
					// 图表的提示
					tooltip:this.$GLOBAL.public_tooltip,
					// 图例
					legend:{data:[""]},
					// x轴线
					dataZoom: this.$GLOBAL.public_dataZoom,
					xAxis:{
						show:false,
						axisTick: {
							// show: false,  //隐藏刻度线
						},
						data:[
							],
						axisLine: {
						    lineStyle: {
					            color: "rgb(255, 255, 255)",
				            }
				        },
						axisLabel: {
						    color: "rgb(255, 255, 255)", // y 轴文字颜色
						    formatter(v){
								return v.slice(11,16)
							},
							// 间隔 显示
							interval:3,
							fontSize: 14,  
							// rotate: 40,//倾斜度数
						},
					},
					// y轴线
					yAxis:this.$GLOBAL.public_yAxis,
					// 设置数据
					series:[
						{
							// 数据名称
							name:"陆侧行程模拟量数值-AIW54",
							// 类型为柱状图
							type:"line",
							// 数据data
							data:[
								],
							color:"#00ff7f",
							label: { //鼠标放上去显示数值
							    show: true,
								color: '#ffffff',
								fontSize: 13,  
							    fontWeight: 'bold',
							    offset: [5, -3],
							    align: 'center',
								verticalAlign: 'middle',								  
							},
						},
					],
					animationDelay: function () {
					},
					animationDuration:function(){
					},
				},
				data_chistory_show_5_option :{
				// 图表的标题
					title:{
						text:"海侧行程读值-VW108",
						left:"10px",
						top:"8px",
						textStyle: {    // 标题样式
						    color: '#ffffff',    //字体颜色
						    // fontSize: 14,    //字体大小
						    // fontWeight: 400,    //字体粗细
						    // fontFamily: '',    //文字字体
						    // lineHeight: '',    //字体行高
						    // align:'center',//文字水平对齐方式（left/right）
						    // verticalAlign:'middle',//文字垂直对齐方式（top/bottom）
						  },
					},
					toolbox: this.$GLOBAL.public_toolbox,
					// 图表的提示
					tooltip:this.$GLOBAL.public_tooltip,
					// 图例
					legend:{data:[""]},
					// x轴线
					dataZoom: this.$GLOBAL.public_dataZoom,
					xAxis:{
						show:false,
						axisTick: {
							// show: false,  //隐藏刻度线
						},
						data:[
							],
						axisLine: {
						    lineStyle: {
					            color: "rgb(255, 255, 255)",
				            }
				        },
						axisLabel: {
						    color: "rgb(255, 255, 255)", // y 轴文字颜色
						    formatter(v){
								return v.slice(11,16)
							},
							// 间隔 显示
							interval:3,
							fontSize: 14,  
							// rotate: 40,//倾斜度数
				
						},
					},
					// y轴线
					yAxis:this.$GLOBAL.public_yAxis,
					// 设置数据
					series:[
						{
							// 数据名称
							name:"海侧行程读值-VW108",
							// 类型为柱状图
							type:"line",
							// 数据data
							data:[
								],
							color:"#00ff7f",
							label: { //鼠标放上去显示数值
							    show: true,
								color: '#ffffff',
								fontSize: 13,  
							    fontWeight: 'bold',
							    offset: [5, -3],
							    align: 'center',
								verticalAlign: 'middle',								  
							},
						},
					],
					animationDelay: function () {
					},
					animationDuration:function(){
					},
				},
				data_chistory_show_6_option :{
				// 图表的标题
					title:{
						text:"陆侧行程读值-VW124",
						left:"10px",
						top:"8px",
						textStyle: {    // 标题样式
						    color: '#ffffff',    //字体颜色
						    // fontSize: 14,    //字体大小
						    // fontWeight: 400,    //字体粗细
						    // fontFamily: '',    //文字字体
						    // lineHeight: '',    //字体行高
						    // align:'center',//文字水平对齐方式（left/right）
						    // verticalAlign:'middle',//文字垂直对齐方式（top/bottom）
						  },
					},
					toolbox: this.$GLOBAL.public_toolbox,
					// 图表的提示
					tooltip:this.$GLOBAL.public_tooltip,
					// 图例
					legend:{data:[""]},
					// x轴线
					dataZoom: this.$GLOBAL.public_dataZoom,
					xAxis:{
						show:false,
						axisTick: {
							// show: false,  //隐藏刻度线
						},
						data:[
							],
						axisLine: {
						    lineStyle: {
					            color: "rgb(255, 255, 255)",
				            }
				        },
						axisLabel: {
						    color: "rgb(255, 255, 255)", // y 轴文字颜色
						    formatter(v){
								return v.slice(11,16)
							},
							// 间隔 显示
							interval:3,
							fontSize: 14,  
							// rotate: 40,//倾斜度数
				
						},
					},
					// y轴线
					yAxis:this.$GLOBAL.public_yAxis,
					// 设置数据
					series:[
						{
							// 数据名称
							name:"陆侧行程读值-VW124",
							// 类型为柱状图
							type:"line",
							// 数据data
							data:[
								],
							color:"#00ff7f",
							label: { //鼠标放上去显示数值
							    show: true,
								color: '#ffffff',
								fontSize: 13,  
							    fontWeight: 'bold',
							    offset: [5, -3],
							    align: 'center',
								verticalAlign: 'middle',								  
							},
						},
					],
					animationDelay: function () {
					},
					animationDuration:function(){
					},
				},
				data_chistory_show_7_option :{
				// 图表的标题
					title:{
						text:"-----------------",
						left:"10px",
						top:"8px",
						textStyle: {    // 标题样式
						    color: '#ffffff',    //字体颜色
						  },
					},
					toolbox: {
						show: true,
						itemSize: 18,  //icon大小
						right: '10px',  
						feature: {						  
							// 数据视图只读
							dataView: { 
								readOnly: true ,
								backgroundColor: '#55aaff',
								show: true,
								iconStyle:{
									// color: "#55ffff", 
									borderColor: "#ffffff",
									borderWidth: 1.2,
									opacity: 1  //图形透明度
								},
								// hover
								emphasis: {
									iconStyle:{
										borderColor: "#55ffff",
										borderWidth: 1.5,
										opacity: 1  ,//图形透明度
										// textPosition: 'top',  //文本位置，属性值：left/right/top/bottom
								        // textAlign: 'left',  //文本对齐方式，属性值：left/center/right
										Size:20
									}
								},
							},
							// 魔法类型
							// magicType: { type: ['line', 'bar'] },
							//  重置
							restore: {
								backgroundColor: '#55aaff',
								show: true,
								iconStyle:{
									// color: "#55ffff", 
									borderColor: "#ffffff",
									borderWidth: 1.2,
									opacity: 1  //图形透明度
								},
								// hover
								emphasis: {
									iconStyle:{
										borderColor: "#55ffff",
										borderWidth: 1.5,
										opacity: 1  ,//图形透明度
										// textPosition: 'top',  //文本位置，属性值：left/right/top/bottom
								        // textAlign: 'left',  //文本对齐方式，属性值：left/center/right
										Size:20
									}
								},
							},
							  
					
						},
					},
					// 图表的提示
					tooltip:this.$GLOBAL.public_tooltip,
					// 图例
					legend:{data:[""]},
					dataZoom: [{
					    type: 'slider', // inside ,slider 
					    throttle: '50', //设置触发视图刷新的频率。单位为毫秒（ms）。
					    minValueSpan: 6, //用于限制窗口大小的最小值,在类目轴上可以设置为 5 表示 5 个类目
					    start: 0, //数据窗口范围的起始百分比 范围是：0 ~ 100。表示 0% ~ 100%。
					    end: 5, //数据窗口范围的结束百分比。范围是：0 ~ 100。
					    zoomLock: false, //如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
						
						// backgroundColor: 'rgba(255, 255, 255, 1)',//组件的背景颜色
						selectedDataBackground: {//选中部分数据阴影的样式
							lineStyle: {
								color: 'rgba(0, 255, 127, 1.0)',
							},//选中部分阴影的线条样式
							areaStyle: {
								color: 'rgba(0, 255, 127, 1.0)',
							},//选中部分阴影的填充样式
						},
						fillerColor: 'rgba(170, 0, 0, 0.2)',//选中范围的填充颜色
						borderColor: 'rgba(85, 255, 255, 1.0)',//边框颜色。
						moveHandleSize: 10,//移动手柄的尺寸高度
						textStyle: {
							color: 'rgba(0, 255, 127, 1.0)',
							fontSize: 17,  
							fontWeight: 700,
							position: "absolute",
							zIndex: 99,
							overflow: "auto",
						},
						labelFormatter(val,val2){
							// console.log(val2)
							return val2.slice(5,19)
						},
						// left:"20px"
						bottom:"15px",
					}],
					// x轴线
					xAxis:{
						show:true,
						axisTick: {
							// show: false,  //隐藏刻度线
						},
						data:[
							],
						axisLine: {
						    lineStyle: {
					            color: "rgb(255, 255, 255)",
								
				            }
				        },
						axisLabel: {
						    color: "rgb(255, 255, 255)", // y 轴文字颜色
						    formatter(v){
								return v.slice(5,16)
							},
							// 间隔 显示
							interval:3,
							fontSize: 14,  
							// rotate: 40,//倾斜度数
				
						},
					},
					// y轴线
					yAxis:this.$GLOBAL.public_yAxis,
					// 设置数据
					series:[
						{
							// 数据名称
							name:"data7",
							// 类型为柱状图
							type:"line",
							// 数据data
							data:[
								],
							color:"#00ff7f",
							label: { //鼠标放上去显示数值
							    show: true,
								color: '#ffffff',
								fontSize: 13,  
							    fontWeight: 'bold',
							    offset: [5, -3],
							    align: 'center',
								verticalAlign: 'middle',	
								formatter(v){
									return v.data==1?"是":"否"
								},
							},
						},
					],
					animationDelay: function () {
					},
					animationDuration:function(){
					},
				},
				history_device_data_type_3:[],
				show_1_default_isShow:true,
				show_2_default_isShow:true,
				show_3_default_isShow:true,
				show_4_default_isShow:true,
				show_5_default_isShow:true,
				show_6_default_isShow:true,
				data_2_show_default_isShow:false,
				history_data_2_isShow:false,
				select_options:[],
				select_type_1_device_id:"none",
				select_type_2_device_id:"none",
				select_type_3_device_id:"none",
				select_type_1_time:"",
				select_type_2_time:"",
				select_type_3_time:"",
				select_type_2_id:"",
				device_data2_list:[],
				device_data2_name_select:"",
				return_btn_isShow:false,
				// 单元格样式
				cellState:function(row){
					
					let style ={
						textAlign: "center",
						borderBottom:"1px solid #195b69",
						backgroundColor: '#01313d',
						fontSize: "15px",
					}
					if(row['columnIndex']==10){
						style.backgroundColor='rgba(0, 75, 113, 0.0)'
						// style.borderRadius='15px'
					}
					return style
					
				},
				//标题单元格样式
				headerCellState:function(row, rowIndex){
					let style ={
						backgroundColor: '#195b69',
						color: "#55aaff",
						textAlign: "center",
						fontSize: "15px",
					}
					return style
				},
			}
		},
		methods:{
			return_btn_(){
				this.$router.push("/MGT/Private_Device_1")//跳转
			},
			// 页签切换事件
			tab_click_handle(activeName, oldActiveName){
				// console.log("activeName:"+activeName+" , oldActiveName:"+oldActiveName)
				// 获取全部设备信息
				this.get_all_device_info()
				// 获取当前用户配置信息
				// this.get_init_settings_data()
				// setTimeout(() => {
				// 	if(activeName==0){
				// 		// 初始化时间选择器的时间
				// 		this.init_now_time()
				// 		// 判断图表对象是否已经初始化
				// 		if(!this.$echarts.getInstanceByDom(document.getElementById("data_chistory_show_1"))){
				// 			this.$GLOBAL.data_chistory_show_1 = this.$echarts.init(document.getElementById("data_chistory_show_1"))
				// 			this.$GLOBAL.data_chistory_show_2 = this.$echarts.init(document.getElementById("data_chistory_show_2"))
				// 			this.$GLOBAL.data_chistory_show_3 = this.$echarts.init(document.getElementById("data_chistory_show_3"))
				// 			this.$GLOBAL.data_chistory_show_4 = this.$echarts.init(document.getElementById("data_chistory_show_4"))
				// 			this.$GLOBAL.data_chistory_show_5 = this.$echarts.init(document.getElementById("data_chistory_show_5"))
				// 			this.$GLOBAL.data_chistory_show_6 = this.$echarts.init(document.getElementById("data_chistory_show_6"))
				// 		}
				// 		// 从服务器获取图表数据
				// 		this.get_device_history_type1_data_by_time()
				// 	}else if(activeName==1){
				// 		// 初始化时间
				// 		let date = new Date()
				// 		let time_key = date.getTime() - 60 * 60 * 1000
				// 		let old_date = new Date(time_key)							
				// 		this.select_type_2_time=[old_date,date]
				// 		// 获取所有类型2数据信息
				// 		this.get_all_type2_davice_info()
				// 	}else if(activeName==2){
				// 		// 获取数据
				// 		this.get_device_history_type3_data()
				// 	}
				// }, 600)
			},
			// 监听窗口变化
			window_resize(){
				if(this.tabs_index_1=="0"){
					this.$GLOBAL.data_chistory_show_1.dispose()
					this.$GLOBAL.data_chistory_show_1 = this.$echarts.init(document.getElementById("data_chistory_show_1"))
					this.$GLOBAL.data_chistory_show_1.setOption(this.data_chistory_show_1_option)
					// this.data_chistory_show_1.resize()
					// console.log(this.$GLOBAL.data_chistory_show_1)
					this.$GLOBAL.data_chistory_show_2.dispose()
					this.$GLOBAL.data_chistory_show_2 = this.$echarts.init(document.getElementById("data_chistory_show_2"))
					this.$GLOBAL.data_chistory_show_2.setOption(this.data_chistory_show_2_option)
					
					this.$GLOBAL.data_chistory_show_3.dispose()
					this.$GLOBAL.data_chistory_show_3 = this.$echarts.init(document.getElementById("data_chistory_show_3"))
					this.$GLOBAL.data_chistory_show_3.setOption(this.data_chistory_show_3_option)
					
					this.$GLOBAL.data_chistory_show_4.dispose()
					this.$GLOBAL.data_chistory_show_4 = this.$echarts.init(document.getElementById("data_chistory_show_4"))
					this.$GLOBAL.data_chistory_show_4.setOption(this.data_chistory_show_4_option)
					
					this.$GLOBAL.data_chistory_show_5.dispose()
					this.$GLOBAL.data_chistory_show_5 = this.$echarts.init(document.getElementById("data_chistory_show_5"))
					this.$GLOBAL.data_chistory_show_5.setOption(this.data_chistory_show_5_option)
					
					this.$GLOBAL.data_chistory_show_6.dispose()
					this.$GLOBAL.data_chistory_show_6 = this.$echarts.init(document.getElementById("data_chistory_show_6"))
					this.$GLOBAL.data_chistory_show_6.setOption(this.data_chistory_show_6_option)
				}else if(this.tabs_index_1=="1"){
					
				}
			},
			// 获取设备信息并初始化下拉框控件数据
			get_all_device_info(){
				request.post(this.$GLOBAL.flask_web_local_url+'/get_private_device_info',{"username":sessionStorage.getItem('user')}).then(res => {
					this.select_options = []
					for(let i in res){
						let info_data = {
							'value':res[i]['device_id'],
							'label':res[i]['device_id']+" -- "+res[i]['address'],
							'region':res[i]['region'],
							'address':res[i]['address'],
						}
						this.select_options.push(info_data)
					}
					// 通过服务器获取默认选择的区域设备id  *****************************
					this.get_init_settings_data()
				})
				// request.get(this.$GLOBAL.flask_web_local_url+'/get_device_info').then(res => {
				// 	// console.log(res)
				// 	for(let i in res){
				// 		let info_data = {
				// 			'value':res[i]['device_id'],
				// 			'label':res[i]['device_id']+" -- "+res[i]['address'],
				// 			'region':res[i]['region'],
				// 			'address':res[i]['address'],
				// 		}
				// 		this.select_options[i]=info_data
				// 		// console.log(i)
				// 	}
				// })
			},
			// 获取用户配置信息
			get_init_settings_data(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_init_settings?username='+sessionStorage.getItem('user')).then(res => {
					this.select_type_1_device_id = this.select_options[0]['value']
					this.select_type_2_device_id = this.select_options[0]['value']
					this.select_type_3_device_id = this.select_options[0]['value']
					for(let i in this.select_options){
						if(this.select_options[i]['value']==res['init_device_id']){
							this.select_type_1_device_id = res['init_device_id']
							this.select_type_2_device_id = res['init_device_id']
							this.select_type_3_device_id = res['init_device_id']
						}
					}
					let activeName = this.tabs_index_1
					if(activeName==0){
						// 初始化时间选择器的时间
						this.init_now_time()
						// 判断图表对象是否已经初始化
						if(!this.$echarts.getInstanceByDom(document.getElementById("data_chistory_show_1"))){
							this.$GLOBAL.data_chistory_show_1 = this.$echarts.init(document.getElementById("data_chistory_show_1"))
							this.$GLOBAL.data_chistory_show_2 = this.$echarts.init(document.getElementById("data_chistory_show_2"))
							this.$GLOBAL.data_chistory_show_3 = this.$echarts.init(document.getElementById("data_chistory_show_3"))
							this.$GLOBAL.data_chistory_show_4 = this.$echarts.init(document.getElementById("data_chistory_show_4"))
							this.$GLOBAL.data_chistory_show_5 = this.$echarts.init(document.getElementById("data_chistory_show_5"))
							this.$GLOBAL.data_chistory_show_6 = this.$echarts.init(document.getElementById("data_chistory_show_6"))
						}
						// 从服务器获取图表数据
						this.get_device_history_type1_data_by_time()
					}else if(activeName==1){
						// 初始化时间
						let date = new Date()
						let time_key = date.getTime() - 10 * 60 * 1000
						let old_date = new Date(time_key)							
						this.select_type_2_time=[old_date,date]
						// 获取所有类型2数据信息
						this.get_all_type2_davice_info()
					}else if(activeName==2){
						let date = new Date()
						let time_key = date.getTime() - 7 * 24 * 60 * 60 * 1000
						let old_date = new Date(time_key)							
						this.select_type_3_time=[old_date,date]
						// 获取数据
						this.get_device_history_type3_data()
					}
				})
				
			},
			// get_init_settings_data(){
			// 	request.get(this.$GLOBAL.flask_web_local_url+'/get_init_settings?username='+sessionStorage.getItem('user')).then(res => {
			// 		this.select_type_1_device_id = res['init_device_id']
			// 		this.select_type_3_device_id = res['init_device_id']
			// 	})
			// },
			// 获取当前设备下类型2的数据信息
			get_all_type2_davice_info(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_device_data_type_2?region_id='+this.select_type_2_device_id).then(res => 	{
					let obj = res['device_data_type_2']
					this.device_data2_list = []
					for(let i in obj){
						// 内容筛选
						if(obj[i].content.indexOf(this.device_data2_name_select)!=-1||this.device_data2_name_select==""){
							this.device_data2_list.push(obj[i])
						}
					}
					// console.log(this.device_data2_list)
				}).catch(function(){
					ElMessage.error('设备状态数据加载失败 ！！！')
				})	
			},
			// 关闭页签二的图表显示窗口
			close_data2_show(){
				this.history_data_2_isShow = false
			},
			// 点击选择当前设备下的一个类型2的具体数据，并在图表窗口进行绘制
			choose_one_type2_device_data(type_data){
				// 图表加载动画
				this.data_2_show_default_isShow=true
				// 记录图表窗口展示的数据type_id
				let type_id = type_data.type_id
				this.select_type_2_id = type_id
				// 显示图表窗口
				if(!this.history_data_2_isShow){
					this.history_data_2_isShow = true
				}		
				// // 计算时间差
				// let timeDiff  =  this.select_type_2_time[1].getTime() - this.select_type_2_time[0].getTime()
				// let hours = Math.floor(timeDiff / (1000 * 60 * 60));
				// console.log(hours)
								
				// 解析时间筛选条件
				let begin_time = this.select_type_2_time[0].getFullYear()+"-"
							+(this.select_type_2_time[0].getMonth()+1)+"-"
							+this.select_type_2_time[0].getDate()+" "
							+this.select_type_2_time[0].toTimeString().substring(0,8);
				let end_time = this.select_type_2_time[1].getFullYear()+"-"
							+(this.select_type_2_time[1].getMonth()+1)+"-"
							+this.select_type_2_time[1].getDate()+" "
							+this.select_type_2_time[1].toTimeString().substring(0,8);
				let msg_data ={
					"device_id":this.select_type_2_device_id,
					"type_id":type_id,
					"begin_time":begin_time,
					"end_time":end_time
				}
				setTimeout(() => {
					// 延时初始化，避免资源未加载
					if(!this.$echarts.getInstanceByDom(document.getElementById("data_chistory_show_7"))){
						this.$GLOBAL.data_chistory_show_7 = this.$echarts.init(document.getElementById("data_chistory_show_7"))
					}
					request.post(this.$GLOBAL.flask_web_local_url+'/select_device_history_data_type2',msg_data).then(res =>{
						let data = []
						let x_data = []
						for(let i in res){
							// 解析x轴数据和折线数据
							x_data.push(res[i]['update_time'])
							data.push(res[i]['value'])
						}
						// 更新图表数据
						this.data_chistory_show_7_option['xAxis']['data']= x_data
						this.data_chistory_show_7_option['series'][0]['data'] = data
						this.data_chistory_show_7_option['title']['text'] = type_data.content
						this.data_chistory_show_7_option['series'][0]['name'] = type_data.content
						this.$GLOBAL.data_chistory_show_7.setOption(this.data_chistory_show_7_option)
						// 加载动画显示/隐藏
						if(data.length>0){
							this.data_2_show_default_isShow=false
						}else{
							this.data_2_show_default_isShow=true
						}
					})
				},200)
				
			},
			// 页签1 筛选条件变更
			select_type_1_time_onChange(){
				// console.log(this.select_type_1_time)			
				this.show_1_default_isShow=true
				this.show_2_default_isShow=true
				this.show_3_default_isShow=true
				this.show_4_default_isShow=true
				this.show_5_default_isShow=true
				this.show_6_default_isShow=true
				this.get_device_history_type1_data_by_time()
			},
			// 页签2 筛选条件变更
			select_type_2_time_onChange(){
				// 判断当前数据图表窗口是否激活
				if(this.history_data_2_isShow){
					for(let i in this.device_data2_list){
						// 判断当前激活的数据图表窗口对应的type_id
						if(this.device_data2_list[i]['type_id']==this.select_type_2_id){
							// 调用服务器接口更新图表
							this.choose_one_type2_device_data(this.device_data2_list[i])
						}
					}
				}				
			},
			// 页签3 筛选条件变更
			select_type_3_time_onChange(){
				this.get_device_history_type3_data()
			},
			// 获取当前时间进行初始化
			init_now_time(){
				let date = new Date()							
				// 前半小时
				let time_key = date.getTime() - 10 * 60 * 1000
				let old_date = new Date(time_key)							
				this.select_type_1_time=[old_date,date]
				// console.log(this.select_type_1_time)
			},
			// 页签1 从数据获取图表数据并重绘图表
			get_device_history_type1_data_by_time(){
				// let that = this
				// 解析时间筛选条件
				let begin_time = this.select_type_1_time[0].getFullYear()+"-"
							+(this.select_type_1_time[0].getMonth()+1)+"-"
							+this.select_type_1_time[0].getDate()+" "
							+this.select_type_1_time[0].toTimeString().substring(0,8);
				let end_time = this.select_type_1_time[1].getFullYear()+"-"
							+(this.select_type_1_time[1].getMonth()+1)+"-"
							+this.select_type_1_time[1].getDate()+" "
							+this.select_type_1_time[1].toTimeString().substring(0,8);
				// 计算时间差
				let timeDiff  =  this.select_type_1_time[1].getTime() - this.select_type_1_time[0].getTime()
				// let timeDiff_hour = Math.floor(timeDiff / (1000 * 60 *60));
				let timeDiff_hour = timeDiff / (1000 * 60 *60);
				let interval = 10
				// 根据时间范围设置查询跳跃间隔
				if(timeDiff_hour<0.5){
					interval = 1
				}else if(timeDiff_hour<1){
					interval = 5
				}else if(timeDiff_hour<24){
					interval = 10
				}else{
					interval = 60
				}
				// console.log(timeDiff_hour)
				
				let msg_data ={
					"device_id":this.select_type_1_device_id,
					// "type_id":type_id,
					"begin_time":begin_time,
					"end_time":end_time,
					"interval":interval
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/select_device_history_data_type1',msg_data).then(res =>{
					
					let x_data_1 =[]
					let data_1 = []
					let x_data_2 =[]
					let data_2 = []
					let x_data_3 =[]
					let data_3 = []
					let x_data_4 =[]
					let data_4 = []
					let x_data_5 =[]
					let data_5 = []
					let x_data_6 =[]
					let data_6 = []
					// 将数据进行解析、归类
					for(let i in res){
						if(res[i]['type_id']=="1_1"){
							x_data_1.push(res[i]['update_time'])
							data_1.push(res[i]['value'])
						}
						if(res[i]['type_id']=="1_2"){
							x_data_2.push(res[i]['update_time'])
							data_2.push(res[i]['value'])
						}
						if(res[i]['type_id']=="1_3"){
							x_data_3.push(res[i]['update_time'])
							data_3.push(res[i]['value'])
						}
						if(res[i]['type_id']=="1_4"){
							x_data_4.push(res[i]['update_time'])
							data_4.push(res[i]['value'])
						}
						if(res[i]['type_id']=="1_5"){
							x_data_5.push(res[i]['update_time'])
							data_5.push(res[i]['value'])
						}
						if(res[i]['type_id']=="1_6"){
							x_data_6.push(res[i]['update_time'])
							data_6.push(res[i]['value'])
						}
					}
					// 更新图表数据
					this.data_chistory_show_1_option['xAxis']['data']= x_data_1
					this.data_chistory_show_1_option['series'][0]['data'] = data_1
					this.data_chistory_show_2_option['xAxis']['data']= x_data_2
					this.data_chistory_show_2_option['series'][0]['data'] = data_2
					this.data_chistory_show_3_option['xAxis']['data']= x_data_3
					this.data_chistory_show_3_option['series'][0]['data'] = data_3
					this.data_chistory_show_4_option['xAxis']['data']= x_data_4
					this.data_chistory_show_4_option['series'][0]['data'] = data_4
					this.data_chistory_show_5_option['xAxis']['data']= x_data_5
					this.data_chistory_show_5_option['series'][0]['data'] = data_5
					this.data_chistory_show_6_option['xAxis']['data']= x_data_6
					this.data_chistory_show_6_option['series'][0]['data'] = data_6
					
					this.$GLOBAL.data_chistory_show_1.setOption(this.data_chistory_show_1_option)
					this.$GLOBAL.data_chistory_show_2.setOption(this.data_chistory_show_2_option)
					this.$GLOBAL.data_chistory_show_3.setOption(this.data_chistory_show_3_option)
					this.$GLOBAL.data_chistory_show_4.setOption(this.data_chistory_show_4_option)
					this.$GLOBAL.data_chistory_show_5.setOption(this.data_chistory_show_5_option)
					this.$GLOBAL.data_chistory_show_6.setOption(this.data_chistory_show_6_option)
					
					if(data_1.length>0){
						this.show_1_default_isShow=false
					}else{
						this.show_1_default_isShow=true
					}
					if(data_2.length>0){
						this.show_2_default_isShow=false
					}else{
						this.show_2_default_isShow=true
					}
					if(data_3.length>0){
						this.show_3_default_isShow=false
					}else{
						this.show_3_default_isShow=true
					}
					if(data_4.length>0){
						this.show_4_default_isShow=false
					}else{
						this.show_4_default_isShow=true
					}
					if(data_5.length>0){
						this.show_5_default_isShow=false
					}else{
						this.show_5_default_isShow=true
					}
					if(data_6.length>0){
						this.show_6_default_isShow=false
					}else{
						this.show_6_default_isShow=true
					}
			
				}).catch(function(e){
					console.error(e)
					ElMessage.error('数据获取超时，请重试！！！')
				})
			},
			// 获取页签3的列表数据
			get_device_history_type3_data(){
				let msg_data ={
					"device_id":this.select_type_3_device_id,
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/select_device_history_data_type3',msg_data).then(res =>{
					this.history_device_data_type_3=[]
					for(let i in res){
						let temt_data = res[i]['notes']?res[i]['notes'].split("|"):""
						let user_data = ""
						let text_data = ""
						if(temt_data!=""){
							user_data = temt_data[0]
							text_data = temt_data[1]
						}
						if(this.select_type_3_time){
							//解析时间筛选条件
							let start_year = this.select_type_3_time[0].toString().substring(11,15)
							let start_month = this.select_type_3_time[0].toString().substring(4,7)
							start_month=this.transformation_month(start_month)
							let start_day = this.select_type_3_time[0].toString().substring(8,10)
							
							let end_year = this.select_type_3_time[1].toString().substring(11,15)
							let end_month = this.select_type_3_time[1].toString().substring(4,7)
							end_month=this.transformation_month(end_month)
							let end_day = this.select_type_3_time[1].toString().substring(8,10)
							
							//解析数据中的时间参数
							let begin_times = res[i].submit_time.split(" ")
							let begin_year = begin_times[0].split("-")[0]
							let begin_month = begin_times[0].split("-")[1]
							let begin_day = begin_times[0].split("-")[2]
							//判断时间
							if(parseInt(begin_year)>=parseInt(start_year)&&parseInt(begin_year)<=parseInt(end_year)){
								if(parseInt(begin_month)>=parseInt(start_month)&&parseInt(begin_month)<=parseInt(end_month)){
									if(parseInt(begin_day)>=parseInt(start_day) &&parseInt(begin_day)<=parseInt(end_day) ){
										if(temt_data!=""){
											res[i]['user'] = user_data
											res[i]['text'] = text_data
										}
										this.history_device_data_type_3.push(res[i])
									}
								}
							}
						}else{
							if(temt_data!=""){
								res[i]['user'] = user_data
								res[i]['text'] = text_data
							}
							this.history_device_data_type_3.push(res[i])
						}
						
					}
				})
			},
			transformation_month:function(str){
			
				let month = 0
				switch(str){
					case 'Jan':month=1
							break
					case 'Feb':month=2
							break
					case 'Mar':month=3
							break
					case 'Apr':month=4
							break
					case 'May':month=5
							break
					case 'Jun':month=6
							break
					case 'Jul':month=7
							break
					case 'Aug':month=8
							break
					case 'Sept':month=9 
							break
					case 'Oct':month=10
							break
					case 'Nov':month=11
							break
					case 'Dec':month=12
							break
				}
				return month
			}
		},
		//模板渲染完成后执行，可以拿到DOM节点和数据
		mounted(){
			let that = this
	
			//窗口调整事件监听
			window.addEventListener("resize",this.window_resize);			
			let geturl = window.location.href
			let index = geturl.split('=')[1]
			if(index=="1"||index==1){
				this.tabs_index_1="0"
				this.tab_click_handle(0,0)	
				this.return_btn_isShow = true
				
			}else{
				this.tabs_index_1="1"
				// 加载图表
				this.tab_click_handle(1,1)	
				// this.tab_click_handle(0,0)	
			}			
		},
	}
		
</script>

<style>
	.phone_history_main_layout{
		width: calc(100% - 5px);
		/* margin-left: 1%; */
		margin-top: 0px;
		/* overflow: auto; */
	}
	.phone_history_data_layout{
		height: calc(100vh - 165px);		
		overflow: hidden;
	}
	.phone_history_data_layout_1{
		position: relative;
		height: calc(100% - 90px);
		overflow: auto;
	}
	.phone_history_data_layout_2{
		height: calc(100vh - 165px);
		overflow: auto;
		position: relative;
		/* height: 200px; */
		/* overflow: hidden; */
		/* background-color:rgba(255, 255, 255, 0.3); */
	}
	.phone_history_data_layout_3{
		height: calc(100vh - 165px);
		/* background-color:rgba(255, 255, 255, 0.3); */
	}
	.phone_history_data_1_show{
		margin-bottom: 5px;
		position: relative;
		z-index: 88;
		/* width: calc(33% - 20px); */
		/* min-width: 350px; */
		width: calc(100% - 10px);
		min-width: 500px;
		margin-left: 5px;
		height: 46%;
		min-height: 350px;
		/* width: 500px; */
		/* height: 300px; */
		display: inline-block;
		background-color: rgba(85, 255, 255, 0.2);
		/* background-color: rgba(0, 170, 255, 0.2); */
	}
	.history_data_layout_1_title{
		margin-top: 3px;
		margin-left: 2%;
		margin-bottom: 15px;
		width: 96%;
		height: 60px;
		background-color: #00557f;	
		background-color: rgba(19, 77, 88, 0.4);
		border-radius: 10px;
		border: 1px solid #77f7f8;
		box-shadow: 0px 0px 3px 2px #6dfaff;
	}
	.history_device_position_col{
		height: 100%;
		/* background-color: #6dfaff; */
		color: #6dfaff;
		line-height: 60px;
		font-size: 20px;
		
	}
	.phone_history_now_region_select{
		margin-top: 5px;
		/* margin-left: -100px; */
		width: 100%;
		/* width: 300px; */
	}
	.history_select_options_item{
		/* text-align: center; */
		/* background-color: rgba(85, 170, 255, 0.8) ; */
		/* color: #55ffff !important; */
	}
	.date_picker_layout{
		margin-top: 8px;
	}
	.phone_history_data_1_show_default{
		
		position: absolute;
		z-index: 99;
		margin-bottom: 15px;
		/* width: calc(33% - 20px); */
		/* min-width: 350px; */
		width: calc(100% - 10px);
		min-width: 500px;
		margin-left: 5px;
		height: 46%;
		min-height: 350px;
		/* display: inline-block; */
		background-color: rgba(0, 0, 0, 0.5);
		
	}
	.phone_history_main_layout .box {
		position: absolute;
		width: 200px;
		height: 200px;
		margin-left: calc(50% - 100px);
		margin-left: 50px;
		margin-top: 100px;           
	    border-radius: 50%;
		animation: turn 2s linear infinite;
		transform-origin: 50% 50%;
	}
	.phone_history_main_layout .box::before {
		content: "";
	    width: 180px;
	    height: 180px;
		position: relative;
	    margin-top: 6px;
	    /* margin-left: -90px; */
		display: inline-block;
		animation: turn2 2.25s linear infinite;

	 }
	.phone_history_main_layout .box::after {
		content: "";
		position: absolute;
	    width: 160px;
	    height: 160px;
	    margin-top: 16px;
	    margin-left: -174px;
		display: inline-block;
		animation: turn 2.25s linear infinite;

	}
	.box,
	.box::after,
	.box::before {
		border: 2px solid rgba(0, 0, 0, 0.0);
	    border-left: 2px solid #55aaff;
	    border-right: 2px solid #55aaff;
	    border-radius: 50%;
	}
	@keyframes turn {
	  100% {
	       transform: rotateZ(-1turn);
	   }
	}
	@keyframes turn2 {
	    100% {
	        transform: rotateZ(1turn);
	    }
	}
	@keyframes rotate {
		100% {
	        border: none;
		    border-top: 2px solid #55ffff;
	        border-bottom: 2px solid #ffffff;
		    /* transform: rotate3d(0.5, 0.5, 0.5, -360deg); */
			transform: rotate3d(0.5, 0.5, 0.5, -720deg);

		}

	}
	.box-circle,
	.box-circle1,
	.box-circle2 {
		/* border-left: 2px solid rgba(58, 170, 136, 0.0);
		border-right: 2px solid rgba(255, 255, 255, 0.9);
		border-top: 2px solid rgba(0, 0, 0, 0.9);
		border-bottom: 2px solid rgba(85, 170, 255, 0.5); */
	    opacity: .9;
        border-radius: 50%;
	    position: absolute;
        left: 47px;
	    top: 47px;
        transform-origin: 50% 50%;
	    /* transform: translate(-50%, -50%); */
	    width: 100px;
        height: 100px;
	    animation: rotate 5.5s linear infinite;

	}
	.box-circle {
		animation-delay: 0.0s;
		/* border-left: 2px solid rgba(58, 170, 136, 0); */
		/* border-right: 2px solid rgba(255, 255, 255, 0.0); */
		border: 2px solid rgba(255, 255, 255, 1);
		/* border-bottom: 2px solid rgba(85, 170, 255, 0.0); */
	}
	
	.box-circle1 {
	    animation-delay: 1s;
		border: 2px solid rgba(255, 170, 0, 1.0);
		/* border-right: 2px solid rgba(255, 255, 255, 0.0); */
		/* border-top: 2px solid rgba(0, 0, 0, 0.0); */
		/* border-bottom: 2px solid rgba(85, 170, 255, 0.0); */
	}
	
	.box-circle2 {
	    animation-delay: 1.8s;
		/* border-left: 2px solid rgba(58, 170, 136, 0.0); */
		/* border-right: 2px solid rgba(255, 255, 255, 0.0); */
		/* border-top: 2px solid rgba(0, 0, 0, 0.0	); */
		border: 2px solid rgba(85, 255, 255, 1);
	}
	.el-picker-panel__shortcut{
		background-color: rgb(0 161 167 / 80%) !important;
		color: #ffffff !important;
		margin-bottom: 6px;
	}
	.el-picker-panel__shortcut:hover{
		color: #55ffff !important;
	}
	
	.history_data_layout_2_header{
		margin-top: 3px;
		margin-left: 2%;
		margin-bottom: 15px;
		width: 96%;
		height: 60px;
		background-color: #00557f;	
		background-color: rgba(19, 77, 88, 0.4);
		border-radius: 10px;
		border: 1px solid #77f7f8;
		box-shadow: 0px 0px 3px 2px #6dfaff;
	}
	.phone_history_data_layout_2_content{	
		height: calc(98% - 110px);
		margin-left: 3%;
		border-radius: 20px;
		margin-top: 10px;
		width: 94%;
		overflow: auto;
		/* background-color: rgba(85, 255, 255, 0.4); */
	}
	.phone_history_device_data2_show1{
		position: relative;
		margin-left: 5px;
		width: 100px;
		height: 85px;
		background-color: rgba(179, 179, 179, 0.8); 
		display: inline-block;
		border-radius: 20px;
		/* margin-bottom: 5px; */
	}
	.phone_history_device_data2_show1_icon{
		margin-left: 30px;
		width: 40px;
		height: 40px;
		/* border: 1px solid #6dfaff; */
		/* background-color: #6dfaff; */
		/* display: inline-block; */
		line-height: 40px;
		float: left;
		background-image:url(../assets/device_state.png);
		background-size: 40px 40px ;
	}
	.phone_history_device_data2_show1_title{
		position: absolute;
		width: 100px;
		color: #000000;
		height: 35px;
		font-size: 16px;
		line-height: 17px;
		overflow-y: auto;
		bottom: 0px;		
		/* background-color: #ffffff; */
		
	}
	
	.history_data_layout_3_header{
		margin-top: 3px;
		margin-left: 2%;
		margin-bottom: 15px;
		width: 96%;
		height: 60px;
		background-color: #00557f;	
		background-color: rgba(19, 77, 88, 0.4);
		border-radius: 10px;
		border: 1px solid #77f7f8;
		box-shadow: 0px 0px 3px 2px #6dfaff;
	}
	.phone_history_data_layout_3_content{
		margin-top: 10px;
		width: calc(94% );
		height: calc(100% - 90px);
		margin-left: calc(3%);
		/* margin-left: 3%; */
		background-color: rgba(85, 255, 255, 0.4);
		border-radius: 20px;
		
	}
	.phone_data_type_3_expand_item{
		text-align: left;
		height: 51px;
		line-height: 50px;
		font-size: 16px;
		font-weight: 500;
		/* border-right: 1px solid #ffffff; */
		border-bottom: 1px solid #ffffff;
		overflow: auto;
	}
	.phone_data_type_3_expand_item_2{
		/* text-align: left; */
		height: 51px;
		line-height: 50px;
		font-size: 16px;
		font-weight: 500;
		border-bottom: 1px solid #ffffff;
		overflow: auto;
	}
	.phone_data_type_3_expand_item_3{
		height: 25px;
		/* line-height: 25px; */
		font-size: 15px;
		font-weight: 500;
		border-bottom: 1px solid #ffffff;
		color: #55ffff;
		overflow: auto;
	}
	.phone_history_device_data2_select_col{
		/* background-color: #55aaff; */
		color: #6dfaff;
		font-size: 16px;
		/* font-weight: 600; */
		/* line-height:55px; */
	}
	.history_data_2_show_layout{
		position: absolute;
		z-index: 99;
		margin-left: 10%;
		margin-top: 85px;
		width: 80%;
		height: 75%;
		border-radius: 50px;
		background-color: rgba(0, 85, 127, 0.98);
		box-shadow: 0px 0px 5px 5px rgba(85, 255, 255, 0.9) ,inset 0px 0px 10px 5px rgba(85, 255, 255, 0.9);
	}
	.history_data_2_show_header{
		width: 100%;
		height: 50px;
		background-color: rgba(255, 255, 255, 0.5);
		border-top-left-radius: 50px;
		border-top-right-radius: 50px;
		font-size: 60px;
		line-height: 50px;
		color: #ffaa00;
	}
	.history_data_2_show_header:hover{
		background-color: rgba(255, 0, 0, 0.5);
	}
	.history_data_2_show_data{
		margin-left: 20px;
		margin-top: 15px;
		width: calc(100% - 40px);
		height: calc(100% - 50px - 35px);
		/* background-color: rgba(85, 255, 255, 0.5); */
	}
	.history_data_2_show_data_default{
		position: absolute;
		z-index: 99;		
		width: 100%;
		/* margin-left: 20px;
		margin-top: 15px; */
		height: calc(100% - 50px );
		background-color: rgba(0, 0, 0, 0.5);
		border-bottom-left-radius: 50px;
		border-bottom-right-radius: 50px;
		padding-top: 3%;
	}
	.phone_return_btn_{
		margin-top: 2px;
		margin-left: 100px;		
		background: url(../assets/sort_chart.png) no-repeat !important;
		background-size: 30px 30px !important;
		width: 30px;
		height: 30px;
	}
	.phone_history_main_layout .el-tabs__item{
		padding:0px;
		padding-right: 10px;
		font-size: 16px;
	}
	.phone_device_data_type_2_layout_title{
		margin-bottom: 10px;
		background-color: #00557f;	
		background-color: rgba(19, 77, 88, 0.4);
		border-radius: 10px;
		border: 1px solid #77f7f8;
		box-shadow: 0px 0px 3px 2px #6dfaff;
		margin-top: 8px;
		margin-left: 5px;
		height: 95px;
		/* height: 8%; */
		min-height: 40px;
		width: calc(100% - 10px);
	}
	.phone_type_2_data_show_layout{
		position: absolute;
		z-index: 99;
		margin-left: 10px;
		margin-top: 40px;
		width: calc(100% - 20px);
		height: calc(100% - 10px - 30px);
		/* border-radius: 30px; */
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
		background-color: rgba(0, 85, 127, 0.98);
		box-shadow: 0px 0px 5px 5px rgba(85, 255, 255, 0.9) ,inset 0px 0px 10px 5px rgba(85, 255, 255, 0.9);
		z-index: 98;
		overflow: auto;
	}
	.phone_type_2_data_show_header{
		margin-left: 10px;
		width: calc(100% - 20px);
		height: 30px;
		background-color: rgba(255, 255, 255, 0.95);
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		font-size: 50px;
		line-height: 30px;
		color: #ffaa00;
		position: absolute;
		z-index: 99;
		margin-top: 10px;
	}
	.phone_type_2_data_show_data_default{
		position: absolute;
		z-index: 99;		
		width: 800px;
		/* margin-left: 20px;
		margin-top: 15px; */
		height: calc(100% - 0px );
		background-color: rgba(0, 0, 0, 0.5);
		/* border-bottom-left-radius: 30px; */
		/* border-bottom-right-radius: 30px; */
		padding-top: 3%;
	}
	.phone_type_2_data_show_data{
		/* margin-left: 20px; */
		/* margin-top: 5px; */
		/* width: calc(100% - 20px); */
		/* height: calc(100% - 50px - 35px); */
		/* height: 450px; */
		width: 800px;
		height: calc(100% - 30px);
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
		/* width: 1500px; */
		/* background-color: rgba(85, 255, 255, 0.5); */
		/* padding-left: 40px; */
		/* overflow: auto; */
	}
	.phone_history_data_layout_3_content .el-table__expand-icon>.el-icon{
		font-size: 17px !important;
	}
	.phone_history_data_layout_3_content.cell{
		font-size: 17px !important;
	}
</style>