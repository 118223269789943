<template>
  <div class="phone_login" >

	<el-header 
		class="el-header_style"
	>
		<Header></Header>
	
		<!-- 智能巡检机器人平台 -->
	</el-header>

	<el-container style="height: calc(100vh - 60px);">
		<!-- <el-row style="height: 100%;"> -->
			<!-- <el-col :span="3"></el-col> -->
			<!-- <el-col :span="18"> -->
			<div class="phone_logo">
				
			</div>
				<div class="phone_login_frame" >
					<div class="login_boxBorder1 ">
						<div class="login_boxBorder2">
							<div class="phone_login_data_style">
								<el-row style="margin-top: 20px;margin-left: -40px;margin-right: -40px;margin-bottom: 5vh;">
									<el-col :span="2"></el-col>
								    <el-col :span="4"><div class="main_title_style_b" style="z-index: 1; "  /> </el-col>						
								    <el-col :span="12">
										<div class="main_title_style_a" style="z-index: -1;">
											系统登录
										</div>
									</el-col>
								    <el-col :span="4"><div class="main_title_style_c" style="z-index: 1; "  /></el-col>
									<el-col :span="2"></el-col>
								</el-row>
<!-- 								<div style="color: white; font-size: 30px;text-align: center;padding: 30px 0">
								  
								</div> -->
								<div class="demo-input-suffix">
								  <el-form ref="form" :model="form" :rules="rules">
								
								    <el-form-item prop="username">
								      <el-input
								          v-model="form.username"
								          placeholder="   请输入账号"
								          size="large"
								          clearable
										  >
								        <template #prefix>
								          <el-icon class="el-input__icon" size="15">
								            <avatar/>
								          </el-icon>
								        </template>
								      </el-input>
								    </el-form-item>
								
								    <el-form-item prop="password">
								      <el-input
								          v-model="form.password"
								          placeholder="   请输入密码"
								          size="large"
								          clearable
								          show-password>
								        <template #prefix>
								          <el-icon class="el-input__icon" size="15">
								            <lock/>
								          </el-icon>
								        </template>
								      </el-input>
								    </el-form-item>
								
								    <el-form-item>
								      <el-button style="width: 100%;margin: 1vh auto" type="primary" @click="login()">
								        登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								        <el-icon class="el-input__icon" size="20">
								          <pointer/>
								        </el-icon>
								        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录
								      </el-button>
								    </el-form-item>
								  </el-form>
								</div>
							</div>
							<div class="phone_login_text_style">
								
							</div>
						</div>
					</div>
				
				</div>
			<!-- </el-col> -->
			<!-- <el-col :span="3"></el-col> -->
			
		<!-- </el-row> -->
	</el-container>
    <!--    </el-card>-->
		<el-footer class="el-footer_style" >
			<div class="boxBorder1">
				<div class="boxBorder2">
					<div class="footer_text">
						<a target="_blank" href="https://beian.miit.gov.cn" >
							鄂ICP备2023006219号-1
						</a>
					</div>
				</div>
			</div>
		</el-footer>
	</div>
	
</template>

<script>
import Header from '../components/phone_Header.vue'
import {Avatar, Lock, Pointer} from '@element-plus/icons-vue'
import request from "../utils/request";
import { ElMessage } from 'element-plus'

var user = {};
export default {
  name: "login",
  components: {
    Lock,
    Avatar,
    Pointer,
	Header,
  },
  data() {
    return {
      form: {},
      rules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
          // { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          // { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }

        ],
        usertype: [
          {required: true, message: '请选择账号类型', trigger: 'blur'}
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    login() {
      user.username = this.form.username;
      user.password = this.form.password;
      this.$refs['form'].validate((valid) => {
        if (valid) {
			// console.log(this.$GLOBAL.flask_web_url)
			request.post(this.$GLOBAL.flask_web_url+'/login', user).then(res => {
				//console.log(res)
				if(res=="500"){
					this.$message({
						type: "error",
						message: "登录失败，账号或密码错误！！！"
					})
				}else if(res=="404"){
					this.$message({
						type: "error",
						message: "登录失败，账号不存在！！！"
					})
				}else{
					sessionStorage.setItem("user", res.username)
					sessionStorage.setItem("power", res.power)
					sessionStorage.setItem("id", res.id)
					this.$router.push("/phone/phone_Device_Data")//登录成功后跳转
					this.$message({
						type: "success",
						message: "登录成功！！！"
					})
					
				}
			}).catch(function(){
					ElMessage.error('服务器异常 ！！！')
			})	
		
          // if (this.form.usertype == "3") {
          //   request.post("/login/admin", user).then(res => {
          //     if (res.code === '0') {
          //       this.$message({
          //         type: "success",
          //         message: "登录成功"
          //       })
          //       sessionStorage.setItem("user", res.data);
          //       sessionStorage.setItem("power", "admin");
          //       this.$router.push("/admin")//登录成功后跳转
          //     } else {
          //       this.$message({
          //         type: "error",
          //         message: res.msg
          //       })
          //     }
          //   })
          // } 
		  
        } else {
          console.log('error submit!!');
		  this.$message({
		    type: "error",
		    message: "登录失败！！！"
		  })
          return false;
        }
      });

    }
  }
}
</script>

<style scoped>
/* 背景图片*/
.phone_login {
  background: url(../assets/bg.jpeg) no-repeat;
  /* background: url(../assets/bg_1.jpg) no-repeat; */
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  width: 100%; 
  height: calc(100vh + 50px);
  /* height: 100vh; */
  /* background-color: darkcyan; */
  overflow: hidden;
  
}
.phone_login_frame{
	min-width: 300px;
	width: calc(100% - 60px);
	margin-left: 30px;
	height: 35vh;
	min-height: 260px;
	/* margin: 10% 27%; */
	margin-top: calc(10vh + 90px);
	/* position: absolute; */
}
.phone_login_data_style{
	/* padding: 40px; */
	padding: 0px 40px;
	min-width: 300px;
	width: 100%;
	height: 100%;
	/* background-color: rgba(130, 130, 130, 0.3); */
	/* display: inline; */
	
}
.phone_login_text_style{
	/* padding: 10px; */
	/* min-width: 300px; */
	height: 70%;
	/* width: 50%; */
	background-color: rgba(255, 255, 0, 0.3);
	display: inline-block;
}
.login_boxBorder1 {
    height: 100%;
    padding: calc(6vw * 100 / 1920);
	/* 最外层 */
	background: linear-gradient(135deg, transparent calc(11vw * 150 / 1920), rgba(85, 170, 255, 0.3) 0) top left,
				linear-gradient(-135deg, transparent calc(11vw * 150 / 1920), rgba(85, 170, 255, 0.3) 0) top right,
                linear-gradient(-45deg, transparent calc(11vw * 150 / 1920), rgba(85, 170, 255, 0.3) 0) bottom right,
                linear-gradient(45deg, transparent calc(11vw * 150 / 1920), rgba(85, 170, 255, 0.3) 0) bottom left;
	/* background-size: 50.5% 50.5%; */
	/* position: relative; */
	  /* width: 400px; */
	  /* height: 300px; */
	  /* margin: 300px auto; */
	  /* background: rgba(1, 19, 67, 0.8); */
	  /* border: 2px solid #00a1ff; */
	  border-radius: 25px;
	background-size: 50% 50%;
    background-repeat: no-repeat;
    z-index: auto;
}

.login_boxBorder2{

    position: relative;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
	/* 最里层 */
   /* background: linear-gradient(135deg, transparent calc(11vw * 100 / 1920), rgba(1, 49, 61, 0.2) 0) top left,
                linear-gradient(-135deg, transparent calc(11vw * 100 / 1920), rgba(1, 49, 61, 0.2) 0) top right,
                linear-gradient(-45deg, transparent calc(11vw * 100 / 1920), rgba(1, 49, 61, 0.2) 0) bottom right,
                linear-gradient(45deg,transparent calc(11vw * 100 / 1920), rgba(1, 49, 61, 0.2) 0) bottom left;
	background-size: 50.5% 50.5%;
    background-repeat: no-repeat;
	transform-style: preserve-3d; */
    
}

.phone_login .el-container{
	display: block;
}
.phone_logo{
	/* background: url(../assets/bg.jpeg) no-repeat; */
	background: url(../assets/bg_3.png) no-repeat;
	background-size: cover;
	width: 80px;
	height: 80px;
	position: absolute;
	margin-top: -100px;
	background-size: 80px 80px;
	/* width: 100%; */
	/* height: calc(100vh + 50px); */
	/* height: 100vh; */
	/* background-color: darkcyan; */
	overflow: hidden;
	margin-left: calc(50% - 40px);
}
</style>
