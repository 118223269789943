<template>
	<!-- <div > -->
		<!-- test ....... -->
		<el-container>
		    <el-header 
				class="el-header_style"
				>
				<Header></Header>
				
				  <!-- 智能巡检机器人平台 -->
			</el-header>
			<el-container >
		        <el-aside >
					<Aside></Aside>
					<!-- —&nbsp;功能列表&nbsp;— -->
				</el-aside>
		        <el-main id="main_layout" class="main_layout_style">
					<!-- 主界面 -->
					<router-view/>
				</el-main>
		    </el-container>
<!-- 			<el-footer class="el-footer_style">
				<div class="boxBorder1">
					<div class="boxBorder2">
						<div class="footer_text">
							<a target="_blank" href="https://beian.miit.gov.cn" >
								鄂ICP备2023006219号-1
							</a>
						</div>
					</div>
				</div>
			</el-footer> -->
			
		</el-container>
	<!-- </div> -->
</template>

<script>
	// document.getElementById('aside').style.height=window.innerHeight*0.9355+'px'
	import Header from '../components/Header.vue'
	import Aside from '../components/Aside.vue'
	export default{
		name:'mainLayout',
		components:{
			Header,
			Aside
		},
		data() {
			return{
				// isCollapse :Aside.data().isCollapse
			}
		}
		
	}
</script>

<style>

</style>