<template>
	<div class="confirm_action" v-show="confirm_action_isShow">
		<div style="width: 100%;height: 40px;margin-top: 4%;">
			<div style="
				width: 100px;
				font-size: 17;
				float: left;
				line-height: 40px;
				text-align: right;
				color: #55ffff;
			">
				提交人：
			</div>
			<el-input v-model="confirm_user"
				style="width: calc(100% - 120px);float: left;line-height: 40px;"
				placeholder="  " 
				clearable
				/>
		</div>
		<div style="width: 100%;height: 40px;margin-top: 10px;">
			<div style="
				width: 100px;
				font-size: 17;
				float: left;
				line-height: 40px;
				text-align: right;
				color: #55ffff;
			">
				提交信息：
			</div>
			<el-input v-model="confirm_text"
				style="width: calc(100% - 120px);float: left;line-height: 40px;"
				placeholder="  " 
				clearable
				/>
		</div>
		<div style="font-size: 19px;color: #ffaa00;margin-top: 10px;">
			请仔细核对检修结果
		</div>
		<div style="font-size: 17px;margin-top: 10px;color: #fff;">
			当前时间:{{submit_time}}
		</div>
		<div style="font-size: 18px;color: #55ffff;margin-top: 10px;">
			是否提交检修结果至服务器
		</div>
		<div style="width: 100%;height: 50px;margin-top: 40px;">
			<div style="width: calc(40% - 20px);
					background-color: #8f8f8f;
					height: 50px;
					display: inline-block;
					line-height: 50px;
					border-radius: 20px;
					border: 1px solid #55ffff;
					font-size: 17px;"
					@click="close_confirm_action"
					>
				取消
			</div>
			<div style="width: calc(60% - 20px);
					margin-left: 10px;
					background-color: #55aaff;
					border: 1px solid #55ffff;
					border-radius: 20px;
					height: 50px;
					display: inline-block;
					line-height: 50px;
					font-size: 18px;"
					@click="submit_data"
					>
				确认提交
			</div>
		</div>
		
	</div>
	<div class="submit_data_main_layout">
		<div class="submit_data_header" >
			设备维护检修
		</div>
		<div class="submit_data_header_2" >
			{{now_device_address}}			
			{{now_device_id}}
		</div>
		
		<div class="submit_data_list_layout">
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_list_title" 
						style="border-top-left-radius: 20px;
							border: 1px solid #fff;
						">
						序号
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_list_title" style="border: 1px solid #fff;">
						检查内容
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_list_title" 
						style="border-top-right-radius: 20px;
							border: 1px solid #fff;
							">
						检查结果
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col" style="margin-top: 0px;">
						{{device_data3_list[0].id}}
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content" style="margin-top: 0px;">
					{{device_data3_list[0].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio1" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col">
						{{device_data3_list[1].id}}
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content" >
						{{device_data3_list[1].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio2" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col">
						{{device_data3_list[2].id}}
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content" >
						{{device_data3_list[2].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio3" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col">
						{{device_data3_list[3].id}}
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content">
						{{device_data3_list[3].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio4" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col">
						{{device_data3_list[4].id}}
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content">
						{{device_data3_list[4].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio5" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col">
						{{device_data3_list[5].id}}
		
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content">
						{{device_data3_list[5].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio6" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col">
						{{device_data3_list[6].id}}
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content">
						{{device_data3_list[6].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio7" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col">
						{{device_data3_list[7].id}}
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content">
						{{device_data3_list[7].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio8" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col">
						{{device_data3_list[8].id}}
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content">
						{{device_data3_list[8].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio9" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
			<el-row style="width: calc(100% - 20px);">
				<el-col :span="4" >
					<div class="submit_data_col" style="border-bottom-left-radius: 20px;">
						{{device_data3_list[9].id}}
					</div>
				</el-col>
				<el-col :span="14">
					<div class="submit_data_col submit_data_col_content">
						{{device_data3_list[9].content}}
					</div>
				</el-col>
				<el-col :span="6">
					<div class="submit_data_col" style="margin-top: 0px;overflow: hidden;border-bottom-right-radius: 20px;">
						<!-- {{device_data3_list[0].value}} -->
						<el-radio-group v-model="radio10" size="small">
						    <el-radio-button label="是" />
						    <el-radio-button label="否" />
						</el-radio-group>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="bottom_btn" @click="submit_btn">
			提交
		</div>

	</div>
</template>

<script>
	import request from "@/utils/request";
	import { ElMessage } from 'element-plus'
	export default{
		data() {
			return{
				confirm_text:"",
				confirm_user:"",
				device_data3_list:[
					{
						id:1,
						content:"金属结构有无严重变形及裂纹、焊缝有无开裂等情况",
						value:"检查结果",
					},
					{
						id:2,
						content:"各部位的螺栓紧固情况，如法兰面螺栓、复位支架、液压站、电控箱、锚定机构连接螺栓等",
						value:"检查结果",
					},
					{
						id:3,
						content:"导向滚轮能否顺利转动，踏面磨损情况",
						value:"检查结果",
					},
					{
						id:4,
						content:"清槽板、开合机构/复位弹簧是否完好，是否有零件缺失，如夹紧块",
						value:"检查结果",
					},
					{
						id:5,
						content:"油缸的伸缩动作是否正常，夹钳转动是否灵活",
						value:"检查结果",
					},
					{
						id:6,
						content:"锚定机构是否正常，如销轴是否灵活，限位开关、自动锚定是否正常等",
						value:"检查结果",
					},
					{
						id:7,
						content:"线缆连接是否松动，传感器是否正常，电控柜各指示灯是否正常",
						value:"检查结果",
					},
					{
						id:8,
						content:"液压站的油位，油缸以及液压回路是否存在漏油情况，油缸保压是否正常",
						value:"检查结果",
					},
					{
						id:9,
						content:"轨道及轨道沟槽是否满足使用要求",
						value:"检查结果",
					},
					{
						id:10,
						content:"是否定期加注润滑油脂(每个月一次)",
						value:"检查结果",
					},
					{
						id:11,
						update_time:"--------------------------"
					},
				],
				radio1:"否",
				radio2:"否",
				radio3:"否",
				radio4:"否",
				radio5:"否",
				radio6:"否",
				radio7:"否",
				radio8:"否",
				radio9:"否",
				radio10:"否",
				submit_time:"",
				confirm_action_isShow:false,
				now_device_id:"",
				now_device_address:"",
			}
		},
		methods:{
			submit_btn(){
				let date = new Date()
				this.submit_time = date.getFullYear()+"/"
						+(date.getMonth()+1)+"/"
						+date.getDate()+" "+date.toTimeString().substring(0,5)
				this.confirm_action_isShow = true
			},
			close_confirm_action(){
				this.confirm_action_isShow = false
			},
			submit_data(){
				if(this.confirm_text==""||this.confirm_user==""){
					ElMessage.error("信息不能为空！")
				}else{
					
					let geturl = window.location.href 
					let device_id = geturl.split('=')[1]
					let msg_data = {
						"device_id":device_id,
						"device_data":{
							"3_1":this.radio1=="是"? 1 : 0,
							"3_2":this.radio2=="是"? 1 : 0,
							"3_3":this.radio3=="是"? 1 : 0,
							"3_4":this.radio4=="是"? 1 : 0,
							"3_5":this.radio5=="是"? 1 : 0,
							"3_6":this.radio6=="是"? 1 : 0,
							"3_7":this.radio7=="是"? 1 : 0,
							"3_8":this.radio8=="是"? 1 : 0,
							"3_9":this.radio9=="是"? 1 : 0,
							"3_10":this.radio10=="是"? 1 : 0,
						},
						"text":this.confirm_text,
						"user":this.confirm_user
					}
					
					request.post(this.$GLOBAL.flask_web_local_url+'/update_device_data_type_3',msg_data).then(res =>{
						console.log(res)
						if(res=="500"){
							ElMessage.error("数据错误！")
						}else{
							ElMessage.success("数据上传成功")
							this.confirm_action_isShow = false
							this.confirm_text=""
							this.confirm_user=""
						}
						
						
					}).catch(function(e){
						ElMessage.error(e)
						ElMessage.error('数据上传失败，请稍后重试 ！！！')
					})
				}
			},
			init_device_info(){
				let geturl = window.location.href
				let device_id = geturl.split('=')[1]
				// console.log(device_id)
				request.get(this.$GLOBAL.flask_web_local_url+'/get_one_device_info?device_id='+device_id).then(res =>{
					this.now_device_address = res['address']
					if(this.now_device_address.length>15){
						this.now_device_address = this.now_device_address.substring (0,14)+"..."
					}
					this.now_device_id = res['device_id']
					// console.log(res)
				})
			}
		},
		//模板渲染完成后执行，可以拿到DOM节点和数据
		mounted(){
			this.init_device_info()
		},
	}
</script>

<style>
	.submit_data_main_layout{
		position: relative;
		width: 100vw;
		height: 100vh;
		/* background-color: #ffffff; */
		/* z-index: 999; */
	}
	.submit_data_header{
		width: 100%;
		height: 50px;
		background-color: rgba(85, 170, 255, 0.3);
		line-height: 50px;
		color: #ffffff;
		text-align: center;
		font-size: 25px;
		font-weight: 600;
	}
	.submit_data_header_2{
		width: 100%;
		height: 40px;
		background-color: rgba(255, 255, 255, 0.3);
		background-color: rgba(85, 170, 255, 0.3);
		line-height: 25px;
		color: #55ffff;
		text-align: center;
		font-size: 20px;
		font-weight: 500;
	}
	.submit_data_list_title{
		margin-top: 10px;
		height: 50px;
		width: calc(100%);
		margin-left: 10px;
		background-color: rgba(85, 170, 255, 0.6);
		color: #00ffff;
		line-height: 50px;
	}
	.submit_data_list_layout{
		position: relative;
		margin-top: 10px;
		overflow: auto;
		overflow-x: hidden;
		width: 100%;
		height: calc(100% - 15px - 50px - 40px - 50px);
	}
	.submit_data_col{
		overflow: auto;
		/* margin-top: 5px; */
		border: 1px solid #ffffff;
		height: 90px;
		line-height: 88px;
		width: calc(100% );
		margin-left: 10px;
		background-color: rgba(85, 170, 255, 0.5);
		color: #ffffff;
		
	}
	.submit_data_col_content {
		text-align: left;
		padding-left: 20px;
		line-height: 20px;
		display: flex;
		align-items: center;
		
	}
	.bottom_btn{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
		position: absolute;
		bottom: 0px;
		background-color: rgba(85, 170, 255, 0.9);
	}
	.confirm_action{
		position: absolute;
		width: 100vw;
		/* margin-left: 3vw; */
		margin-top: 25vh;
		height: 50vh;
		background-color: rgba(56, 112, 168, 1.0);
		z-index: 99;
		border: 2px solid #55ffff;
		border-radius: 30px;
		
	}

</style>