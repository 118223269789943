<template>
	<div class="header">
		<div class="header_text">
			防爬器监控系统
		</div>
		<div style="flex: 1;"></div>
		<!-- <div style="flex: 1;"></div> -->
		<div style="margin-right: 30px;" id="user_text" ref="user_text">
			<el-dropdown @command="handleCommand" >
			    <span class="el-dropdown-link" 
					style="color: #00ffff;
						font-size: 20px;
						font-weight: 600;
						">
			      {{now_user}}
			      <el-icon class="el-icon--right">
			        <arrow-down />
			      </el-icon>
			    </span>
			    <template #dropdown>
			      <el-dropdown-menu>
			        <!-- <el-dropdown-item>Action 1</el-dropdown-item> -->
			        <!-- <el-dropdown-item>Action 2</el-dropdown-item> -->
			        <!-- <el-dropdown-item>Action 3</el-dropdown-item> -->
			        <!-- <el-dropdown-item disabled>Action 4</el-dropdown-item> -->
					<el-dropdown-item  command="edit" >修改信息</el-dropdown-item>
			        <el-dropdown-item  command="logout" >退出登录</el-dropdown-item>
					
					
			      </el-dropdown-menu>
			    </template>
			  </el-dropdown>
		</div>
		<div v-show="false">
			<span id="header_day" class="header_right" >
			</span>	
			<span id="header_time" class="header_time">
			</span>
		</div>
		

	</div>
	
	<el-dialog v-model="dialogTableVisible" draggable @close="close_admin_dialog">
		<template #default="scope">
		    <div class="admin_dialog_layout">
				<div style="
					width: 100%;
					height: 75%;
				" >
					<el-row
						style="
							margin-top: 30px;
						">
						<el-col :span="4" >
							
						</el-col>
						<el-col :span="4" 
							style="
								text-align: right;
							">
							用户名：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="user.username"
								id="see_admin_username" :disabled="unedit_admin_value"
							></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="4" >
							
						</el-col>
						<el-col :span="4" 
							style="
								text-align: right;
							">
							密码：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="user.password"
								id="see_admin_password" :disabled="unedit_admin_value"
							></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
				
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="4" >
							
						</el-col>
						<el-col :span="4" 
							style="
								text-align: right;
							">
							姓名：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="user.name"
								id="see_admin_name" :disabled="unedit_admin_value"
							></el-input>
						</el-col>
						<el-col :span="4" >
							
						</el-col>
					</el-row>
					
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="4" >
							
						</el-col>
						<el-col :span="4" 
							style="
								text-align: right;
							">
							性别：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="user.sex"
								id="see_admin_sex" :disabled="unedit_admin_value" 
							></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					<!-- <el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="6" >
							
						</el-col>
						<el-col :span="2" 
							style="
								text-align: right;
							">
							职位：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="user.job"
								id="see_admin_job" :disabled="unedit_admin_value" 
							></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row> -->
					<!-- <el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="6" >
							
						</el-col>
						<el-col :span="2" 
							style="
								text-align: right;
							">
							权限：
						</el-col>
						<el-col :span="8" >
							<el-input v-model="user.power"
								id="see_admin_power" :disabled="unedit_admin_value" 
							></el-input>
						</el-col>
						<el-col :span="8" >
						</el-col>
					</el-row> -->
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="4" >
							
						</el-col>
						<el-col :span="4" 
							style="
								text-align: right;
							">
							手机：
						</el-col>
						<el-col :span="8">
							<el-input v-model="user.phone"
								id="see_admin_phone" :disabled="unedit_admin_value" 
							></el-input>
							
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="4" >
							
						</el-col>
						<el-col :span="4" 
							style="
								text-align: right;
							">
							地址：
						</el-col>
						<el-col :span="8" >
							<el-input id="see_admin_address" :disabled="unedit_admin_value" v-model="user.address" ></el-input>
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
					<el-row
						style="
							margin-top: 20px;
						">
						<el-col :span="4" >
							
						</el-col>
						<el-col :span="4" 
							style="
								text-align: right;
							"
							
							>
							更多：
						</el-col>
						<el-col :span="8" >
							<el-input 
								type="textarea"
								v-model="user.more"
								:rows="4"
								resize="none"
								id="see_admin_more" :disabled="unedit_admin_value" 
								>
							</el-input>
							
						</el-col>
						<el-col :span="8" >
							
						</el-col>
					</el-row>
				</div>
				<div class="save_btn">
					<el-button size="large" style="
						background-color: rgba(108, 204, 255, 0.1);
						box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
						color: #6cccff;
						font-size: 15px;
						font-weight: 600;
						border: none;
						"
						ref="save_change_button"
						id="save_change_button"
						@click="save_admin_dialog"
						>
						保存
					</el-button>
				</div>
				
				<!-- <el-row>
					<el-col :span="15">
						
					</el-col>
					<el-col :span="4" style="text-align: right;">
						<el-button size="large" style="
							background-color: rgba(108, 204, 255, 0.1);
							box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
							color: #6cccff;
							font-size: 15px;
							font-weight: 600;
							border: none;
							"
							ref="save_change_button"
							id="save_change_button"
							@click="change_admin_dialog"
							>
							保存
						</el-button>
					</el-col>
					<el-col :span="4">
						<el-popconfirm
							title="确定删除吗？" 
							@confirm="delete_admin"
						>
						  <template #reference>
						    <el-button 
								type="danger" 
								size="large" 
								style="
									background-color: rgba(255, 85, 127, 0.3);
									box-shadow: 0px 0px 5px 2px #ff557f, inset 0px 0px 10px 1px #ff557f;
									color: #ff557f;
									font-size: 15px;
									font-weight: 600;
									border: none;">
								删除
							</el-button>
						  </template>
						</el-popconfirm>
					</el-col>
				</el-row> -->
		    </div>
		</template>
	</el-dialog>
</template>

<script>
	import Aside from '../components/Aside.vue'
	import { ArrowDown } from '@element-plus/icons-vue'
	import { ElMessage } from 'element-plus'
	import request from "../utils/request";
	export default {
		name:"Header",
		data() {
			return {
				dialogTableVisible:false,
				// isCollapse:false,
				now_user:"",
				timer:"",
				// info_timer:"",
				user:{}
			}
		},
		components:{
			Aside,
			ArrowDown
		},
		watch:{
			// isCollapse:function(val){
			// 	Aside.methods.setIsCollapse()
			// 	// console.log();
			// }
		},
		mounted(){
			let that = this
			// console.log(document.getElementById("user_text").style.display) 
			// console.log(this.$refs.user_text.style.display) 
			this.timer = setInterval(function(){
				// 获取当前登录用户名
				let get_user = sessionStorage.getItem('user')
				if(get_user){
					that.now_user=get_user
				}
				// 如果为空则不显示
				if(that.now_user==""){
					that.$refs.user_text.style.display="none"
				}else{
					that.$refs.user_text.style.display=""
				}
				
				
				let date = new Date()
				let day=date.getFullYear()+"/"
						+(date.getMonth()+1)+"/"
						+date.getDate()+"  星期";
				switch(date.getDay()){
					case 1:  
						day+="一";
						break;
					case 2:
						day+="二";
						break;
					case 3:
						day+="三";
						break;
					case 4:
						day+="四";
						break;
					case 5:
						day+="五";
						break;
					case 6:
						day+="六";
						break;
					case 7:
						day+="日";
						break;
				}
				document.getElementById("header_day").innerText=day
				let time =date.toTimeString().substring(0,8);
				document.getElementById("header_time").innerText=time
				// console.log(time)
				
				

				
			},999)

		},
		methods:{
			handleCommand(e){
				if(e=="logout"){
					sessionStorage.removeItem("user");
					sessionStorage.clear()
					this.now_user=""
					this.$refs.user_text.style.display="none"
					this.$router.push("/login")
				}else if(e=="edit"){
					request.post(this.$GLOBAL.flask_web_local_url+'/get_one_admin', {"id":sessionStorage.getItem('id')}).then(res => {
						this.user=res
						// console.log(this.user)
						// console.log(res)
						this.dialogTableVisible=true
						// console.log(tthis.dialogTableVisible)
					}).catch(function(){
					ElMessage.error('数据加载失败 ！！！')
				})	
					
				}
			},
			save_admin_dialog(){
				console.log(this.user)
				request.post(this.$GLOBAL.flask_web_local_url+'/update_admin_data', this.user).then(res => {
					if(res=="200"){
						sessionStorage.setItem("user", this.user.username)
						this.$message({
							type: "success",
							message: "信息修改成功"
						})
						this.dialogTableVisible=false
					}else if (res=="500"){
						this.$message({
							type: "error",
							message: "修改失败，该用户名已存在！！！"
						})
					}else if (res=="501"){
						this.$message({
							type: "error",
							message: "修改失败，密码不能为空！！！"
						})
                    }
				}).catch(function(){
					ElMessage.error('操作异常 ！！！')
				})	
				
			}
		},
		beforeDestroy() {
		    clearInterval(this.timer);	
			// clearInterval(this.info_timer);	
		},
		// 切换界面时调用
		beforeUnmount(){
		    clearInterval(this.timer);	
			// clearInterval(this.info_timer);	
		}
	}
</script>

<style>
</style>