<template>
	<div style="

			width:25px;
			height:100px;
			background-color: rgba(0, 170, 255, 0.8);
			margin-top: 50px;
			margin-left: 5px;
			color: #55ffff;
			border-radius: 20px;
			
			position: fixed;
			z-index: 100;
			"
			@click="show_aside_menu"
			>
		<!-- 展开导航 -->
		{{show_aside_menu_text}}
	</div>
	<div class="phone_aside" v-show="aside_menu_isShow">
		  <el-menu
			id="menu"
		    :default-active="index"
		    class="phone_aside_menu"
		    :collapse="isCollapse"
		    @open="handleOpen"
		    @close="handleClose"
			@select="handelSelect"
			background-color="#00557f"
			text-color="#55ffff"
			active-text-color="#ffd04b"
			router
			
		  >			
		 <!-- <div class="collapse_btn" v-show="!isCollapse">
		  	<div class="collapse_icon" id="collapse_btn" @click="setMenuShow"></div>
		  </div>
		  <div class="collapse_btn" v-show="isCollapse">
		  	<div class="collapse_icon_hide" id="collapse_icon_hide" @click="setMenuShow"></div>
		  </div> -->
		  <!-- <div id="aside_Title" class="aside_title" > -->
		  			<!-- {{title}} -->
		  			  
		  <!-- </div> -->
		  
		  <!-- <div class="phone_aside_menu_items"> -->
		  
				<el-menu-item index="phone_Device_Data" class="phone_aside_menu_item">
					<el-icon size="20px" class="phone_aside_menu_item_icon">
						<DataAnalysis />
					</el-icon>
					<template #title >
						设备信息
					</template>
				</el-menu-item>
				
				<!-- <el-sub-menu  class="aside_sub_menu_item" index="sub_1"> 
					<template #title class="aside_sub_menu_item">
					    <el-icon size="20px" class="phone_aside_menu_item_icon">
					    	<Loading />
					    </el-icon>
					    设备数据
					</template>
					<el-menu-item index="Device_Data_2" class="phone_aside_menu_item" v-show="show_real_time_device_data">
						<el-icon size="20px" class="phone_aside_menu_item_icon">
							<Loading />
						</el-icon>
						<template #title class="phone_aside_menu_item">
							实时数据
						</template>
					</el-menu-item>
					
					<el-menu-item index="Device_Data_3" class="phone_aside_menu_item" v-show="show_status_device_data">
						<el-icon size="20px" class="phone_aside_menu_item_icon">
							<Operation />
						</el-icon>
						<template #title class="phone_aside_menu_item">
							状态数据
						</template>
					</el-menu-item>
					<el-menu-item index="Device_Data_4" class="phone_aside_menu_item" v-show="show_maintenance_device_data">
						<el-icon size="20px" class="phone_aside_menu_item_icon">
							<Calendar />
						</el-icon>
						<template #title class="phone_aside_menu_item">
							维护数据
						</template>
					</el-menu-item>
					<el-menu-item index="Device_Data_5" class="phone_aside_menu_item">
						<el-icon size="20px" class="phone_aside_menu_item_icon">
							<DataLine />
						</el-icon>
						<template #title class="phone_aside_menu_item">
							故障回顾
						</template>
					</el-menu-item>
				</el-sub-menu>
				 -->
				<el-sub-menu  class="aside_sub_menu_item" index="sub_2">
					<template #title class="aside_sub_menu_item">
					    <el-icon size="20px" class="phone_aside_menu_item_icon">
					    	<Star />
					    </el-icon>
					    <!-- 专属页 -->
						设备数据
					</template>
					<el-menu-item index="phone_Private_Device_1" class="phone_aside_menu_item" v-show="show_real_time_device_data">
						<el-icon size="20px" class="phone_aside_menu_item_icon">
							<Loading />
						</el-icon>
						<template #title class="phone_aside_menu_item">
							实时数据
						</template>
					</el-menu-item>
					
					<el-menu-item index="phone_Private_Device_2" class="phone_aside_menu_item" v-show="show_status_device_data">
						<el-icon size="20px" class="phone_aside_menu_item_icon">
							<Operation />
						</el-icon>
						<template #title class="phone_aside_menu_item">
							状态数据
						</template>
					</el-menu-item>
<!-- 					<el-menu-item index="Private_Device_2_s" class="phone_aside_menu_item" v-show="show_status_device_data">
						<el-icon size="20px" class="phone_aside_menu_item_icon">
							<Operation />
						</el-icon>
						<template #title class="phone_aside_menu_item">
							状态数据(简)
						</template>
					</el-menu-item> -->
					<el-menu-item index="phone_Private_Device_3" class="phone_aside_menu_item" v-show="show_maintenance_device_data">
						<el-icon size="20px" class="phone_aside_menu_item_icon">
							<Calendar />
						</el-icon>
						<template #title class="phone_aside_menu_item">
							维护数据
						</template>
					</el-menu-item>
					<el-menu-item index="phone_Private_Device_4" class="phone_aside_menu_item">
						<el-icon size="20px" class="phone_aside_menu_item_icon">
							<DataLine />
						</el-icon>
						<template #title class="phone_aside_menu_item">
							故障回顾
						</template>
					</el-menu-item>
				</el-sub-menu>
				
				<!-- <el-menu-item index="main" class="phone_aside_menu_item">
					<el-icon size="20px" class="phone_aside_menu_item_icon">
						<DataAnalysis />
					</el-icon>
					<template #title class="phone_aside_menu_item">
						设备数据old
					</template>
				</el-menu-item> -->
				<el-menu-item index="phone_mgtAdmin" class="phone_aside_menu_item" v-show="see_user_data">
					<el-icon size="20px" class="phone_aside_menu_item_icon">
						<Avatar />
					</el-icon>
					<template #title>
						账号管理
					</template>
				</el-menu-item>
<!-- 				<el-menu-item index="customer" class="phone_aside_menu_item">
					<el-icon size="20px" class="phone_aside_menu_item_icon">
						<UserFilled />
					</el-icon>
					<template #title>
						用户信息
					</template>
				</el-menu-item> -->
<!-- 				<el-menu-item index="equipment" class="phone_aside_menu_item">
					<el-icon size="20px" class="phone_aside_menu_item_icon">
						<Operation />
					</el-icon>
					<template #title>
						设备信息
					</template>
				</el-menu-item> -->
				
				
				
			  <!-- <el-menu-item index="patrol" class="phone_aside_menu_item">
			    <el-icon  size="22px" class="phone_aside_menu_item_icon"><Cpu  /></el-icon>
			    <template #title>智能巡检</template>
			  </el-menu-item> -->
			  <el-menu-item index="phone_New_History" class="phone_aside_menu_item">
			    <el-icon size="20px" class="phone_aside_menu_item_icon"><Document /></el-icon>
			    <template #title>历史记录</template>
			  </el-menu-item>
<!-- 			  <el-menu-item index="monitor" class="phone_aside_menu_item">
			    <el-icon size="20px" class="phone_aside_menu_item_icon"><VideoCameraFilled /></el-icon>
			    <template #title>视频监控</template>
			  </el-menu-item> -->
<!-- 			  <el-menu-item index="error" class="phone_aside_menu_item">
			    <el-icon size="20px" class="phone_aside_menu_item_icon"><WarnTriangleFilled /></el-icon>
			    <template #title>异常告警</template>
			  </el-menu-item> -->
			  
			  <!-- <el-menu-item index="documents" class="phone_aside_menu_item" >
			    <el-icon size="20px" class="phone_aside_menu_item_icon"><Folder /></el-icon>
			    <template #title>项目文件
			  				</template>
			  </el-menu-item> -->
			  
			  <el-menu-item index="phone_New_Documents" class="phone_aside_menu_item" >
			    <el-icon size="20px" class="phone_aside_menu_item_icon"><Folder /></el-icon>
			    <template #title>
					项目管理
			  	</template>
			  </el-menu-item>
			  
			  <el-menu-item index="phone_New_Settings" class="phone_aside_menu_item" >
			    <el-icon size="20px" class="phone_aside_menu_item_icon"><setting /></el-icon>
			    <template #title>系统配置
				</template>
			  </el-menu-item>
			  
			  
		  <!-- </div> -->
<!-- 			<el-radio-group v-model="isCollapse"
			class="aside_menu_bottom"
			size="small"
			fill="#065064"
			@click="setMenuTitle"
			>
			    <el-radio-button :label="false">展开</el-radio-button>
			    <el-radio-button :label="true">折叠</el-radio-button>
			</el-radio-group> -->
			
			<!-- <div class="collapse_btn" v-show="!isCollapse">
				<div class="collapse_icon" id="collapse_btn" @click="setMenuShow"></div>
			</div>
			<div class="collapse_btn" v-show="isCollapse">
				<div class="collapse_icon_hide" id="collapse_icon_hide" @click="setMenuShow"></div>
			</div> -->
		</el-menu>
		  
		  
	</div>
</template>

<script>
import { User,Tickets,Setting,ChatLineSquare,WarnTriangleFilled,VideoCameraFilled,Document,Cpu,DataAnalysis,Avatar,UserFilled,Operation,Folder,DataLine,Calendar,Loading,Star} from '@element-plus/icons-vue'
import Header from '../components/Header.vue'
// import new_device_data from'../views/New_Device_Data.vue'
	export default{
		name:"Aside",
		components:{
			Tickets,
			User,
			Setting,
			ChatLineSquare,
			Header,
			WarnTriangleFilled,
			VideoCameraFilled,
			Document,
			Cpu,
			DataAnalysis,
			Avatar,
			UserFilled,
			Operation,
			Folder,
			DataLine,
			Calendar,
			Loading,
			Star,
		},
		data() {
			return{
				isCollapse : false,	
				title:"—— 功能列表 ——",
				index:"Device_Data_1",
				see_user_data:false,
				show_real_time_device_data:false,
				show_status_device_data:false,
				show_maintenance_device_data:false,
				set_system:false,
				aside_menu_isShow:false,
				show_aside_menu_text:"展开导航",
			}
		},
		created(){
			
		},
		watch:{
			// isCollapse:function(val){
			// 	if(val){
			// 		this.title="功能"
			// 		setTimeout(function(){
			// 			document.getElementById("main_layout").style.marginLeft='-140px'
			// 		},100)
					
			// 	}else{
			// 		this.title="—— 功能列表 ——"
			// 		document.getElementById("main_layout").style.marginLeft='0px'
			// 	}
			// 	console.log(this.isCollapse)
			// }
		},
		methods:{
			show_aside_menu(){
				if(this.aside_menu_isShow){
					this.aside_menu_isShow = false
					this.show_aside_menu_text="展开导航"
				}else{
					this.aside_menu_isShow = true
					this.show_aside_menu_text="收起导航"
				}
			},
			
			handleOpen(){
				let index = sessionStorage.getItem("index");
				// console.log(this.index)
				this.index=index;
			},
			handleClose(key, keyPath){
            console.log(key, keyPath);
			},
			handelSelect(index,indexPath){
				this.aside_menu_isShow = false
				this.show_aside_menu_text = "展开导航"
				// sessionStorage.setItem("index",index);
				// console.log(index);console.log(indexPath)
			},
			setMenuShow(){
				
				if(this.isCollapse){
					this.isCollapse=false
					document.getElementById("main_layout").style.marginLeft='0px'
					document.getElementsByClassName("el-tabs__header")[0].style.width="calc(100% )"
					// console.log(document.getElementById("collapse_btn").className="collapse_icon")
				}else{
					this.isCollapse=true
					setTimeout(function(){
						document.getElementById("main_layout").style.marginLeft='-140px'
						document.getElementsByClassName("el-tabs__header")[0].style.width="calc(100% - 140px)"
						// console.log(document.getElementById("collapse_btn").className="collapse_icon_hide")
					},100)
				}
			}

		},
		mounted() {
			// 调用全局的判断用户权限方法
			// 查询用户权限
			this.$GLOBAL.methods.check_permission('check_aside_permission').then(val=>{
				// console.log(val)
				// console.log(val['show_real_time_device_data'])
				// if(val['show_real_time_device_data']=='1'){
				// 	console.log(true)
				// }
				if(val=="500"){
					this.$router.push("/login")
				}else {
					if(val['show_real_time_device_data']=='1'){
						this.show_real_time_device_data=true
					}else{
						this.show_real_time_device_data=false
					}
					if(val['show_status_device_data']=='1'){
						this.show_status_device_data=true
					}else{
						this.show_status_device_data=false
					}
					if(val['show_maintenance_device_data']=='1'){
						this.show_maintenance_device_data=true
					}else{
						this.show_maintenance_device_data=false
					}
					if(val['query_user_data']=='1'){
						this.see_user_data=true
					}else{
						this.see_user_data=false
					}
				}
			})
			
			// console.log(sessionStorage.setItem("index",this.index))
			console.log(window.location.href)
			if(window.location.href.indexOf("main")!=-1){
				this.index="main"
				sessionStorage.setItem("index","main");
			}else if(window.location.href.indexOf("phone_mgtAdmin")!=-1){
				this.index="phone_mgtAdmin"
				sessionStorage.setItem("index","phone_mgtAdmin");
			}else if(window.location.href.indexOf("customer")!=-1){
				this.index="customer"
				sessionStorage.setItem("index","customer");
			}else if(window.location.href.indexOf("equipment")!=-1){
				this.index="equipment"
				sessionStorage.setItem("index","equipment");
			}else if(window.location.href.indexOf("phone_New_History")!=-1){
				this.index="phone_New_History"
				sessionStorage.setItem("index","phone_New_History");
			}else if(window.location.href.indexOf("monitor")!=-1){
				this.index="monitor"
				sessionStorage.setItem("index","monitor");
			}else if(window.location.href.indexOf("error")!=-1){
				this.index="error"
				sessionStorage.setItem("index","error");
			}else if(window.location.href.indexOf("phone_New_Settings")!=-1){
				this.index="phone_New_Settings"
				sessionStorage.setItem("index","phone_New_Settings");
			}else if(window.location.href.indexOf("phone_Device_Data")!=-1){
				this.index="phone_Device_Data"
				sessionStorage.setItem("index","phone_Device_Data");
			}else if(window.location.href.indexOf("Device_Data_2")!=-1){
				this.index="Device_Data_2"
				sessionStorage.setItem("index","Device_Data_2");
			}else if(window.location.href.indexOf("Device_Data_3")!=-1){
				this.index="Device_Data_3"
				sessionStorage.setItem("index","Device_Data_3");
			}else if(window.location.href.indexOf("Device_Data_4")!=-1){
				this.index="Device_Data_4"
				sessionStorage.setItem("index","Device_Data_4");
			}else if(window.location.href.indexOf("Device_Data_5")!=-1){
				this.index="Device_Data_5"
				sessionStorage.setItem("index","Device_Data_5");
			}else if(window.location.href.indexOf("phone_Private_Device_1")!=-1){
				this.index="phone_Private_Device_1"
				sessionStorage.setItem("index","phone_Private_Device_1");
			}
			
			else if(window.location.href.indexOf("Private_Device_2_s")!=-1){
				this.index="Private_Device_2_s"
				sessionStorage.setItem("index","Private_Device_2_s");
			}
			else if(window.location.href.indexOf("phone_Private_Device_2")!=-1){
				this.index="phone_Private_Device_2"
				sessionStorage.setItem("index","phone_Private_Device_2");
			}
			
			else if(window.location.href.indexOf("phone_Private_Device_3")!=-1){
				this.index="phone_Private_Device_3"
				sessionStorage.setItem("index","phone_Private_Device_3");
			}
			else if(window.location.href.indexOf("phone_Private_Device_4")!=-1){
				this.index="phone_Private_Device_4"
				sessionStorage.setItem("index","phone_Private_Device_4");
			}
			else if(window.location.href.indexOf("phone_New_Documents")!=-1){
				this.index="phone_New_Documents"
				sessionStorage.setItem("index","phone_New_Documents");
			}
		}
	}
</script>

<style>
	.progressBar >>> .el-progress-bar__outer {
	  background-color: rgba(255, 255, 255, 0.2);
	}
	/* 进度条的背景色 */
	.progressBar >>> .el-progress-bar__inner {
	  background-color: #fff;
	}
	.phone_aside_menu .collapse_btn{
		position: absolute;
		/* background-color: aqua; */
		text-align: center;
		width: calc(100% - 20px);
		margin-left: 10px;
		height: 40px;
		margin-top: 15px;
		
	}
	.phone_aside_menu .collapse_icon{
		margin-left: calc(50% - 20px );
		background: url(../assets/collapse_icon_1.png) no-repeat;
		background-size: 100% 100% ;
		width: 30px;
		height: 30px;
	}
	.phone_aside_menu .collapse_icon_hide{
		margin-left: calc(50% - 20px );
		background: url(../assets/collapse_icon_2.png) no-repeat;
		background-size: 100% 100% ;
		width: 30px;
		height: 30px;
	}
	.phone_aside_menu .el-sub-menu__title{
		margin-top: 0px !important;
		background-color: rgba(255, 255, 255, 0.0) !important;
		color: #6dfaff !important;
		font-size:17px !important;
	}
	.phone_aside_menu .aside_sub_menu_item{
		margin-top: 0px !important;
		/* margin-left: 50px; */
		transition: margin-left 0.6s 0s;
		border-radius: 10px;
		font-family: 'Courier New', Courier, monospace;
		color: #6dfaff;
		font-size:20px !important;
		/* background-color: #00557f; */
		background-color: rgba(85, 170, 255, 0.2);
	}
	.phone_aside_menu .aside_sub_menu_item:hover{
		margin-left: -10px;
		/* background-color: rgba(85, 170, 255, 0.3); */
		
	}
	.el-menu{
		background-color: rgba(85, 170, 255, 0.0);
		--el-menu-bg-color:rgba(85, 170, 255, 0.0) !important;
		border-right: none !important;
	}
	/* ------------------------------------ */
	.phone_aside{
		font-family: 'Courier New', Courier, monospace;
		color: #6dfaff;
		font-size:20px;
		width: 100%;
		margin-left: 40px;
		/* background-color: #00a1ff; */
		/* height: 50%; */
	}
	
	.phone_aside_menu{
		border-right: none;
		background-color: rgba(2, 51, 65, 0.9) !important;
		color: #6dfaff;
		padding-left: 3px;
		width: 230px;
		
		border-radius: 20px;
		border-bottom-right-radius: 20px;
		border-top-right-radius: 20px;
		box-shadow: 0px 0px 6px 5px rgba(108, 204, 255, 0.9),inset 2px 0px 4px 2px rgba(108, 204, 255, 0.8) ;
			
		/* height: calc(100vh - 60px - 50px - 23px -400px); */
		/* height: 400px; */
		height: 65vh;
		margin-top: 15px !important;
		margin-bottom: 23px !important;
		/* margin-left: 10px; */
		text-align: right !important;
		/* overflow: auto; */
		overflow-x: hidden !important;
		overflow-y: auto !important;
		z-index: 100;
	}
	.phone_aside_menu .el-sub-menu__title{
		margin-top: 0px !important;
	}
	
	.phone_aside_menu .el-menu-item{
		height: 50px !important;
		line-height: 58px !important;
		
	}
	.phone_aside_menu_item{
		margin-top: 10px !important;
		/* margin-left: 50px; */
		transition: margin-left 0.6s 0s;
		border-radius: 10px;
		font-family: 'Courier New', Courier, monospace;
		color: #6dfaff;
		font-size:17px !important;
		/* background-color: #00557f; */
		
	}

	.phone_aside_menu_item_icon{
		margin-left: 40px;
	}
	/* 折叠时宽度 */
	.el-menu--collapse {
	    width: 110px;
	}
	/* 菜单选中时背景颜色 */
	.el-menu-item.is-active {
	    background-color: #002832 !important;
		background-color:  rgba(0, 127, 159, 0.3) !important;
		margin-left: 20px;
		border:2px solid #6dfaff;
		border-right: none;
		/* border-radius: 10px; */
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
	}
	.aside_menu_bottom{
		margin-top: 10%;
		margin-bottom: 20%
	}
	
</style>