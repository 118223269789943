<template>
	<div class="admin_mainLayout">
		<div class="boxBorder1 ">
		    <div class="boxBorder2">
				<el-row>
					<el-col :span="1"></el-col>
				    <el-col :span="6"><div class="phone_main_title_style_b" /></el-col>						
				    <el-col :span="10">
						<div class="phone_main_title_style_a" >
							设备故障回顾
						</div>
					</el-col>
				    <el-col :span="6"><div class="phone_main_title_style_c" /></el-col>
					<el-col :span="1"></el-col>
				</el-row>
				<div class="phone_device_data_5_layout_title">
					<el-row>
						<el-col :span="1"></el-col>
						<el-col :span="11">
							<el-date-picker
									class="phone_date_picker_layout"
							        v-model="select_type_1_time[0]"
							        type="datetime"
							        placeholder="开始时间"
							        format="MM-DD HH:mm:ss"
							        date-format="YYYY/MM/DD "
							        time-format="hh:mm:ss"
									size="small"
									:clearable=false
									@change="select_value_onChange"
							      />
						</el-col>
						<el-col :span="11">
							<el-date-picker
									class="phone_date_picker_layout"
							        v-model="select_type_1_time[1]"
							        type="datetime"
							        placeholder="截止时间"
							        format="MM-DD HH:mm:ss"
							        date-format="YYYY/MM/DD "
							        time-format="hh:mm:ss"
									size="small"
									:clearable=false
									@change="select_value_onChange"
							      />
						</el-col>
						<!-- <el-col :span="22">
							<el-date-picker
									class="phone_date_picker_layout"
							        v-model="select_type_1_time"
							        type="datetimerange"
							        start-placeholder="开始时间"
							        end-placeholder="结束时间"
							        format="YYYY-MM-DD HH:mm:ss"
							        date-format="YYYY/MM/DD "
							        time-format="hh:mm:ss"
									:shortcuts="shortcuts"
									size="small"
									@change="select_value_onChange"
									:clearable=false
							      />								  
						</el-col> -->
						<el-col :span="1"></el-col>
					</el-row>
					<el-row style="margin-top: 4px;">
						<el-col :span="1"></el-col>
						<el-col :span="20" class="phone_error_history_device_position_col">
							<el-select v-model="select_device_id" 
									class="phone_error_history_now_region_select" 
									placeholder="Select" 
									@change="select_value_onChange"
									filterable 
									size="small">
							    <el-option
							      v-for="item in select_options"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value"
								  class="history_select_options_item"
							    />
							</el-select>
						</el-col>
						<!-- <el-col :span="3">
							<div class="device_data2_select_col">
								故障类型:      	 
							</div>
						</el-col>
						<el-col :span="3" >
							<div class="device_data2_select_col" style="margin-left: 10px;">
								<el-input v-model="select_error_data_str"
									placeholder="  " 
									clearable
									@change="error_data_str_On_change"
									/>
							</div>
						</el-col> -->
						<!-- <el-col :span="2"></el-col> -->
						<!-- <el-col :span="7"></el-col> -->
<!-- 						<el-col :span="7">
							<el-date-picker
									class="date_picker_layout"
							        v-model="select_type_1_time"
							        type="datetimerange"
							        start-placeholder="开始时间"
							        end-placeholder="结束时间"
							        format="YYYY-MM-DD HH:mm:ss"
							        date-format="YYYY/MM/DD "
							        time-format="hh:mm:ss"
									:shortcuts="shortcuts"
									size="large"
									@change="select_value_onChange"
									:clearable=false
							      /> -->
								  <!-- value-format="YYYY-MM-DD HH:mm:ss" -->
								  
						<!-- </el-col> -->
						<el-col :span="3">
							<div class="phone_show_error_count_btn"  @click="show_error_count"></div>
						</el-col>
					</el-row>
				</div>
				<!-- count_error_data_isShow -->
				<div class="phone_count_error_data_show_header" 
					@click="close_error_count_show" 
					id="phone_count_error_data_show_header"
					v-show="count_error_data_isShow"
					>
					×
				</div>
				<div class="phone_count_error_data_show_layout" v-show="count_error_data_isShow">

					<div class="phone_count_error_data_show_data_default" v-show="count_error_data_default_isShow">
						<div class="box">
							<div class="box-circle"></div>
							<div class="box-circle1"></div>
							<div class="box-circle2"></div>
						</div>
					</div>
					<div class="phone_count_error_data_show_data" id="count_error_data_show">
						
					</div>
				</div>
				<div class="phone_device_data_5_layout_content" id="item_list" >
					
					<div class="phone_device_data_5_content_item_list" id="device_data_5_content_item_list" > 
		<!-- 						<div class="item_list_connect_line" v-if="now_choose_error_data['choose_list_isShow']">
							
						</div> -->
						<div class="phone_item_list_header" v-if="now_choose_error_data['choose_list_isShow']">
							<div class="phone_item_list_header_time" >
								记录时间：{{now_choose_error_data['error_time']}}								
							</div>
<!-- 							<div class="phone_item_list_header_time">
								设备：{{now_choose_error_data['device_id']}}
							</div> -->
						</div>
						<div class="phone_item_list_data_layout" v-if="now_choose_error_data['choose_list_isShow']">
								<el-row >
									<!-- <el-col :span="1"></el-col> -->
									<!-- <el-col :span="2"></el-col> -->
									<!-- <el-col :span="3" class="item_list_data_type_item_title" >
										序号
									</el-col> -->
									<el-col :span="10" class="phone_item_list_data_type_item_title" >
										内容
									</el-col>
									<el-col :span="10" class="phone_item_list_data_type_item_title">
										地址
									</el-col>
									<el-col :span="4" class="phone_item_list_data_type_item_title">
										数据
									</el-col>
									<!-- <el-col :span="1"></el-col> -->
								</el-row>
								<el-row v-for="i in now_choose_error_data['error_data']" :class="i['value']==0?'':'data_error_row'">
									
									
									<!-- <el-col :span="3" class="item_list_data_type_item" >
										{{i['id']}}
									</el-col> -->
									<el-col :span="10" class="phone_item_list_data_type_item" >
										{{i['title']}}
									</el-col>
									<el-col :span="10" class="phone_item_list_data_type_item">
										{{i['position']}}
									</el-col>
									
									<el-col :span="4" :class="i['value']==0? 'phone_item_list_data_type_item':'phone_item_list_data_error_item'" >
										{{i['value']}}
									</el-col>
								</el-row>
							
							<!-- <el-collapse v-model="main_activeNames" class="item_list_data_collapse">
								<el-collapse-item title="设备实时数据" name="1" >
									<div class="item_list_data_type">
										<el-row >
											<el-col :span="3"></el-col>
											<el-col :span="3" class="item_list_data_type_item_title" >
												序号
											</el-col>
											<el-col :span="4" class="item_list_data_type_item_title" >
												地址
											</el-col>
											<el-col :span="8" class="item_list_data_type_item_title">
												内容
											</el-col>
											<el-col :span="3" class="item_list_data_type_item_title">
												数据
											</el-col>
										</el-row>
										<el-row v-for="i in now_choose_error_data['type_1_data']">
											<el-col :span="3"></el-col>
											<el-col :span="3" class="item_list_data_type_item" >
												{{i['id']}}
											</el-col>
											<el-col :span="4" class="item_list_data_type_item" >
												{{id_position[i['id']]}}
											</el-col>
											<el-col :span="8" class="item_list_data_type_item">
												{{id_content[i['id']]}}
											</el-col>
											<el-col :span="3" class="item_list_data_type_item">
												{{i['value']}}
											</el-col>
										</el-row>
									</div>
								</el-collapse-item>
								<el-collapse-item title="设备状态数据" name="2" >
									<div class="item_list_data_type">
										<el-row >
											<el-col :span="3"></el-col>
											<el-col :span="3" class="item_list_data_type_item_title" >
												序号
											</el-col>
											<el-col :span="4" class="item_list_data_type_item_title" >
												地址
											</el-col>
											<el-col :span="8" class="item_list_data_type_item_title">
												内容
											</el-col>
											<el-col :span="3" class="item_list_data_type_item_title">
												数据
											</el-col>
										</el-row>
										<el-row v-for="i in now_choose_error_data['type_2_data']">
											<el-col :span="3"></el-col>
											<el-col :span="3" class="item_list_data_type_item" >
												{{i['id']}}
											</el-col>
											<el-col :span="4" class="item_list_data_type_item" >
												{{id_position[i['id']]}}
											</el-col>
											<el-col :span="8" class="item_list_data_type_item">
												{{id_content[i['id']]}}
											</el-col>
											<el-col :span="3" class="item_list_data_type_item">
												{{i['value']}}
											</el-col>
										</el-row>
									</div>
								</el-collapse-item>
								<el-collapse-item title="设备维护数据" name="3" >
									<div class="item_list_data_type">
										<el-row >
											<el-col :span="3"></el-col>
											<el-col :span="3" class="item_list_data_type_item_title" >
												序号
											</el-col>
											<el-col :span="12" class="item_list_data_type_item_title">
												内容
											</el-col>
											<el-col :span="3" class="item_list_data_type_item_title">
												数据
											</el-col>
										</el-row>
										<el-row v-for="i in now_choose_error_data['type_3_data']">
											<el-col :span="3"></el-col>
											<el-col :span="3" class="item_list_data_type_item" >
												{{i['id']}}
											</el-col>
											<el-col :span="12" class="item_list_data_type_item">
												{{id_content[i['id']]}}
											</el-col>
											<el-col :span="3" class="item_list_data_type_item">
												{{i['value']}}
											</el-col>
										</el-row>
									</div>
								</el-collapse-item>
							</el-collapse> -->
						</div>
						<div class="phone_no_data_layout" v-if="!now_choose_error_data['choose_list_isShow']">
							暂无数据
						</div>
					</div>
					<div class="phone_device_data_5_content_item_line_main" id="line_main">
						<div class="phone_device_data_5_content_item_line" v-for="i in all_error_history_data"  :id="'device_item_'+(i['id'])">
							<div 
								:class="i['id']==1? 'phone_device_data_5_content_item_choose_btn_choose':'phone_device_data_5_content_item_choose_btn'"
								@click="choose_item_btn_2(i['id'])" 
								:id="'device_item_btn_'+i['id']">
								{{i['id']}}
							</div>
							<div class="phone_device_data_5_content_item_title" 
								:class="i['id']==1? 'phone_device_data_5_content_item_title_choose':'phone_device_data_5_content_item_title'"
								@click="choose_item_btn_2(i['id'])" 
								:id="'device_item_title_'+i['id']">
								{{i['error_time']}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	import request from "@/utils/request";
	import { ElMessage } from 'element-plus'
	export default{
		data() {
			return{
				select_device_id:"",
				select_options:[],
				select_type_1_time:"",
				shortcuts:[
					{
						text: '半小时前',
						value: () => {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 1000 * 60 * 30)
							return [start, end]
						},
					},
					{
						text: '一小时前',
						value: () => {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 1000 * 60 * 60 )
							return [start, end]
						},
					},
					{
						text: '- 10分钟',
						value: () => {
							let that = this
							const end = this.select_type_1_time[1]
							const start = this.select_type_1_time[0]
							start.setTime(start.getTime() - 1000 * 60 * 10)
							that.select_type_1_time[0] = ""
							that.select_type_1_time[1] = ""
							return [start, end]
						},
					},
					{
						text: '+ 10分钟',
						value: () => {
							let that = this
							const end = this.select_type_1_time[1]
							const start = this.select_type_1_time[0]
							start.setTime(start.getTime() + 1000 * 60 * 10)
							that.select_type_1_time[0] = ""
							that.select_type_1_time[1] = ""
							return [start, end]
						},
					},
					{
						text: '- 1小时',
						value: () => {
							let that = this
							const end = this.select_type_1_time[1]
							const start = this.select_type_1_time[0]
							start.setTime(start.getTime() - 1000 * 60 * 60)
							that.select_type_1_time[0] = ""
							that.select_type_1_time[1] = ""
							return [start, end]
						},
					},
					{
						text: '+ 1小时',
						value: () => {
							let that = this
							const end = this.select_type_1_time[1]
							const start = this.select_type_1_time[0]
							start.setTime(start.getTime() + 1000 * 60 * 60)
							that.select_type_1_time[0] = ""
							that.select_type_1_time[1] = ""
							return [start, end]
						},
					},
				],
				all_error_history_data:[],
				now_choose_error_data:{
					"choose_list_isShow":false
				},
				count_error_data_isShow:false,
				main_activeNames:[''],
				id_position:{
					'v1_1':"AIW36",
					'v1_2':"AIW38",
					'v1_3':"AIW52",
					'v1_4':"AIW54",
					'v1_5':"VW108",
					'v1_6':"VW124",
					'v1_7':"Q1.2",
					'v1_8':"Q1.3",
					'v1_9':"Q1.4",
					'v1_10':"Q1.5",
					'v1_11':"Q13.4",
					'v1_12':"Q13.5",
					'v1_13':"Q13.6",
					'v1_14':"Q13.7",
					'v1_15':"Q0.6",
					'v1_16':"M2.0|Q1.3",
					'v1_17':"I9.7|Q13.2|Q13.3",
					'v1_18':"Q0.7",
					'v1_19':"M2.0|Q1.5",
					'v1_20':"I9.7|Q13.0|Q13.1",
					'v1_21':"Q1.0",
					'v1_22':"Q12.6",
					'v1_23':"Q12.7",
					'v2_1':"M2.0",
					'v2_2':"M3.0",
					'v2_3':"M5.0",
					'v2_4':"M5.2",
					'v2_5':"M5.5",
					'v2_6':"M8.0",
					'v2_7':"M8.2",
					'v2_8':"M8.5",
					'v2_9':"M20.0",
					'v2_10':"M22.6",
					'v2_11':"M22.5",
					'v2_12':"M22.7",
					'v2_13':"M24.0",
					'v2_14':"M29.3",
					'v2_15':"M20.1",
					'v2_16':"M31.2",
					'v2_17':"M31.3",
					'v2_18':"M29.2",
					'v2_19':"M29.1",
					'v2_20':"M29.4",
					'v2_21':"M29.5",
					'v2_22':"M29.6",
					'v2_23':"I0.0",
					'v2_24':"I0.1",
					'v2_25':"I0.2",
					'v2_26':"I0.3",
					'v2_27':"I0.4",
					'v2_28':"I0.5",
					'v2_29':"I0.6",
					'v2_30':"I0.7",
					'v2_31':"I2.0",
					'v2_32':"I2.1",
					'v2_33':"I8.0",
					'v2_34':"I8.1",
					'v2_35':"I8.6",
					'v2_36':"I8.7",
					'v2_37':"I9.0",
					'v2_38':"I9.1",
					'v2_39':"I9.2",
					'v2_40':"I9.3",
					'v2_41':"I9.4",
					'v2_42':"I9.5",
					'v2_43':"I9.6",
					'v2_44':"I9.7",
					'v2_45':"Q0.6",
					'v2_46':"Q0.7",
					'v2_47':"Q1.0",
					'v2_48':"Q1.2",
					'v2_49':"Q1.3",
					'v2_50':"Q1.4",
					'v2_51':"Q1.5",
					'v2_52':"Q8.0",
					'v2_53':"Q8.1",
					'v2_54':"Q12.0",
					'v2_55':"Q12.1",
					'v2_56':"Q12.2",
					'v2_57':"Q12.6",
					'v2_58':"Q12.3",
					'v2_59':"Q12.4",
					'v2_60':"Q12.5",
					'v2_61':"Q12.7",
					'v2_62':"Q13.0",
					'v2_63':"Q13.1",
					'v2_64':"Q13.2",
					'v2_65':"Q13.3",
					'v2_66':"Q13.4",
					'v2_67':"Q13.5",
					'v2_68':"Q13.6",
					'v2_69':"Q13.7",
				},
				id_content:{
					'v1_1':"海侧行程模拟量数值",
					'v1_2':"陆侧行程模拟量数值",
					'v1_3':"海侧行程模拟量数值",
					'v1_4':"陆侧行程模拟量数值",
					'v1_5':"海侧行程读值",
					'v1_6':"陆侧行程读值",
					'v1_7':"海侧夹紧状态反馈",
					'v1_8':"海侧松开状态反馈",
					'v1_9':"陆侧夹紧状态反馈",
					'v1_10':"陆侧松开状态反馈",
					'v1_11':"陆侧锚定插入到位反馈",
					'v1_12':"陆侧锚定提起到位反馈",
					'v1_13':"海侧锚定插入到位反馈",
					'v1_14':"海侧锚定提起到位反馈",
					'v1_15':"海侧电机运行时间",
					'v1_16':"海侧运行时间",
					'v1_17':"海侧锚定运行时间",
					'v1_18':"陆侧电机运行时间",
					'v1_19':"陆侧运行时间",
					'v1_20':"陆侧锚定运行时间",
					'v1_21':"故障统计",
					'v1_22':"海侧故障",
					'v1_23':"陆侧故障",
					'v2_1':"远程松指令",
					'v2_2':"远程紧指令",
					'v2_3':"陆侧锚定接触器故障",
					'v2_4':"陆侧锚定抬起超时",
					'v2_5':"陆侧锚定落下超时",
					'v2_6':"海侧锚定接触器故障",
					'v2_7':"海侧锚定抬起超时",
					'v2_8':"海侧锚定落下超时",
					'v2_9':"传感器断线1M故障",
					'v2_10':"海侧夹紧超时M",
					'v2_11':"海侧打开超时M",
					'v2_12':"电机1接触器故障M",
					'v2_13':"海侧补压过多M",
					'v2_14':"海侧空开跳闸",
					'v2_15':"传感器断线2M故障",
					'v2_16':"陆侧松开超时M",
					'v2_17':"陆侧夹紧超时M",
					'v2_18':"电机2接触器故障M",
					'v2_19':"陆侧补压过多M",
					'v2_20':"陆侧空开跳闸",
					'v2_21':"plc扩展模块1故障",
					'v2_22':"plc扩展模块2故障",
					'v2_23':"远程/本地",
					'v2_24':"海侧松开",
					'v2_25':"海侧夹紧",
					'v2_26':"陆侧松开",
					'v2_27':"陆侧夹紧",
					'v2_28':"旁路",
					'v2_29':"海侧空开",
					'v2_30':"陆侧空开",
					'v2_31':"远程松开",
					'v2_32':"远程夹紧",
					'v2_33':"陆侧锚定空开",
					'v2_34':"海侧锚定空开",
					'v2_35':"陆侧锚定电机1热继动作",
					'v2_36':"陆侧锚定电机2热继动作",
					'v2_37':"海侧锚定电机1热继动作",
					'v2_38':"海侧锚定电机2热继动作",
					'v2_39':"陆侧锚定提起",
					'v2_40':"陆侧锚定插入",
					'v2_41':"海侧锚定提起",
					'v2_42':"海侧锚定插入",
					'v2_43':"远程锚定提起",
					'v2_44':"远程锚定插入",
					'v2_45':"M1电机得电",
					'v2_46':"M2电机得电",
					'v2_47':"故障",
					'v2_48':"海侧夹紧状态反馈",
					'v2_49':"海侧松开状态反馈",
					'v2_50':"陆侧夹紧状态反馈",
					'v2_51':"陆侧松开状态反馈",
					'v2_52':"电气故障指示灯",
					'v2_53':"液压故障指示灯",
					'v2_54':"海侧液位低报警",
					'v2_55':"海侧高温报警",
					'v2_56':"海侧液压站堵塞报警",
					'v2_57':"海侧电气故障",
					'v2_58':"陆侧液位低报警",
					'v2_59':"陆侧高温报警",
					'v2_60':"陆侧液压站堵塞报警",
					'v2_61':"陆侧电气故障",
					'v2_62':"陆侧锚定正转中继",
					'v2_63':"陆侧锚定反转中继",
					'v2_64':"海侧锚定正转中继",
					'v2_65':"海侧锚定反转中继陆侧锚定插入到位反馈",
					'v2_66':"陆侧锚定插入到位反馈",
					'v2_67':"陆侧锚定提起到位反馈",
					'v2_68':"海侧锚定插入到位反馈",
					'v2_69':"海侧锚定提起到位反馈",
					'v3_1':"金属结构有无严重变形及裂纹、焊缝有无开裂等情况",
					'v3_2':"各部位的螺栓紧固情况，如法兰面螺栓、复位支架、液压站、电控箱、锚定机构连接螺栓等",
					'v3_3':"导向滚轮能否顺利转动，踏面磨损情况",
					'v3_4':"清槽板、开合机构/复位弹簧是否完好，是否有零件缺失，如夹紧块",
					'v3_5':"油缸的伸缩动作是否正常，夹钳转动是否灵活",
					'v3_6':"锚定机构是否正常，如销轴是否灵活，限位开关、自动锚定是否正常等",
					'v3_7':"线缆连接是否松动，传感器是否正常，电控柜各指示灯是否正常",
					'v3_8':"液压站的油位，油缸以及液压回路是否存在漏油情况，油缸保压是否正常",
					'v3_9':"轨道及轨道沟槽是否满足使用要求",
					'v3_10':"是否定期加注润滑油脂(每个月一次)",
				},
				select_error_data_str:"",
				error_Chart:"",
				count_error_data_default_isShow:false,
				count_error_data_show_option :{
				// 图表的标题
					title:{
						text:"故障统计",
						left:"5px",
						top:"5px",
						textStyle: {    // 标题样式
						    color: '#ffffff',    //字体颜色
						  },
					},
					grid:{
						bottom:'160',
						left: "60px",
					},
					toolbox: {
						show: true,
						itemSize: 18,  //icon大小
						right: '10px',  
						feature: {						  
							// 数据视图只读
							dataView: { 
								readOnly: true ,
								backgroundColor: '#55aaff',
								show: true,
								iconStyle:{
									// color: "#55ffff", 
									borderColor: "#ffffff",
									borderWidth: 1.2,
									opacity: 1  //图形透明度
								},
								// hover
								emphasis: {
									iconStyle:{
										borderColor: "#55ffff",
										borderWidth: 1.5,
										opacity: 1  ,//图形透明度
										// textPosition: 'top',  //文本位置，属性值：left/right/top/bottom
								        // textAlign: 'left',  //文本对齐方式，属性值：left/center/right
										Size:17
									}
								},
							},
							// 魔法类型
							// magicType: { type: ['line', 'bar'] },
							//  重置
							restore: {
								backgroundColor: '#55aaff',
								show: true,
								iconStyle:{
									// color: "#55ffff", 
									borderColor: "#ffffff",
									borderWidth: 1.2,
									opacity: 1  //图形透明度
								},
								// hover
								emphasis: {
									iconStyle:{
										borderColor: "#55ffff",
										borderWidth: 1.5,
										opacity: 1  ,//图形透明度
										// textPosition: 'top',  //文本位置，属性值：left/right/top/bottom
								        // textAlign: 'left',  //文本对齐方式，属性值：left/center/right
										Size:17
									}
								},
							},
							  
					
						},
					},
					// 图表的提示
					tooltip:{				
						show: true,
					    trigger: 'item',    
						// 触发类型（'item'，数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用；'axis'，坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用；'none'，不触发。）
						backgroundColor: "rgba(85, 170, 255, 1)",
						borderColor: "rgba(255, 255, 255, 1.0)",
						padding: [10, 15],
						textStyle: {
							color: "#fff",
							fontSize: 17, 
						},
						axisPointer: {
							type: "cross",
							axis: 'auto', // 指示器的坐标轴。
							snap: true, // 坐标轴指示器是否自动吸附到点上  
							
							crossStyle: {
								color: "#55ffff",
							},
							lineStyle: {
								color: "#55ffff",
							},
							color: "#55ffff",
							label:{
								show: true,  //是否显示文本标签
								color: '#55ffff',  
								backgroundColor: 'rgba(85, 170, 255, 1)',  //背景颜色，默认是和axis.axisLine.lineStyle.color 相同
							    fontSize: 16,  
								precision:0

							},
							
						},
						triggerOn: 'mousemove|click',
						// alwaysShowContent: true,
						formatter: '{b0}<br/>{a0} : {c0}',
						formatter: (params) => {
							// console.log(params)
							let str = `${params.name}
							<div>
								<div style="width: 10px;
											height: 10px;
											background-color: #00ff7f;
											border-radius: 20px;
											margin-top: 5px;
											float:left">
								</div>
								<div style="float:right;margin-left: 5px;">
									${params.seriesName}  :  ${params.data}
								</div>
							</div>
							`
							return str
						},
						extraCssText: 'box-shadow: 0 0 4px 4px rgba(85, 255, 255, 0.8);',  //额外附加到浮层的 css 样式
					
					},
					// 图例
					legend:{data:[""]},

					// x轴线
					xAxis:{
						show:true,
						axisTick: {
							show: false,  //隐藏刻度线
						},
						data:[
							],
						axisLine: {
						    lineStyle: {
					            color: "rgb(255, 255, 255)",
								
				            }
				        },
						axisLabel: {
						    color: "rgb(255, 255, 255)", // y 轴文字颜色
						    formatter(v){
								let str = ""
								for(let i in v){
									// console.log(i)
									str = str + v[i] + "\n"
									
								}
								return str
								
							},
							// 间隔 显示
							// interval:3,
							fontSize: 14,  
							// lineHeight:'100'
							// rotate: 40,//倾斜度数
				
						},
					},
					// y轴线
					yAxis:this.$GLOBAL.public_yAxis,
					// 设置数据
					series:[
						{
							// 数据名称
							name:"故障统计",
							type:"bar",
							// 数据data
							data:[
								],
							color:"#ffaa00",
							label: { //鼠标放上去显示数值
							    show: true,
								color: '#ffffff',
								fontSize: 13,  
							    fontWeight: 'bold',
							    // offset: [5, -3],
							    align: 'center',
								verticalAlign: 'middle',	
								// formatter(v){
								// 	return v.data==1?"是":"否"
								// },
							},
						},
					],
					animationDelay: function () {
					},
					animationDuration:function(){
					},
				},
			}
		},
		methods:{
			error_data_str_On_change(){
				this.get_error_history_data_2()
			},
			choose_item_btn(i){
				let content_obj = document.getElementById("device_data_5_content_item_list")
				// let btn_obj = document.getElementById("device_item_"+i)
				let main_obj = document.getElementById("item_list")
				// let old_left = parseInt(content_obj.style.marginLeft.replace("px","")) 
				// let old_index = (old_left-148)/100
				main_obj.scrollLeft=i*140
				content_obj.style.marginLeft=(8+i*140)+"px"
				
				let choose_data = this.all_error_history_data[i-1]

				let type_1_data = {}
				let type_2_data = {}
				let type_3_data = {}
				for(let i in choose_data){
					let msg = {
						"id":i,
						"value":choose_data[i]
					}
					if(i.indexOf("v1_")!=-1){
						type_1_data[i] = msg
					}
					if(i.indexOf("v2_")!=-1){
						type_2_data[i] = msg
					}
					if(i.indexOf("v3_")!=-1){
						type_3_data[i] = msg
					}
				}
				this.now_choose_error_data['type_1_data'] = type_1_data
				this.now_choose_error_data['type_2_data'] = type_2_data
				this.now_choose_error_data['type_3_data'] = type_3_data
				this.now_choose_error_data['id'] = choose_data['id']
				this.now_choose_error_data['choose_list_isShow'] = choose_data['choose_list_isShow']
				this.now_choose_error_data['device_id'] = choose_data['device_id']
				this.now_choose_error_data['error_time'] = choose_data['error_time']
				this.now_choose_error_data['error_type'] = choose_data['error_type']
				// console.log(this.now_choose_error_data)
				// this.now_choose_error_data = choose_data
			},
			choose_item_btn_2(i){
				let content_obj = document.getElementById("device_data_5_content_item_list")
				let main_obj = document.getElementById("item_list")
				// console.log(i)
				// console.log("device_item_btn_"+i)
				for(let j in this.all_error_history_data){
					let choose_btn_id = "device_item_btn_"+(parseInt(j)+1)
					let choose_title_id = "device_item_title_"+(parseInt(j)+1)
					let choose_btn_obj = document.getElementById(choose_btn_id)
					let choose_title_obj = document.getElementById(choose_title_id)
					
					if(parseInt(j)+1 == parseInt(i)){
						console.log(i)
						if(choose_btn_obj){
							document.getElementById(choose_btn_id).className="phone_device_data_5_content_item_choose_btn_choose"
						}
						if(choose_title_obj){
							document.getElementById(choose_title_id).className="phone_device_data_5_content_item_title_choose"
						}
					}else{
						if(choose_btn_obj){
							document.getElementById(choose_btn_id).className="phone_device_data_5_content_item_choose_btn"
						}
						if(choose_title_obj){
							document.getElementById(choose_title_id).className="phone_device_data_5_content_item_title"
						}
					}
					
				}
				main_obj.scrollLeft=i*120
				content_obj.style.marginLeft=(8+i*120)+"px"
				let choose_data = this.all_error_history_data[i-1]
				// console.log(choose_data)
				// 初始化数据
				let error_data = []
				// let count = 0
				// for(let i in choose_data){
				// 	count ++
				// }
				// count = (count -4)/3
				// for(let i = 0;i<count;i++){
				// 	error_data[i]={
				// 		"id":"--",
				// 		"title":"",
				// 		"position":"",
				// 		"value":""
				// 	}
				// }
				let count_i = 1
				for(let j in choose_data){
					if (j!="choose_list_isShow" && j!="error_time" && j!="id"){
						error_data.push({
							// "id":count_i,
							"title":choose_data[j]['content'],
							"position":choose_data[j]['position'],
							"value":choose_data[j]['value']
						})
						count_i++
					}

				}
				
				// // 解析数据
				// for(let i in choose_data){
				// 	let data_index = parseInt(i.split("_")[0].replace("v",""))-1
				// 	if(i!="device_id"&&i!="error_time"&&i!="id"){
				// 		if(i.indexOf("title")!=-1){
				// 			error_data[data_index]['title']=choose_data[i]
				// 		}else if(i.indexOf("position")!=-1){
				// 			error_data[data_index]['position']=choose_data[i]
				// 		}else if(i.indexOf("value")!=-1){
				// 			error_data[data_index]['value']=choose_data[i]
				// 		}
				// 	}
				// }
				
				this.now_choose_error_data['id'] = choose_data['id']
				this.now_choose_error_data['choose_list_isShow'] = choose_data['choose_list_isShow']
				this.now_choose_error_data['device_id'] = this.select_device_id
				this.now_choose_error_data['error_time'] = choose_data['error_time']
				this.now_choose_error_data['error_data'] = error_data
				// console.log(error_data)
				
			},
			// 获取用户配置信息
			get_init_settings_data(){
				request.get(this.$GLOBAL.flask_web_local_url+'/get_init_settings?username='+sessionStorage.getItem('user')).then(res => {
					// console.log(this.select_options)
					this.select_device_id = this.select_options[0]['value']					
					for(let i in this.select_options){
						if(this.select_options[i]['value']==res['init_device_id']){
							this.select_device_id = res['init_device_id']
							this.now_region_position = this.select_options[i]['region']
						}
					}
					this.get_error_history_data_2()
				})
			},
			select_value_onChange(){
				this.get_error_history_data_2()
				if(this.count_error_data_isShow){
					this.get_count_error_history_data_2()
				}
			},
			get_all_device_info(){
				request.post(this.$GLOBAL.flask_web_local_url+'/get_private_device_info',{"username":sessionStorage.getItem('user')}).then(res => {
					for(let i in res){
						let info_data = {
							'value':res[i]['device_id'],
							// 'label':res[i]['device_id']+" -- "+res[i]['address'],
							'label':res[i]['address']+" -- "+res[i]['region'],
							'region':res[i]['region'],
							'address':res[i]['address'],
						}
						this.select_options.push(info_data)
					}
					// 通过服务器获取默认选择的区域设备id  *****************************
					this.get_init_settings_data()
				})
			},
			// 获取当前时间进行初始化
			init_now_time(){
				let date = new Date()							
				// 推算时间范围
				let time_key = date.getTime() - 30 * 60 * 1000
				let old_date = new Date(time_key)							
				this.select_type_1_time=[old_date,date]
				// console.log(this.select_type_1_time)
			},
			// 获取故障信息
			get_error_history_data(){
				let begin_time = this.select_type_1_time[0].getFullYear()+"-"
							+(this.select_type_1_time[0].getMonth()+1)+"-"
							+this.select_type_1_time[0].getDate()+" "
							+this.select_type_1_time[0].toTimeString().substring(0,8);
				let end_time = this.select_type_1_time[1].getFullYear()+"-"
							+(this.select_type_1_time[1].getMonth()+1)+"-"
							+this.select_type_1_time[1].getDate()+" "
							+this.select_type_1_time[1].toTimeString().substring(0,8);
				let msg = {
					"device_id":this.select_device_id,
					"begin_time":begin_time,
					"end_time": end_time,
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/select_error_history_data',msg).then(res => {
					this.all_error_history_data = []
					for(let i in res){
						res[i]['choose_list_isShow'] = true
						this.all_error_history_data.push(res[i])
					}
					let data_length = this.all_error_history_data.length
					document.getElementById("line_main").style.width=(data_length*140+48+600+900)+"px"
					
					// 初始化一次
					if(!this.now_choose_error_data['choose_list_isShow']){
						this.choose_item_btn(1)
					}else{
						this.choose_item_btn(this.now_choose_error_data['id']+1)
					}
					// console.log(this.all_error_history_data)
				})
			},
			close_error_count_show(){
				this.count_error_data_isShow = false
			},
			show_error_count(){
				// 显示图表窗口
				if(!this.count_error_data_isShow){
					this.count_error_data_isShow = true
				}
				this.count_error_data_default_isShow=true
				setTimeout(() => {
					// 延时初始化，避免资源未加载
					if(!this.$echarts.getInstanceByDom(document.getElementById("count_error_data_show"))){
						this.error_Chart = this.$echarts.init(document.getElementById('count_error_data_show'));					
						}
					this.get_count_error_history_data_2()
					
				},200)
			},
			get_count_error_history_data_2(){
				let data = []
				let x_data = []
				console.log(this.all_error_history_data)
				for(let i in this.all_error_history_data[0]){
					if(i!="choose_list_isShow"&&i!="error_time"&&i!="id"){
						console.log(this.all_error_history_data[0][i])
						x_data.push(this.all_error_history_data[0][i]['content'])
						data.push(0)
					}
				}
				// console.log(data)
				// console.log(x_data)
				
				document.getElementById("count_error_data_show").style.width = parseInt(x_data.length)*100+"px"
				// document.getElementById("phone_count_error_data_show_header").style.width = parseInt(x_data.length)*100+"px"
				this.error_Chart.dispose()
				this.error_Chart = this.$echarts.init(document.getElementById("count_error_data_show"))
				this.error_Chart.setOption(this.count_error_data_show_option)
				
				for(let j in this.all_error_history_data){
					// console.log(j)
					let count_index=0
					for(let k in this.all_error_history_data[j]){
						if(k!="choose_list_isShow"&&k!="error_time"&&k!="id"){
							// console.log(this.all_error_history_data[j][k])
							// console.log(count_index)
							if(this.all_error_history_data[j][k]['value']=="1"){
								data[count_index]++
								data[count_index] = data[count_index]++
								console.log(data)
							}
							count_index++
						}
						

						
					}
				}
				console.log(data)
				console.log(x_data)
				
				let begin_time = this.select_type_1_time[0].getFullYear()+"-"
							+(this.select_type_1_time[0].getMonth()+1)+"-"
							+this.select_type_1_time[0].getDate()+" "
							+this.select_type_1_time[0].toTimeString().substring(0,8);
				let end_time = this.select_type_1_time[1].getFullYear()+"-"
							+(this.select_type_1_time[1].getMonth()+1)+"-"
							+this.select_type_1_time[1].getDate()+" "
							+this.select_type_1_time[1].toTimeString().substring(0,8);
				let msg = {
					"device_id":this.select_device_id,
					"begin_time":begin_time,
					"end_time": end_time,
				}
				request.post(this.$GLOBAL.flask_web_local_url+'/count_error_history_data_2',msg).then(res => {
					console.log(res)
					// 解析成数组 {} => []
					for(let i in res){
						x_data.push(res[i]['content'])
						data.push(res[i]['value'])
					}
					// 降序排列
					let new_data = data
					let new_x_data = x_data
					for(let i = 0; i<data.length; i++){
						let max_value_index = i
						for(let j = i+1; j<data.length; j++){
							if(data[j]>data[max_value_index]){
								max_value_index = j
							}
						}
						let temp = new_data[i]
						new_data[i] = new_data[max_value_index]
						new_data[max_value_index] = temp
						
						let x_temp = new_x_data[i]
						new_x_data[i] = new_x_data[max_value_index]
						new_x_data[max_value_index] = x_temp

					}
					this.count_error_data_show_option['xAxis']['data']= x_data
					this.count_error_data_show_option['series'][0]['data'] = data
					this.error_Chart.setOption(this.count_error_data_show_option)
					this.count_error_data_default_isShow=false
				})
			},
			get_error_history_data_2(){
				let begin_time = this.select_type_1_time[0].getFullYear()+"-"
							+(this.select_type_1_time[0].getMonth()+1)+"-"
							+this.select_type_1_time[0].getDate()+" "
							+this.select_type_1_time[0].toTimeString().substring(0,8);
				let end_time = this.select_type_1_time[1].getFullYear()+"-"
							+(this.select_type_1_time[1].getMonth()+1)+"-"
							+this.select_type_1_time[1].getDate()+" "
							+this.select_type_1_time[1].toTimeString().substring(0,8);
				let msg = {
					"device_id":this.select_device_id,
					"begin_time":begin_time,
					"end_time": end_time,
				}
				console.log(this.select_type_1_time)
				// request.post(this.$GLOBAL.flask_web_local_url+'/select_error_history_data_2',msg).then(res => {
				// 	this.all_error_history_data = []
				// 	if(JSON.stringify(res)!="{}"){
				// 		for(let i in res){
				// 			if(this.select_error_data_str==""){
				// 				res[i]['choose_list_isShow'] = true
				// 				this.all_error_history_data.push(res[i])
				// 			}else{

				// 			}

				// 		}
				// 		let data_length = this.all_error_history_data.length
				// 		document.getElementById("line_main").style.width=(data_length*140+48+600+900)+"px"
				// 		// console.log(this.all_error_history_data)
				// 		// 初始化一次
				// 		if(!this.now_choose_error_data['choose_list_isShow']){
				// 			this.choose_item_btn_2(1)
				// 		}else{
				// 			this.choose_item_btn_2(this.now_choose_error_data['id']+1)
				// 		}
				// 	}else{
				// 		this.now_choose_error_data['choose_list_isShow'] = false
				// 	}
				// })
				
				request.post(this.$GLOBAL.flask_web_local_url+'/select_error_history_data_3',msg).then(res => {
					this.all_error_history_data = []
					// console.log(res)
					let count = 0
					if(JSON.stringify(res)!="{}"){
						for(let i in res){
							count++
							let msg_data = {
								"error_time":i,
								"id":count,
							}
							for(let j in res[i]){
								msg_data[j] = {
									"content":res[i][j]['content'],
									"position":j,
									"value":res[i][j]['value'],
								}
							}

							msg_data['choose_list_isShow'] = true
							// console.log(res[i])
				// 			if(this.select_error_data_str==""){
								res[i]['choose_list_isShow'] = true
								this.all_error_history_data.push(msg_data)
				// 			}else{
				
				// 			}
						// console.log(this.all_error_history_data)
						}
						let data_length = this.all_error_history_data.length
						document.getElementById("line_main").style.width=(data_length*140+48+600+900)+"px"
						// console.log(this.all_error_history_data)
						// 初始化一次
						if(!this.now_choose_error_data['choose_list_isShow']){
							this.choose_item_btn_2(1)
						}else{
							this.choose_item_btn_2(this.now_choose_error_data['id']+1)
						}
					}else{
						this.now_choose_error_data['choose_list_isShow'] = false
					}
				})
			},
		},
		mounted(){
			// this.get_init_settings_data()
			this.get_all_device_info()
			this.init_now_time()
			
			// document.getElementById("device_data_5_content_item_list").style.marginLeft="148px"
			// 旧版，三类型
			// this.get_error_history_data()
			// 新版，错误类型
			// this.get_error_history_data_2()
		}
		
	}
</script>

<style>
	.phone_device_data_5_layout_title{
		margin-bottom: 10px;
		background-color: #00557f;	
		background-color: rgba(19, 77, 88, 0.4);
		border-radius: 10px;
		border: 1px solid #77f7f8;
		box-shadow: 0px 0px 3px 2px #6dfaff;
		margin-top: 8px;
		margin-left: 5px;
		height: 68px;
		/* height: 8%; */
		min-height: 40px;
		width: calc(100% - 10px);
		
		
		
	}
	.phone_device_data_5_layout_content{
		margin-left: 5px;
		width: calc(100% - 10px);
		height: calc(100vh - 220px);
		/* background-color: rgba(19, 77, 88, 0.4); */
		/* overflow-y: hidden; */
		box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
		overflow-x: scroll;
		/* padding-left: 50px; */
		
		
	}
	.phone_device_data_5_content_item_list{
		position: relative;
		margin-top: 8px;
		/* width: 1250px; */
		width: calc(100% - 15px);
		height: calc(100% - 80px);
		background-color: rgba(85, 255, 255, 0.2);
		box-shadow: 0px 0px 5px 5px #00aaff ,inset 0px 0px 10px 3px #00aaff;
		border-radius: 20px;
		/* 在mounted中初始化 */
		/* margin-left: 348px; */
		overflow-y: auto;
	}
	.item_list_connect_line{
		position: absolute;
		/* position: relative; */
		margin-top: 457px;
		/* bottom: 0px; */
		margin-left: 500px;
		width: 5px;
		height: 30px;
		/* background-color: #55ffff; */
		border-radius: 5px;
		box-shadow: 0px 0px 5px 5px #00aaff ,inset 0px 0px 10px 3px #55ffff;
	}
	.phone_device_data_5_content_item_line_main{
		/* position: absolute; */
		/* width: ; */
		width: 10000px;
		height: 4px;
		/* width: 4px;
		height: 10000px; 
		padding-top: 650px;
		*/
		background-color: rgba(255, 255, 255, 0.4);
		margin-left: -50px;
		margin-top: 15px;
		padding-left: 220px;
	}
	.phone_device_data_5_content_item_line{
		height: 4px;
		width: 120px;
		
		/* height: 100px;
		width: 4px; */
		display: inline-block;
		float: left;
		background-color: #55aaff;
	}
	.phone_device_data_5_content_item_choose_btn{
		height: 20px;
		width: 20px;
		line-height: 20px;
		color: #000000;
		font-size: 12px;
		margin-left: calc(40px - 10px);
		border-radius: 15px;
		background-color: #ffffff;
		margin-top: -10px;
	}
	.phone_device_data_5_content_item_choose_btn_choose{
		height: 20px;
		width: 20px;
		line-height: 20px;
		color: #000000;
		font-size: 12px;
		margin-left: calc(40px - 10px);
		border-radius: 15px;
		background-color: #55ffff;
		margin-top: -10px;
	}
	.phone_device_data_5_content_item_title{
		margin-left: -10px;
		margin-top: 2px	;
		width: 100px;
		height: 40px;
		line-height: 20px;
		font-size: 16px;
		color: #ffffff;
		background-color: rgba(171, 171, 171, 0.5);
		box-shadow: 0px 0px 5px 2px #ffffff ,inset 0px 0px 10px 1px #ffffff;
		border-radius: 15px;
		
	}
	.phone_device_data_5_content_item_title_choose{
		margin-left: -10px;
		margin-top: 2px	;
		width: 100px;
		height: 40px;
		line-height: 20px;
		font-size: 16px;
		color: #55ffff;
		background-color: rgba(171, 171, 171, 0.5);
		box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
		border-radius: 15px;
		
	}
	.phone_item_list_header{
		width: 100%;
		height: 25px;
		background-color: rgba(0, 170, 255, 0.8);
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		/* border-bottom-left-radius: 10px; */
		/* border-bottom-right-radius: 10px; */
	}
	.phone_item_list_header_time{
		line-height: 25px;
		height: 25px;
		width: 100%;
		font-size: 17px;
		font-weight: 700;
		float: left;
		color: #ffffff;
		overflow-x: hidden !important;;
		overflow-y: auto !important;
	}
	.phone_item_list_data_layout{
		margin-left: 1px;
		width: calc(100% - 1px - 1px);
		height: calc(100% - 30px - 5px);
		/* background-color: #00557f; */
		/* box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff; */
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		overflow: auto;
	}
	.item_list_data_collapse{
		margin-left: 30px;
		width: calc(100% - 60px);
	}
	.item_list_data_type{
		box-shadow: 0px 0px 5px 2px #6cccff ,inset 0px 0px 10px 1px #6cccff;
		width: calc(100% - 80px);
		height: calc((100vh - 100px)*0.7);
		margin-bottom: -20px;
		margin-left: 40px;
		color: #55aaff;
		text-align: left;
		padding: 10px;
		font-size: 16px;
		overflow: auto;
		background-color: rgba(0, 170, 255, 0.2);
		
	}
	.phone_no_data_layout{
		font-size: 55px;
		/* line-height: 400px; */
		/* line-height: 60vh; */
		color: #55ffff;
	}
	.data_type_3_expand_item_2{
		/* text-align: left; */
		height: 30px;
		line-height: 30px;
		font-size: 17px;
		font-weight: 500;
		border-bottom: 1px solid #ffffff;
	}
	.phone_item_list_data_type_item_title{
		height: 25px;
		line-height: 25px;
		font-size: 16px;
		font-weight: 600;
		border-bottom: 1px solid #ff5500;
		color: #ffffff;
		color: #ffffff;
	}
	.phone_item_list_data_type_item{
		height: 32px;
		line-height: 30px;
		font-size: 16px;
		font-weight: 500;
		border-bottom: 1px solid #ffffff;
		color: #55ffff;
		overflow-y: auto;
	}
	.phone_item_list_data_error_item{
		height: 32px;
		line-height: 30px;
		font-size: 16px;
		font-weight: 600;
		border-bottom: 1px solid #ffffff;
		color: #ff5500;
		overflow-y: auto;
	}
	.data_error_row{
		background-color: rgba(170, 170, 0, 0.2);
	}
	.phone_show_error_count_btn{
		/* margin-top: 4px; */
		/* margin-left: -8px; */
		/* background: url(../assets/show_map_btn.png) no-repeat; */
		background: url(../assets/sort_chart.png) no-repeat !important;
		background-size: 30px 30px !important;
		width: 30px;
		height: 30px;
	}
	.phone_count_error_data_show_layout{
		position: absolute;
		z-index: 99;
		margin-left: 10px;
		margin-top: 60px;
		width: calc(100% - 20px);
		height: calc(100% - 150px - 30px);
		/* border-radius: 30px; */
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
		background-color: rgba(0, 85, 127, 0.98);
		box-shadow: 0px 0px 5px 5px rgba(85, 255, 255, 0.9) ,inset 0px 0px 10px 5px rgba(85, 255, 255, 0.9);
		z-index: 98;
		overflow: auto;
		display: flex;
		align-items: center; 
		justify-content: center;
	}
	.phone_count_error_data_show_header{
		margin-left: 10px;
		width: calc(100% - 20px);
		height: 30px;
		background-color: rgba(255, 255, 255, 0.95);
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		font-size: 50px;
		line-height: 30px;
		color: #ffaa00;
		position: absolute;
		z-index: 99;
		margin-top: 30px;
	}
	.count_error_data_show_header:hover{
		background-color: rgba(255, 0, 0, 0.5);
	}
	.phone_count_error_data_show_data{
		/* margin-left: 20px; */
		/* margin-top: 5px; */
		/* width: calc(100% - 20px); */
		/* height: calc(100% - 50px - 35px); */
		/* height: 450px; */
		width: 800px;
		height: calc(100% - 30px);
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
 
		/* width: 1500px; */
		/* padding-left: 40px; */
		/* overflow: auto; */
	}
	.phone_count_error_data_show_data_default{
		position: absolute;
		z-index: 99;		
		width: 100%;
		/* margin-left: 20px;
		margin-top: 15px; */
		height: calc(100% - 0px );
		background-color: rgba(0, 0, 0, 0.5);
		/* border-bottom-left-radius: 30px; */
		/* border-bottom-right-radius: 30px; */
		padding-top: 3%;
		overflow: hidden;
	}
	.phone_date_picker_layout{
		margin-top: 4px;
		
	}
	.phone_device_data_5_layout_title .el-date-editor :nth-child(n){
		font-size: 15px !important;
	}
	.phone_device_data_5_layout_title .el-range-separator{
		width: 20px	!important;
		font-size: 10px !important;
	}
	.phone_error_history_device_position_col{
		height: 100%;
		/* background-color: #6dfaff; */
		color: #6dfaff;
		line-height: 30px;
		font-size: 17px;
		/* margin-top: 5px; */
		
	}
	.phone_error_history_now_region_select{
		/* margin-top: -5px; */
		/* margin-left: -100px; */
		width: 100%;
	}
	.phone_device_data_5_layout_title .el-input__prefix-inner>:last-child{
		margin-right: 0px !important;
	}
	.el-date-picker__header-label{
		color: #55ffff !important;
	}
	.el-date-table th{
		color: #ffffff !important;
	}
	.el-date-table td.next-month, .el-date-table td.prev-month{
		color: #ffffff !important;
	}
</style>