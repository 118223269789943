<template>
</template>

<script>
	import request from "@/utils/request";
	import { ElMessage } from 'element-plus'
	// tab页签索引
	let device_tabs_index = "0";
	// 后端服务器地址
	// let flask_web_url = "http://192.168.1.77:7770/device";
	let flask_web_url = SERVER_URL+"/device";
	// let flask_web_url = "http://120.26.82.44:7770/device";
	let flask_web_local_url = flask_web_url;
	// flask_web_local_url = flask_web_url
	let public_yAxis = {
						minInterval: 1,
						axisLine: {  
						    lineStyle: {
						        color: "rgb(255, 255, 255)",
						    }
					    },
						axisLabel: {
						    color: "rgb(255, 255, 255)", // y 轴文字颜色
						    formatter: '{value}  ',
							fontSize: 14,  
						},

					}
	let public_dataZoom = [{
					    type: 'slider', // inside ,slider 
					    throttle: '50', //设置触发视图刷新的频率。单位为毫秒（ms）。
					    minValueSpan: 6, //用于限制窗口大小的最小值,在类目轴上可以设置为 5 表示 5 个类目
					    start: 0, //数据窗口范围的起始百分比 范围是：0 ~ 100。表示 0% ~ 100%。
					    end: 10, //数据窗口范围的结束百分比。范围是：0 ~ 100。
					    zoomLock: false, //如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
						
						// backgroundColor: 'rgba(255, 255, 255, 1)',//组件的背景颜色
						selectedDataBackground: {//选中部分数据阴影的样式
							lineStyle: {
								color: 'rgba(0, 255, 127, 1.0)',
							},//选中部分阴影的线条样式
							areaStyle: {
								color: 'rgba(0, 255, 127, 1.0)',
							},//选中部分阴影的填充样式
						},
						fillerColor: 'rgba(170, 0, 0, 0.2)',//选中范围的填充颜色
						borderColor: 'rgba(85, 255, 255, 1.0)',//边框颜色。
						moveHandleSize: 10,//移动手柄的尺寸高度
						textStyle: {
							color: 'rgba(0, 255, 127, 1.0)',
							fontSize: 17,  
							fontWeight: 700,
							position: "absolute",
							zIndex: 99,
							overflow: "auto",
						},
						labelFormatter(val,val2){
							// console.log(val2)
							return val2.slice(5,19)
						},
						// left:"20px"
						bottom:"30px",
					}]
	let public_tooltip = {				
						show: true,
					    trigger: 'axis',    
						// 触发类型（'item'，数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用；'axis'，坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用；'none'，不触发。）
						backgroundColor: "rgba(85, 170, 255, 1)",
						borderColor: "rgba(255, 255, 255, 1.0)",
						padding: [10, 15],
						textStyle: {
							color: "#fff"
						},
						axisPointer: {
							type: "cross",
							axis: 'auto', // 指示器的坐标轴。
							snap: true, // 坐标轴指示器是否自动吸附到点上  
							crossStyle: {
								color: "#55ffff",
							},
							lineStyle: {
								color: "#55ffff",
							},
							color: "#55ffff",
							label:{
								show: true,  //是否显示文本标签
								color: '#55ffff',  
								backgroundColor: 'rgba(85, 170, 255, 1)',  //背景颜色，默认是和axis.axisLine.lineStyle.color 相同
							    fontSize: 15,  

							}
						},
						triggerOn: 'mousemove|click',
						// alwaysShowContent: true,
						// formatter: '{b0}<br/>{a0} : {c0}',
						// formatter: (params) => {
						// 	// console.log(params)
						// 	let str = `${params.name}
						// 	<div>
						// 		<div style="width: 10px;
						// 					height: 10px;
						// 					background-color: #00ff7f;
						// 					border-radius: 20px;
						// 					margin-top: 5px;
						// 					float:left">
						// 		</div>
						// 		<div style="float:right;margin-left: 5px;">
						// 			${params.seriesName}  :  ${params.data}
						// 		</div>
						// 	</div>
						// 	`
						// 	return str
						// },
						extraCssText: 'box-shadow: 0 0 4px 4px rgba(85, 255, 255, 0.8);',  //额外附加到浮层的 css 样式
						
					}
	let public_toolbox = {
						show: true,
						itemSize: 18,  //icon大小
						right: '10px',  
						feature: {
							// myFull: {
							// 	backgroundColor: '#55aaff',
							// 	show: true,
							// 	iconStyle:{
							// 		// color: "#55ffff", 
							// 		borderColor: "#ffffff",
							// 		borderWidth: 1.2,
							// 		opacity: 1  //图形透明度
							// 	},
							// 	// hover
							// 	emphasis: {
							// 		iconStyle:{
							// 			borderColor: "#55ffff",
							// 			borderWidth: 1.5,
							// 			opacity: 1  ,//图形透明度
							// 			textPosition: 'left',  //文本位置，属性值：left/right/top/bottom
					  //                   // textAlign: 'left',  //文本对齐方式，属性值：left/center/right
							// 			Size:20
							// 		}
							// 	},
							// 	title: "全屏查看",
							// 	icon: "path://M708.2 792.2l-97.5-97.7c-11.6-11.6-11.6-30.3 0-41.9l41.9-41.9c11.6-11.6 30.3-11.6 41.9 0l97.6 97.6 85.3-85.3c11.6-11.6 30.3-11.6 41.9-0.1 5.6 5.6 8.7 13.1 8.7 21v254.4c0 16.4-13.3 29.6-29.6 29.6H643.9c-16.4 0-29.6-13.3-29.6-29.6 0-7.8 3.1-15.4 8.6-20.9l85.3-85.3v0.1zM234 708.1l97.5-97.6c11.6-11.6 30.3-11.6 41.9 0l41.9 41.9c11.6 11.6 11.6 30.3 0 41.9l-97.6 97.6 85.3 85.3c11.6 11.6 11.5 30.4-0.1 41.9-5.6 5.5-13.1 8.6-20.9 8.6H127.7c-16.4 0-29.6-13.3-29.6-29.6V643.7c0-16.4 13.3-29.6 29.6-29.6 7.9 0 15.4 3.1 21 8.7l85.3 85.3zM792 318l-97.6 97.6c-11.6 11.6-30.3 11.6-41.9 0l-41.8-41.8c-11.6-11.6-11.6-30.3 0-41.9l97.6-97.6L623 149c-11.6-11.6-11.5-30.4 0.1-41.9 5.6-5.5 13.1-8.6 20.9-8.6h254.4c16.4 0 29.6 13.3 29.6 29.6v254.4c0 16.4-13.3 29.6-29.6 29.6-7.9 0-15.4-3.1-21-8.7L792 318z m-474.3-83.9l97.6 97.6c11.6 11.6 11.6 30.3 0 41.9l-42 42c-11.6 11.6-30.3 11.6-41.9 0L233.8 318l-85.3 85.3c-11.6 11.6-30.4 11.5-41.9-0.1-5.6-5.6-8.7-13.1-8.6-21V127.8c0-16.4 13.3-29.6 29.6-29.6H382c16.4 0 29.6 13.3 29.6 29.6 0 7.9-3.1 15.4-8.7 21l-85.2 85.3z",
							// 	onclick: () => {
							// 		let element = document.getElementById("data_chistory_show_1");
							// 		// 一些浏览器的兼容性
							// 		if (element.requestFullScreen) {
							// 			// HTML W3C 提议
							// 			element.requestFullScreen();
							// 		} else if (element.msRequestFullscreen) {
							// 			// IE11
							// 			element.msRequestFullScreen();
							// 		} else if (element.webkitRequestFullScreen) {
							// 			// Webkit (works in Safari5.1 and Chrome 15)
							// 			element.webkitRequestFullScreen();
							// 		} else if (element.mozRequestFullScreen) {
							// 			// Firefox (works in nightly)
							// 			element.mozRequestFullScreen();
							// 		}
							// 		// 退出全屏
							// 		if (element.requestFullScreen) {
							// 			document.exitFullscreen();
							// 		} else if (element.msRequestFullScreen) {
							// 			document.msExitFullscreen();
							// 		} else if (element.webkitRequestFullScreen) {
							// 			document.webkitCancelFullScreen();
							// 		} else if (element.mozRequestFullScreen) {
							// 			document.mozCancelFullScreen();
							// 		}
							// 	},
							// },
							// saveAsImage: { 
							// 	show: true ,
							// 	backgroundColor: '#55aaff',
							// 	iconStyle:{
							// 		borderColor: "#ffffff",
							// 		borderWidth: 1.5,
							// 	}
							// },							  
							// 数据视图只读
							dataView: { 
								readOnly: true ,
								backgroundColor: '#55aaff',
								show: true,
								iconStyle:{
									// color: "#55ffff", 
									borderColor: "#ffffff",
									borderWidth: 1.2,
									opacity: 1  //图形透明度
								},
								// hover
								emphasis: {
									iconStyle:{
										borderColor: "#55ffff",
										borderWidth: 1.5,
										opacity: 1  ,//图形透明度
										// textPosition: 'top',  //文本位置，属性值：left/right/top/bottom
								        // textAlign: 'left',  //文本对齐方式，属性值：left/center/right
										Size:20
									}
								},
							},
							// 魔法类型
							// magicType: { type: ['line', 'bar'] },
							//  重置
							restore: {
								backgroundColor: '#55aaff',
								show: true,
								iconStyle:{
									// color: "#55ffff", 
									borderColor: "#ffffff",
									borderWidth: 1.2,
									opacity: 1  //图形透明度
								},
								// hover
								emphasis: {
									iconStyle:{
										borderColor: "#55ffff",
										borderWidth: 1.5,
										opacity: 1  ,//图形透明度
										// textPosition: 'top',  //文本位置，属性值：left/right/top/bottom
								        // textAlign: 'left',  //文本对齐方式，属性值：left/center/right
										Size:20
									}
								},
							},
							  
					
						},
				
					}
	// 数据图表1
	let data_chistory_show_1="";
	// 数据图表2
	let data_chistory_show_2="";
	// 数据图表3
	let data_chistory_show_3="";
	// 数据图表4
	let data_chistory_show_4="";
	// 数据图表5
	let data_chistory_show_5="";
	// 数据图表6
	let data_chistory_show_6="";
	// 数据图表6
	let data_chistory_show_7="";
	// 设备位置数据
	/* ！！！  " "作为字符串分割的标注,要严格按照下列规范
		"<div style='color:#55aaff;'>地址 :武汉港 <br/>设备: device1 </div>"
	*/
	let map_data_info =[
		// [114.4393964, 30.4204975,"<div style='color:#55aaff;'>地址: 武汉港 <br/>设备: device1 </div>"],
		// [113.479880000162, 23.0573600002638,"<div style='color:#55aaff;'>地址: 广州市番禺区龙沙港 <br/>设备: device2 </div>"],
	 //    [121.365398, 37.562242,"<div style='color:#55aaff;'>地址: 烟台市芝罘区烟台港 <br/>设备: device3 </div>"],
		// [120.018000, 30.075000,"<div style='color:#55aaff;'>地址: 杭州市富阳区东洲港 <br/>设备: device4 </div>"],
		// [121.655197, 38.935595,"<div style='color:#55aaff;'>地址: 大连市中山区大连港 <br/>设备: device5 </div>"],
		// [119.330395, 26.078802,"<div style='color:#55aaff;'>地址: 福州市晋安区福建港 <br/>设备: device6 </div>"],
		// [121.38898, 31.6192,"<div style='color:#55aaff;'>地址: 上海市崇明区南门港 <br/>设备: device7 </div>"],
		// [118.210738, 33.460494,"<div style='color:#55aaff;'>地址: 宿迁市泗洪县泗洪港 <br/>设备: device8 </div>"],
		// [114.4383964, 30.41804975,"<div style='color:#55aaff;'>地址: 武汉港 <br/>设备: device9 </div>"],
		// [114.44265, 30.41874,"<div style='color:#55aaff;'>地址: 武汉港 <br/>设备: device10 </div>"],
		// [113.48748, 23.05264,"<div style='color:#55aaff;'>地址: 广州港 <br/>设备: device11 </div>"],
		// [113.48182, 23.05355,"<div style='color:#55aaff;'>地址: 广州港 <br/>设备: device12 </div>"],
		// [111.28155, 30.69608,"<div style='color:#55aaff;'>地址: 宜昌港 <br/>设备: device13 </div>"],
		// [111.27867, 30.6955,"<div style='color:#55aaff;'>地址: 宜昌港 <br/>设备: device14 </div>"],
		// [111.28219, 30.69291,"<div style='color:#55aaff;'>地址: 宜昌港 <br/>设备: device15 </div>"],
		
	];
	// 天地图对象
	let tdtMap = {};
	// 地图标记数组
	let map_marker = [];
	// 地图标记内容数组
	let map_marker_content = [];
	// 地图label对象
	let map_label = [];
	// 设备数据中选择的当前device_id
	let now_device_id = ""
	let markers_list = ""
	let device_info_data_obj={
		"device_id":"",
		"region":"",
		"address":"",
		"lon":"",
		"lat":"",
	}
	export default {
		device_tabs_index,
		flask_web_url,
		flask_web_local_url,
		public_yAxis,
		public_dataZoom,
		public_tooltip,
		public_toolbox,
		data_chistory_show_1,
		data_chistory_show_2,
		data_chistory_show_3,
		data_chistory_show_4,
		data_chistory_show_5,
		data_chistory_show_6,
		data_chistory_show_7,
		map_data_info,
		tdtMap,
		map_marker,
		map_marker_content,
		map_label,
		now_device_id,
		device_info_data_obj,
		markers_list,
		methods:{
			// 判断用户权限
			check_permission(val){
				return new Promise((resolve, reject) => {
					//当第三方api提供的是异步方法
					// resolve();
					let user_data = {
						user:sessionStorage.getItem("user"),
						power:sessionStorage.getItem("power"),
						action:val
					}
					request.post(flask_web_url+'/check_permission',user_data).then(res =>{
						if(res=="500"){
							ElMessage.error('用户数据异常,请重新登录！！！')
							sessionStorage.removeItem("user");
							sessionStorage.clear()
							resolve("500");
						}
						else{
							// ElMessage.success('校验成功')
							resolve(res);
						}
					}).catch(function(res){
						console.error(res)
						ElMessage.error('服务器异常 ！！！')
						resolve("404");
					})
				})

				// console.log(sessionStorage.getItem("user"))
				
			},
		},
	}
</script>

<style>
</style>